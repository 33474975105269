import HttpApis from "../services/HttpApis";
import dropin from "braintree-web-drop-in";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  checkoutFailure,
  checkoutStart,
  checkoutSuccess,
  checkoutFormAction,
} from "../redux/slices/checkoutSlice";

const useCheckoutHook = () => {
  const sessionId = useSelector((state) => state?.commonReducer?.sessionId);
  const userId = useSelector((state) => state?.authReducer?.user?.id);
  const checkoutFormData = useSelector(
    (state) => state.checkoutReducer.checkoutForm
  );
  const [selectedRadio, setSelectedRadio] = useState();
  const [errors, setErrors] = useState();
  const [checkoutData, setCheckoutData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    state: "",
    country: "",
    city: "",
    address: "",
    zipCode: "",
    sessionId: sessionId,
    userId: userId,
    paymentType: "",
    vatTax: 0,
    isBillingAddress: 0,
    id: selectedRadio?.id ? selectedRadio?.id : null,
  });

  const dispatch = useDispatch();
  const [braintreeInstance, setBraintreeInstance] = useState();
  const [braintreeToken, setBraintreeToken] = useState();
  const [showBraintreeDropIn, setShowBraintreeDropIn] = useState(false);

  useEffect(() => {
    const fetch_token = async () => {
      const response = await HttpApis.getPaymentToken();
      setBraintreeToken(response?.data?.token);
    };
    fetch_token();
  }, []);

  useEffect(() => {
    if (showBraintreeDropIn) {
      const initializeBraintree = () =>
        dropin?.create(
          {
            // insert your tokenization key or client token here
            authorization: braintreeToken,
            container: "#braintree-drop-in-div",
          },
          function (error, instance) {
            if (error) console.error(error);
            else setBraintreeInstance(instance);
          }
        );

      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }
  }, [showBraintreeDropIn]);

  const checkOutAction = async (data) => {
    try {
      dispatch(checkoutStart());
      const response = await HttpApis.checkout(data);
      dispatch(checkoutSuccess(response.data));
      return response.data;
    } catch (err) {
      dispatch(checkoutFailure(err.message));
      return null;
    }
  };

  const handleCheckoutData = async (cartData, e) => {
    const checkoutData = JSON.parse(localStorage.getItem("checkoutForm"));
    const newErrors = {};
    if (
      checkoutData?.firstName?.length === 0 &&
      checkoutData?.lastName?.length === 0 &&
      checkoutData?.email?.length === 0 &&
      checkoutData?.phone?.length === 0 &&
      checkoutData?.companyName?.length === 0 &&
      checkoutData?.address?.length === 0 &&
      checkoutData?.zipCode?.length === 0
    ) {
      message.error("Please fill out the required details");
    } else {
      if (
        checkoutData?.paymentType !== "paypal" &&
        checkoutData?.paymentType !== "card"
      ) {
        message.error("Please select the Payment method");
      }
      if (!checkoutData?.firstName?.trim()) {
        newErrors.firstName = "Firstname is required";
      }
      if (!checkoutData?.lastName.trim()) {
        newErrors.lastName = "Lastname is required";
      }
      if (!checkoutData?.companyName?.trim()) {
        newErrors.companyName = "companyName is required";
      }
      if (!checkoutData?.address?.trim()) {
        newErrors.address = "address is required";
      }
      if (!checkoutData?.zipCode?.trim()) {
        newErrors.zipCode = "zipCode is required";
      }
      if (!checkoutData?.email.trim()) {
        newErrors.email = "Email is required";
      } else if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(
          checkoutData?.email
        )
      ) {
        newErrors.email = "Invalid email address";
      }
      if (!checkoutData?.phone.trim()) {
        newErrors.phone = "Mobile number is required";
      } else if (!/^\d{10,15}$/.test(checkoutData?.mobile)) {
        newErrors.mobile = "Mobile number should be numeric";
      }
      setErrors({
        ...errors,
        [e?.target?.id]: "",
      });
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      }
    }
    if (braintreeInstance) {
      try {
        const payload = await braintreeInstance.requestPaymentMethod();
        const paymentMethodNonce = payload.nonce;
        setShowBraintreeDropIn(false);
        return await checkOutAction({
          ...cartData,
          payment_method_nonce: paymentMethodNonce,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const checkoutFormDataAction = (formData) => {
    dispatch(checkoutFormAction(formData));
  };
  return {
    checkOutAction,
    handleCheckoutData,
    checkoutData,
    setCheckoutData,
    errors,
    showBraintreeDropIn,
    setShowBraintreeDropIn,
    braintreeInstance,
    braintreeToken,
    checkoutFormDataAction,
    checkoutFormData,
    selectedRadio,
    setSelectedRadio,
  };
};
export default useCheckoutHook;
