import HttpApis from "../services/HttpApis";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/slices/authSlice";
import { updateStateAction } from "../redux/slices/commonSlice";

const useCommonHook = () => {
  const { sessionId } = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateCommonState = (data) => {
    dispatch(updateStateAction(data));
  };
  //getCountry
  const fetchCountry = async () => {
    try {
      const response = await HttpApis.getCountry();
      dispatch(updateStateAction({ country: response?.data?.data }));
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  //getState
  const fetchState = async (countryId) => {
    try {
      const response = await HttpApis.getState(countryId);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
    }
  };
  //getCities
  const fetchCities = async (stateId) => {
    try {
      const response = await HttpApis.getCities(stateId);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
    }
  };
  //LicenseTypeList
  const fetchLicenseTypeList = async () => {
    try {
      const response = await HttpApis.getLicensesTypeList();
      dispatch(updateStateAction({ coursesTypeList: response?.data?.data }));
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  //postUplaodCSV
  const uplaodCSV = async (data) => {
    try {
      const headers = {
        "Content-type": "multipart/form-data",
      };
      const response = await HttpApis.postUplaodCSV(data, headers);
      return response?.data;
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  };
  //postCheckout
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  };

  const logOut = async () => {
    const user = await dispatch(logout());
    localStorage.removeItem("token");
    if (user) {
      navigate("/");
    }
    return user;
  };

  return {
    updateCommonState,
    sessionId,
    uplaodCSV,
    fetchCountry,
    fetchState,
    fetchCities,
    useDebounce,
    fetchLicenseTypeList,
    logOut,
  };
};
export default useCommonHook;
