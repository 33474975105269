import MainLayout from "../../layout/MainLayout";
import PrivacyNotes from "../../containers/InvitedUserSignUp/PrivacyNotes";
import React from "react";

const PrivacyNotesPage = () => {
  return (
    <MainLayout>
      <PrivacyNotes />
    </MainLayout>
  );
};

export default PrivacyNotesPage;
