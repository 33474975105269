import * as Styled from "./style";
import Button from "../../components/Atoms/Button/button";
import React, { useEffect, useState } from "react";
import useCartHook from "../../hooks/CartHook";
import useCommonHook from "../../hooks/CommonHook";
import { Popconfirm } from "antd";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../routes/routes";
import { cartLabels, shoppingConstant } from "./data";

import {
  MdShoppingCart,
  MdDelete,
  MdOutlineHorizontalRule,
  MdAdd,
} from "react-icons/md";

const MultiCart = () => {
  const navigate = useNavigate();
  const { updateCommonState } = useCommonHook();
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const headerData = useSelector((state) => state?.authReducer?.user);
  const sessionId = useSelector((state) => state?.commonReducer?.sessionId);
  const url = `${process.env.REACT_APP_URL}api/files/`;
  const cartList = useSelector((state) => state?.cartReducer?.cart?.data);
  const isCourseTypeValid = (data, targetType) => {
    for (let i = 0; i < data?.length; i++) {
      const course = data[i]?.Course;
      if (course && course?.type === targetType) {
        return true;
      }
    }
    return false;
  };
  const isAVSEC = isCourseTypeValid(cartList, "AVSEC");
  const { fetchCart, deleteCart, updateQty } = useCartHook();
  const handleRoutes = () => {
    if (headerData) {
      if (isAVSEC) {
        if (headerData?.type === "Single User") {
          navigate(Routes?.browseDeclaration);
        } else {
          navigate(Routes?.corporateUserDeclaration);
        }
      } else {
        navigate(Routes?.checkoutPage);
      }
    } else {
      updateCommonState({
        loginReferer: "checkout",
      });
      navigate(Routes?.askingPage);
    }
  };

  useEffect(() => {
    let cartTotal = 0;
    cartItems?.forEach((item) => {
      if (item?.qty > 0) {
        cartTotal += parseInt(item?.price);
      }
    });
    setTotalAmount(cartTotal);
  }, [cartItems]);

  useEffect(() => {
    const fetchdata = async () => {
      const response = await fetchCart(sessionId);
      setCartItems(response?.data?.data);
    };
    fetchdata();
  }, [sessionId]);

  const handleDelete = async (id) => {
    await deleteCart(id);
    const response = await fetchCart(sessionId);
    setCartItems(response.data.data);
  };

  const handleDecrement = (item, index) => {
    const _cartItems = [...cartItems];
    const qty = item.qty - 1;
    if (qty > 0) {
      const price = qty * item.Course.price;
      const _item = { ...item, qty, price };
      _cartItems.splice(index, 1, _item);
      setCartItems(_cartItems);
      updateQty(item, { qty: qty }, _cartItems);
    }
  };

  const handleIncrement = (item, index) => {
    const _cartItems = [...cartItems];
    const qty = item.qty + 1;
    const price = qty * item.Course.price;
    const _item = { ...item, qty, price };
    _cartItems.splice(index, 1, _item);
    setCartItems(_cartItems);
    updateQty(item, { qty }, _cartItems);
  };

  const handleChangeQty = (item, index, qty) => {
    if (qty === "" || qty > 0) {
      const _cartItems = [...cartItems];
      const price = parseInt(qty) * item.Course.price;
      const _item = { ...item, qty: qty !== "" ? parseInt(qty) : qty, price };
      _cartItems.splice(index, 1, _item);
      setCartItems(_cartItems);
      if (qty) {
        updateQty(item, { qty: parseInt(qty) }, _cartItems);
      }
    }
  };

  return (
    <>
      <div className="container">
        <Styled.CartContainer>
          <Styled.CartHeading>{shoppingConstant.cart}</Styled.CartHeading>
          <Styled.Icon>
            <MdShoppingCart className="icon" />
          </Styled.Icon>
        </Styled.CartContainer>
        <Styled.Gap />
        <Styled.Para>{shoppingConstant.cartPara}</Styled.Para>
        <Styled.Line />
        <Styled.CourseCountSection>
          {`${cartList?.length} course(s) added`}
        </Styled.CourseCountSection>
      </div>
      <Styled.ShoppingCartSection>
        <div className="container">
          <Styled.MainSection>
            <Styled.LeftSection>
              {cartList?.length === 0 ? (
                <p>Your cart is empty. Keep shopping to find a course!</p>
              ) : (
                cartItems?.length &&
                cartItems?.map((item, index) => {
                  return (
                    <Styled.CardSection key={index}>
                      <Styled.CardContainer>
                        <Styled.CardImageSection>
                          <Styled.ImageCardContainer>
                            <Styled.ImageCard
                              src={`${url}${item?.Course?.banner}`}
                              alt={"banner"}
                              className="image"
                            />
                            <Styled.Background />
                            <Styled.HoveredItem
                              className="middle"
                              alt={"courselogo"}
                              src={`${url}${item?.Course?.courseLogo}`}
                            />
                          </Styled.ImageCardContainer>
                          <Styled.CardDeleteSectionMobile>
                            <Styled.Pop
                              title="Delete the course"
                              description="Are
                            you sure to delete this course?"
                              okText="Yes"
                              cancelText="No"
                              placement="top"
                              onConfirm={() => handleDelete(item?.id)}
                            >
                              <MdDelete size={40} />
                            </Styled.Pop>
                          </Styled.CardDeleteSectionMobile>
                        </Styled.CardImageSection>
                        <Styled.CardDescriptionSection>
                          <Styled.CourseChipName>
                            {cartLabels?.courseName}
                          </Styled.CourseChipName>
                          <Styled.Gap />
                          <Styled.Description>
                            {item?.Course?.title}
                          </Styled.Description>
                          <Styled.PriceMobileRes>
                            <div className="courseMobile">
                              <Styled.CourseChipName>
                                {cartLabels?.coursePriceLabel}
                              </Styled.CourseChipName>
                              <Styled.PriceAmount>
                                £{item?.Course?.price}
                              </Styled.PriceAmount>
                            </div>
                            {headerData?.type === "Corporate User" ? (
                              <div className="licenseMobile">
                                <Styled.CourseChipName>
                                  {cartLabels?.licenses}
                                </Styled.CourseChipName>
                                <Styled.PriceAmount>
                                  {item?.qty}
                                </Styled.PriceAmount>
                              </div>
                            ) : null}
                          </Styled.PriceMobileRes>
                          <Styled.PricingSection>
                            <Styled.CourseChipName>
                              {cartLabels?.coursePriceLabel}
                            </Styled.CourseChipName>
                            <Styled.PriceAmount>
                              £{item?.Course?.price}
                            </Styled.PriceAmount>

                            {headerData?.type === "Corporate User" ? (
                              <>
                                <Styled.CourseChipName>
                                  {cartLabels?.licenses}
                                </Styled.CourseChipName>
                                <Styled.PriceAmount>
                                  {item?.qty}
                                </Styled.PriceAmount>
                              </>
                            ) : null}
                          </Styled.PricingSection>
                          {headerData?.type === "Corporate User" ? (
                            <Styled.ButttonContainer>
                              <Button
                                className="decrement"
                                title={
                                  <MdOutlineHorizontalRule className="decrementIcon" />
                                }
                                //updateQty
                                onClick={() => handleDecrement(item, index)}
                              />
                              <Styled.Input
                                className="count"
                                value={item?.qty}
                                defaultValue={item?.qty}
                                onChange={(e) =>
                                  handleChangeQty(item, index, e.target.value)
                                }
                              />
                              <Button
                                className="add_"
                                title={<MdAdd className="decrementIcon" />}
                                onClick={() => handleIncrement(item, index)}
                              />
                            </Styled.ButttonContainer>
                          ) : null}
                        </Styled.CardDescriptionSection>
                        <Styled.CardDeleteSection>
                          <Popconfirm
                            title="Delete the course"
                            description="Are you sure to delete this course?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleDelete(item?.id)}
                          >
                            <MdDelete size={24} className="delete" />
                          </Popconfirm>
                        </Styled.CardDeleteSection>
                      </Styled.CardContainer>
                      <div className="container">
                        <Styled.BottomSection>
                          <Styled.OnlineCourses>
                            {shoppingConstant.onlineCourse}
                          </Styled.OnlineCourses>
                          {/* <Styled.SaveLaterCourses>
                            {shoppingConstant.save}
                            <AiOutlineClockCircle className="clockIcon" />
                          </Styled.SaveLaterCourses> */}
                        </Styled.BottomSection>
                      </div>
                    </Styled.CardSection>
                  );
                })
              )}
            </Styled.LeftSection>

            <Styled.RightSection>
              <Styled.CalculationSection>
                <Styled.CalculationLabel>
                  {shoppingConstant.Calculations}
                </Styled.CalculationLabel>
                <Styled.Line className="line" />
                <Styled.CartPriceSection>
                  <Styled.CartPriceLabel>
                    {shoppingConstant.Total}
                  </Styled.CartPriceLabel>
                  <Styled.CartPrice>£{totalAmount}</Styled.CartPrice>
                </Styled.CartPriceSection>
                <Styled.CardButton>
                  {cartList?.length === 0 ? (
                    <Button
                      className="buttonStyle"
                      title={"Checkout"}
                      icon={<BsArrowRight />}
                      onClick={handleRoutes}
                      disabled={true}
                    />
                  ) : (
                    <Button
                      className="buttonStyle"
                      title={"Checkout"}
                      icon={<BsArrowRight />}
                      onClick={handleRoutes}
                    />
                  )}
                </Styled.CardButton>
              </Styled.CalculationSection>
            </Styled.RightSection>
          </Styled.MainSection>
        </div>
        <Styled.CartPriceSectionMobile>
          <div className="priceLabel">
            <Styled.CartPriceLabel>
              {shoppingConstant.Total}
            </Styled.CartPriceLabel>
            <div className="total">£{totalAmount}</div>
          </div>
        </Styled.CartPriceSectionMobile>
      </Styled.ShoppingCartSection>
      <Styled.ButtonMobile>
        <Styled.CardButtonMobile>
          {cartList?.length === 0 ? (
            <Button
              className="buttonStyle"
              title={"Checkout"}
              icon={<BsArrowRight />}
              onClick={handleRoutes}
              disabled={true}
            />
          ) : (
            <Button
              className="buttonStyle"
              title={"Checkout"}
              icon={<BsArrowRight />}
              onClick={handleRoutes}
            />
          )}
        </Styled.CardButtonMobile>
      </Styled.ButtonMobile>
      <Styled.LastContainer></Styled.LastContainer>
    </>
  );
};

export default MultiCart;
