import styled from "styled-components";
import { color } from "../../theme/Color";

export const Maincontainer = styled.div`
  overflow-x: hidden;
`;
export const Container = styled.div`
  background: ${color._6072a812};
  display: flex;
  justify-content: center;
`;
export const Payment = styled.div`
  padding-top: 42px;
  padding-bottom: 13px;
  color: ${color._15374D};
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const Line = styled.div`
  background: ${color._FFC636};
  width: 339px;
  height: 5px;
`;
export const ParagraphContainer = styled.div`
  width: 80%;
  margin: 0 auto;
`;
export const OrderLine = styled.hr`
  margin-top: 55px;
  height: 0.5px;
  color: rgba(0, 0, 0, 0.1);
`;
export const Thankyou = styled.div`
  padding-top: 75px;
  color: ${color._071b28};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const Purchase = styled.div`
  padding-top: 6px;
  color: ${color._071b28};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const AVTAVText = styled.div`
  padding-top: 30px;
  color: ${color._5F5F5F};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  .minutes {
    color: ${color._5F5F5F};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const EmailText = styled.div`
  color: ${color._5F5F5F};
  text-align: center;
  padding-top: 35px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  .email {
    color: ${color._5F5F5F};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const CorporateUser = styled.div`
  padding-top: 15px;
  color: ${color._5F5F5F};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  .corporate {
    color: ${color._5F5F5F};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const FooterLine = styled.hr`
  margin: 0;
  height: 0.5px;
  color: rgba(0, 0, 0, 0.1);
`;
export const Billing = styled.div`
  .billing {
    padding-top: 37px;
    padding-bottom: 15px;
    color: ${color._071b28};
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .address {
    color: ${color._071b28};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const LightColorLogo = styled.div`
  display: flex;
  justify-content: center;
  padding: 23px 0px;
`;
export const ThanksFor = styled.div`
  padding-top: 29px;
  color: ${color._071b28};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const TableLine = styled.div`
  background: ${color._ffffff1f};
  width: 987px;
  height: 1px;
`;
