import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1%;
  gap: 1%;
`;
export const SignIn = styled.div`
  color: ${color._15374d};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  @media only screen and (max-width: 800px) {
    color: ${color._ffffff};
  }
`;
export const SingleTitle = styled.div`
  color: ${color._16384e};
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media only screen and (max-width: 800px) {
    color: ${color._ffffff};
  }
`;
export const SignUp = styled.div`
  color: ${color._16384e};
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    color: ${color._ffffff};
  }
`;
export const Acc = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: ${color._15374d};
  @media only screen and (max-width: 800px) {
    color: ${color._ffffff};
    font-size: 20px;
  }
`;
export const Pipe = styled.div`
  display: flex;
  background-color: ${color._5193aa};
  width: 3px;
  height: 28px;
  margin-top: 4px;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media only screen and (max-width: 800px) {
    color: ${color._ffffff};
  }
`;
export const Title = styled.div`
  display: flex;
  justify-content: center;
  gap: 1%;
`;
export const Symbol = styled.div`
  color: ${color._5193aa};
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
