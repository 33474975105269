import AskingPage from "../../containers/AskingPage/AskingPage";
import MainLayout from "../../layout/MainLayout";
import React from "react";

const AskingPages = () => {
  return (
    <MainLayout>
      <AskingPage />
    </MainLayout>
  );
};

export default AskingPages;
