import bannerbg from "../../assets/image/bannerbg.jpg";
import { Link } from "react-scroll";
import { styled } from "styled-components";
import { color } from "../../theme/Color";

export const ImgContainer = styled.div`
  background-image: url(${bannerbg});
  background-repeat: no-repeat;
  min-height: 361px;
  position: relative;
  background-size: cover;
  @media only screen and (max-width: 800px) {
    background-attachment: fixed;
    background-position: center center;
    max-width: 100%;
  }
`;
export const TrainingPage = styled.div``;
export const AboutContentContainer = styled.div`
  width: 88%;
  margin: 0 auto;
  @media only screen and (max-width: 800px) {
    width: 90%;
  }
`;
export const TitleContainer = styled.img`
  display: flex;
  margin: 0 auto;
  @media only screen and (max-width: 800px) {
    width: 80%;
  }
`;

export const SubTitle = styled.p`
  font-size: 1.063rem;
  font-weight: 400;
  line-height: 20px;
  color: ${color._fff};
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    color: #fff;
    display: flex;
    text-align: center;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  gap: 3rem;
  padding: 40px 34px;
  width: 92%;
  margin-left: 16px;
  @media only screen and (max-width: 800px) {
    width: 100%;
    display: grid;
    .aboutLogo {
      display: flex;
      justify-content: center;
    }
  }
`;

export const ContentContainer = styled.div`
  text-align: justify;
  font-size: 1rem;
  font-style: normal;
  line-height: 23px;
  font-weigt: 500;
  line-height: 142.9%;
  color: ${color._656565};
  @media only screen and (max-width: 800px) {
    text-align: justify;
    font-size: 2rem;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 2%;
  margin-top: 30px;
  padding: 4px;
`;
export const TabContainer = styled.div`
  display: flex;
  gap: 4rem;
  width: 100%;
  justify-content: space-between;
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 75%;
  border-radius: 18px;
  background: rgba(239, 243, 249, 0.5);
  font-size: 0.938rem;
  font-weight: 600;
  line-height: 140%;
  color: ${color._08232f};
  height: 32px;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Title = styled.div`
  color: ${color._08232f};
  font-size: 22px;
  line-height: normal;
  font-weight: 700;
  display: content;
`;

export const CartIcon = styled.div`
  .cartIcon {
    height: 20px;
    width: 20px;
    color: ${color._0F2A3C};
    margin-left: 3px;
  }
  width: 33px;
  height: 28px;
  border-radius: 2px;
  border: 0.5px solid rgba(96, 145, 168, 0.14);
`;

export const ValuePrice = styled.div`
  width: 77px;
  color: ${color._16384e};
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
export const TitleLine = styled.div`
  margin-top: 3px;
`;
export const Line = styled.div`
  height: 1px;
  background: ${color._eaedef};
  margin: 10px 0px;
`;

export const Content = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const BrowseTraining = styled.div`
  display: flex;
  margin: 0 auto;
  padding-bottom: 50px;
  width: 100%;
  justify-content: space-between;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const Label = styled.div`
  color: ${color._656565};
  font-size: 0.938rem;
  font-weight: 400;
  line-height: normal;
`;

export const Value = styled.div`
  color: ${color._656565};
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const RegulatedCourseHeader = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Border = styled.div`
  height: 4px;
  background: ${color._ffbe32};
  margin-top: 6px;
`;

export const RegulatedCourseContent = styled.div`
  padding-top: 20px;
  margin: 25px 0px;
  color: var(--Gray-1, #333);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.9%;
`;

export const Card_Section = styled.div`
  width: 100%;
`;
export const Cardtext = styled.div`
  padding-bottom: 6px;
  padding-top: 9px;
  color: ${color._16384E};
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
export const CardText = styled.div`
  padding-bottom: 8px;
  color: ${color._767676};
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;
export const NumberBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 15px;
`;
export const CardNumber = styled.div`
  .call {
    color: ${color._6d7d8b};
  }
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${color._16384E};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const PhoneNumber = styled.div`
  color: ${color._16384E};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const Email = styled.div`
  padding-top: 7px;
  .mail {
    color: ${color._6d7d8b};
  }
  display: flex;
  gap: 8px;
  font-size: 15px;
  align-items: center;
  color: ${color._08232f};
  padding-bottom: 10px;
  font-weight: 500;
  line-height: normal;
`;
export const CardButton = styled.div`
  padding-bottom: 10px;
  .btnHide {
    visibility: hidden;
  }

  .btn {
    background: ${color._ffc636};
    width: 100%;
    height: 28px;
    flex-shrink: 0;
    border-radius: 2px;
    border: none;

    color: ${color._0f2a3c};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const Lined = styled.div`
  display: flex;
  width: 0.5px;
  height: 18px;
  background: rgba(0, 0, 0, 0.1);
`;
export const RegulateMap = styled.div`
  display: flex;
  gap: 2%;
  margin-top: 30px;
  justify-content: space-between;
`;
export const TrainingLogo = styled.div`
  display: flex;
`;
export const DifferentContainers = styled.div`
  border-radius: 5px;
  background: ${color._EFF3F9};
  padding: 40px 0px;
`;
export const DifferentColorContainers = styled.div`
  border-radius: 5px;
  padding: 40px 0px;
`;
export const Items = styled.div`
  gap: 1.5rem;
  display: flex;
  margin: 0 auto;
  padding-bottom: 50px;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const CardBottom = styled.div`
  padding-bottom: 10px;
  display: flex;
  gap: 7%;
  align-items: center;

  .btn_style {
    width: 100%;
    padding: 6px 5px;
    font-size: 14px;
    border-radius: 2px;
    background: ${color._ffc636};
    border: none;
    color: ${color._0f2a3c};
    font-weight: 700;
    line-height: normal;
  }
  .btn_icon {
    stroke-width: 1.5px;
  }
  .button_style {
    height: 28px;
    display: flex;
    gap: 2%;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 2px;
    color: ${color._ffffff};
    background: ${color._009be3};
    width: 100%;
    border: none;
    font-weight: 700;
    line-height: normal;
  }
`;

export const NavLinks = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${color._08232F};
  &:hover {
    color: ${color._08232F};
  }
`;
