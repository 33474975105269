import HttpApis from "../services/HttpApis";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import {
  billingSuccess,
  updateEditBillingAddress,
} from "../redux/slices/billingSlice";

const useBillingHook = () => {
  const dispatch = useDispatch();

  const getBillingData = async (data) => {
    try {
      const response = await HttpApis.getBillingAddress(data);
      dispatch(billingSuccess(response?.data));
    } catch (error) {
      console.error("error");
      return null;
    }
  };

  const handleEditAddress = async (billingData) => {
    try {
      const response = await HttpApis.updateBillingAddress(billingData);
      if (response && response.data.status) {
        message.success(response.data.message);
        dispatch(updateEditBillingAddress(billingData));
        return response.data;
      } else {
        message.error(response.data.message);
        return null;
      }
    } catch (error) {
      message.error("Something went wrong");
      return null;
    }
  };

  return { getBillingData, handleEditAddress };
};
export default useBillingHook;
