import React from "react";
import {
  accountDetailFailure,
  accountDetailStart,
  accountDetailSuccess,
} from "../redux/slices/accountDetailSlice";
import HttpApis from "../services/HttpApis";
import { useDispatch } from "react-redux";

const useAccountDetailHook = () => {
  const dispatch = useDispatch();
  const postAccountData = async (declareData) => {
    try {
      dispatch(accountDetailStart());
      const res = await HttpApis.postAccountDetails(declareData);
      dispatch(accountDetailSuccess(res?.data));
      return res?.data;
    } catch (error) {
      dispatch(accountDetailFailure(error.message));
      return null;
    }
  };
  return { postAccountData };
};

export default useAccountDetailHook;
