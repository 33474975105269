import starRating from "../../../assets/Svg/starRating.svg";
import starss from "../../../assets/Svg/starss.svg";

export const course = {
  modalrating: `Leave a rating`,
  namecourse: `Course name`,
  about: `About this course`,
  general: `The General Security Awareness Training`,
  security: ` course takes a deep dive into the essential practices airport security staff should undertake.`,
  assess: `Assessment: `,
  online: ` Online Multichoice`,
  gsat: `General Security Awareness Training`,
  validate: `Validity: `,
  certify: `Certificates`,
  comp: `Certificate will be issued upon successful completion of the final multiple choice exam.`,
  cont: `Leave a rating`,
  john: `John Doe`,
  page: `it is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.`,
  week: `2 weeks ago`,
  mary: `Mary Doe`,
  cousin: `Cousin Doe`,
  cor: `Courses`,
  mod: `Course modules`,
  moduleno: "MODULES",
  comnt: `You can write your comments here ...`,
  mod1: `Module`,
  aims: `Aims And Objectives`,
  def: `Definition of A Threat`,
  level: `Current Threat Level`,
  threat: `Assessing Threat`,
  civil: `Civil Aviation An Attractive Target`,
  pose: `Who may pose A Threat`,
  mins: `The threat to aviation`,
  min15: ` mins`,
  motiv: `Motivation`,
  civilAviation: `Civil Aviation As A Target`,
  attacks: `Type of Attacks on the aviation industry`,
  sum: `Summary`,
  mod2: `Module 2`,
  review: `Reviews`,
  stepsof: `12 of 57 Steps`,
  coursecertified: `Complete this course to get certified`,
  filterRate: `Filter ratings`,
  rev: `Leave a review`,
  sec: `Security in the aviation industry`,
  mainsModule: ` 5 Modules`,
  overall: `The overall Objective of Aviation Security`,
  localPro: `Local Procedure`,
  rem: `Reminder`,
  who: `Who is Responsible for aviation Security`,
  resp: `Who is Responsible?`,
  direc: `Legal Directions`,
  obligations: `Obligations and Responsibilities`,
  everyones: `Security: Everyone’s responsibility`,
  trainee: `The Trainee’s Role`,
  mod3: `Module 3`,
  accessScreen: `Access control and the screening point`,
  mod4: `Module 4`,
  moded5: `Module 5`,
  employment: `Escorting employment and/ or visitor passholders`,
  quiz: `Quiz`,
  ratings10: ` (10 ratings)`,
  rates5: `5.0`,
};
export const rating = [
  {
    id: 0,
    rate: starss,
  },
  {
    id: 1,
    rate: starss,
  },
  {
    id: 2,
    rate: starss,
  },
  {
    id: 3,
    rate: starss,
  },
  {
    id: 4,
    rate: starss,
  },
];

export const starRate = [
  {
    id: 0,
    img: starRating,
  },
  {
    id: 1,
    img: starRating,
  },
  {
    id: 2,
    img: starRating,
  },
  {
    id: 3,
    img: starRating,
  },
  {
    id: 4,
    img: starRating,
  },
];
