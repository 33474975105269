export const exam = {
  courseExam: `Course Exam`,
  name: `Name: `,
  john: ` John Doe`,
  title: `Course Title: `,
  intro: `INTRODUCTION`,
  before: `Please read the following instructions before starting the exam.`,
  mal: `MALPRACTICE`,
  deliberate: `Malpractice is the deliberate activity of neglect or other practice that compromises the integrity of the assessment and the validity of the certificates by either the Trainee or the Instructor. Examples include fabrication of work, improper assistance by the instructor and falsification of marks.In case of malpractice the DfT will seek to take a stepped approach to compliance and, if necessary, prosecution for non-compliance with relevant legislation.`,
  assess: `ASSESMENT AND EXAM`,
  course: `Throughout the course there will be quizzes to prepare you for a final exam at the end of the course. Prior to completing this exam, please make sure you have reviewed all training modules, aims and objectives and you are confident with the content.`,
  final: ` The final exam consists of 20 multiple choice questions.`,
  pass: `PASS MARK`,
  marks: `The pass mark for this exam is`,
  percent: ` 75%.`,
};
