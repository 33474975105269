import { createSlice } from "@reduxjs/toolkit";
const reviewSlice = createSlice({
  name: "review",
  initialState: {
    review: [],
    reviewList:[],
    loading: false,
    error: null,
  },
  reducers: {
    reviewStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    reviewSuccess: (state, action) => {
      state.loading = false;
      state.review = action.payload;
    },
    reviewListSuccess: (state, action) => {
        state.loading = false;
        state.reviewList = action.payload;
      },
    reviewFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { reviewStart, reviewSuccess, reviewFailure,reviewListSuccess } =
 reviewSlice.actions;
export default reviewSlice.reducer;
