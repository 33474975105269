import { useDispatch } from "react-redux";
import HttpApis from "../services/HttpApis";
import {
  issueLicenseStart,
  issueLicenseSuccess,
  issueLicenseFail,
} from "../redux/slices/licensesSlice";
import { useLicense } from "./LicenseHook";
import { message } from "antd";

const useNewLicensesHook = () => {
  const dispatch = useDispatch();
  const { fetchLicensesList } = useLicense();
  const addNewLicenses = (NewLicenseData) => async () => {
    try {
      dispatch(issueLicenseStart());
      const res = await HttpApis.issueLicense(NewLicenseData);
      dispatch(issueLicenseSuccess(res.data));
      return res?.data;
    } catch (error) {
      dispatch(issueLicenseFail(error.message));
      return null;
    }
  };

  const handleData = async (NewLicenseData) => {
    const data = await dispatch(addNewLicenses(NewLicenseData));
    if (data?.status) {
      message.success(data?.message);
      fetchLicensesList();
    }

    return data;
  };

  return {
    handleData,
  };
};

export default useNewLicensesHook;
