import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Heads = styled.div`
  padding-left: 17px;
  display: flex;
  align-items: center;
  width: 667px;
  height: 53px;
  flex-shrink: 0;
  border-radius: 2px;
  background: ${color._f1fbff};
  color: ${color._177a9c};

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 95%;
`;
export const Heading = styled.div`
  display: flex;
  gap: 9px;
  color: ${color._0F2A3C};

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const CompanyData = styled.div`
  margin-top: ${(props) => props.MarginTop || "0px"};
`;

export const Airways = styled.div`
  color: ${color._15374d};
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;
export const NumberMail = styled.div`
  padding-top: 15px;
  display: flex;
  gap: 23%;
`;

export const SaveButton = styled.div`
  display: flex;
  justify-content: end;
  gap: 14px;
  margin-top: 10px;

  .save {
    height: 37px;
    border-radius: 2px;
    color: ${color._ffffff};
    border: none;
    background: ${color._15374d};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
  }
  .cancel {
    height: 37px;
    border-radius: 5px;
    border: none;
    color: ${color._37738c};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    background: none;
    line-height: 95%;
    margin: 0px;
  }
  .crossIcon {
    font-size: 17px;
  }
`;

export const NumberMailData = styled.div`
  padding-top: 15px;
  display: flex;
  gap: 17%;
`;
export const Number = styled.div`
  .heading {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 5px;
  }
  .data {
    color: ${color._15374d};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
`;
export const NumberData = styled.div`
  padding-top: 15px;
  display: flex;
  gap: 20%;
`;
export const Label = styled.label`
  width: 86px;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: ${color._6200DE};
  color: ${color._ffffff};
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;
export const Input = styled.input`
  display: none;
`;
export const ChangeRemove = styled.div`
  display: flex;
  gap: 10px;
  .remove {
    display: flex;
    border: none;
    font-family: Raleway;
    align-items: center;
    color: ${color._ffffff};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 65px;
    height: 22px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._d4d4d4};
  }
  .chng {
    border: none;

    color: ${color._ffffff};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 65px;
    height: 22px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._d4d4d4};
  }
`;
export const FileData = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 17px;
`;

export const BreakLine = styled.div`
  margin: ${(props) => props.lineMargin};
  width: 100%;
  height: 1px;
  background: ${color._eaedef};
`;
export const InputChange = styled.input`
  display: none;
`;
export const EditInput = styled.input`
  width: 218px;
  height: 33px;
  margin-top: 6px;
  border-radius: 3px;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
  outline: none;
  padding: 10px;
  text-transform: capitalize;
  &.email-input {
    text-transform: none;
  }
`;
export const LabelFile = styled.label`
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: Raleway;
  border: none;
  color: ${color._ffffff};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 65px;
  height: 22px;
  flex-shrink: 0;
  text-align: center;
  border-radius: 2px;
  background: ${color._d4d4d4};
`;
export const RemoveButtons = styled.div`
  display: flex;
  width: 533px;
  justify-content: end;
  align-items: center;
  border: 1px solid ${color._ebebeb};
`;
export const EditButton = styled.div`
  .line {
    background: ${color._15374d};
    width: 101px;
    height: 2px;
    margin-left: -11px;
  }
  svg {
    position: absolute;
    left: -10px;
  }
  .editbutton {
    border: none;
    background: none;
    color: ${color._15374d};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
  }
`;
export const BAData = styled.div`
  display: flex;
  gap: 20%;
`;
export const Check = styled.div`
  display: flex;
  align-items: center;
`;

export const Mail = styled.div`
  .heading {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .data {
    color: ${color._15374D};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Information = styled.div`
  margin-top: 25px;
`;
export const ProfileData = styled.div`
  padding: 33px 0px;
  width: 60%;
`;

export const Error = styled.div`
  color: ${color._ff0000};
`;
