import * as Styled from "./style";
import React from "react";
import { Space } from "antd";
import { HeaderTraining } from "../Header/HeaderTraining";
import { cong } from "./data";
import Footer from "../../../components/Organisms/Footer/Footer";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const CourseCompletion = () => {
  const location = useLocation();
  const authData = useSelector((state) => state.authReducer.user);
  return (
    <>
      <HeaderTraining />
      <Styled.MainComp className="container">
        <Styled.Congratulate>
          <div className="con">{cong.con}</div>
          {authData && authData?.isTrainee ? (
            <>
              <div>
                <div className="success">
                  {cong.success}
                  <span className="xx">{location?.state}</span>
                  {cong.please}
                </div>
              </div>
              <div className="hesitate">
                {/* <div>
                  {cong.request}
                  <span className="xx">
                    {authData?.parentUserData?.companyName
                      ? authData?.parentUserData?.companyName
                      : ""}
                  </span>
                  {" if needed."}
                </div> */}
                <div>{cong.hesitate}</div>
              </div>
              <div className="kind">
                <div>{cong.kindest}</div>
                <div>{cong.avtav}</div>
              </div>
            </>
          ) : (
            <>
              <div className="success">{`Dear ${authData?.firstName},`}</div>
              <div className="hesitate">
                <div>
                  Congratulations on successfully completing the
                  <span className="xx">{location?.state}</span>
                  {" Course"}
                </div>
                <br />
                <div>{cong.profile}</div>
                <br />
                <div>{cong.hesitate}</div>
              </div>
              <div className="kind">
                <div>{cong.kindest}</div>
                <div>{cong.avtav}</div>
              </div>
            </>
          )}
        </Styled.Congratulate>
        <Styled.ProgressComp>
          <Space wrap>
            <Styled.ProgressCircle
              type="circle"
              percent={100}
              format={(percent) => `${percent}% Complete`}
              strokeColor="#5193AA"
              trailColor="#15374D"
              strokeWidth="8"
              size={300}
              colorBgContainer="#F1FBFF"
            />
          </Space>
        </Styled.ProgressComp>
      </Styled.MainComp>
      <Footer />
    </>
  );
};

export default CourseCompletion;
