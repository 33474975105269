import React from "react";
import * as Styled from "./style";
import { BsThreeDots } from "react-icons/bs";

const ThreeDots = ({ onClick }) => {
  return (
    <Styled.ThreeDots onClick={onClick}>
      <BsThreeDots />
    </Styled.ThreeDots>
  );
};

export default ThreeDots;
