import * as Styled from "./style";
import React from "react";
import { online } from "./data";

const OnlineTerms = () => {
  return (
    <>
      <Styled.Terms>{online.online}</Styled.Terms>
      <Styled.Accept className="container">
        <div>
          <div className="about">{online.about}</div>
          <div className="register">
            {online.provide}

            <span className="web"> {online.termsMain}</span>
            {online.out}
            <span className="web"> {online.contentMain}</span>
            {online.webs}
            <span className="web"> {online.websiteMain}</span>
            {online.acceptMain}
          </div>
          <div className="register">
            {online.no2}
            <span className="web"> {online.pleaseRead}</span>

            {online.liability}
            <span className="web"> {online.limited}</span>
          </div>
          <div className="register">
            {online.clearly}
            <span className="web"> {online.consume}</span>

            {online.prime}
          </div>
          <div className="register">
            {online.purchasing}
            <span className="web"> {online.termsMains}</span>
          </div>
          <div className="register">{online.please}</div>
          <div className="register">{online.website}</div>
        </div>
        <div>
          <div className="about">{online.who}</div>
          <div className="register">{online.drayton}</div>
          <div className="register">{online.training}</div>
          <div className="register">
            {online.question}
            <span className="web"> {online.mail}</span>
            {online.train}
            <span className="web"> {online.ph}</span>
            {online.phNo}
          </div>
        </div>
        <div>
          <div className="about">{online.place}</div>
          <div className="register">{online.purchase}</div>
          <div className="register">{online.accurate}</div>
          <div className="register">{online.email}</div>
          <div className="register">{online.consumerText}</div>
          <br />
          <div className="web">{online.youCon}</div>
          <div className="register">{online.individual}</div>
          <div className="register">{online.least}</div>
          <div className="register">{online.resident}</div>
          <div className="register">{online.make}</div>
        </div>
        <div>
          <div className="about">{online.desc}</div>
          <div className="register">{online.set}</div>
          <div className="register">{online.your}</div>
          <div className="register">{online.clear}</div>
          <div className="register">{online.requirements}</div>
          <div className="register">{online.providing}</div>
          <div className="register">{online.theseText}</div>
          <div className="register">{online.applicable}</div>
        </div>
        <div>
          <div className="about">{online.digital}</div>
          <div className="register">{online.link}</div>
          <div className="register">{online.downloading}</div>
          <div className="register">{online.uk}</div>
        </div>
        <div>
          <div className="about">{online.licence}</div>
          <div className="register">{online.where}</div>
          <div className="register">{online.businessText}</div>
          <div className="register">{online.number}</div>
          <div className="register">{online.internal}</div>
        </div>
        <div>
          <div className="about">{online.restriction}</div>
          <div className="register">{online.must}</div>
          <div className="register">{online.partiesText}</div>
          <div className="register">{online.contained}</div>
          <div className="register">{online.literature}</div>
          <div className="register">{online.mimic}</div>
          <div className="register">{online.illegalText}</div>
          <div className="register">{online.programme}</div>
          <div className="register">{online.unless}</div>
          <div className="register">{online.permitted}</div>
        </div>
        <div>
          <div className="about">{online.rightInt}</div>
          <div className="register">{online.except}</div>
          <div className="register">{online.written}</div>
        </div>
        <div>
          <div className="about">{online.pricePay}</div>
          <div className="register">{online.inclusive}</div>
          <div className="register">{online.affect}</div>
          <div className="register">{online.materially}</div>
          <div className="register">{online.will}</div>
          <div className="register">{online.advance}</div>
          <div className="register">{online.authorised}</div>
          <div className="register">{online.invoice}</div>
          <div className="register">{online.within}</div>
          <div className="register">{online.shall}</div>
          <div className="register">{online.stripe}</div>
        </div>
        <div>
          <div className="about">{online.rightCon}</div>
          <br />
          <div className="web">{online.applies}</div>
          <div className="register">{online.date}</div>
          <div className="register">{online.following}</div>
          <div className="register">{online.broken}</div>
          <div className="register">{online.during}</div>
          <div className="register">{online.whereyou}</div>
          <div className="register">{online.cancel}</div>
          <div className="register">{online.quickly}</div>
          <div className="register">{online.whichever}</div>
          <div className="register">{online.possession}</div>
          <div className="register">{online.visit}</div>
          <div className="register">{online.once}</div>
          <div className="register">{online.sameRefund}</div>
          <div className="register">
            {online.advice}
            <a href="">{online.linkWeb}</a>
            {online.no}
          </div>
        </div>
        <div>
          <div className="about">{online.faulty}</div>
          <br />
          <div className="register">{online.meet}</div>
          <br />

          <div className="web">{online.are}</div>
          <div className="register">{online.conformity}</div>
          <div className="register">{online.entitled}</div>
          <div className="register">{online.days}</div>
          <div className="register">{online.full}</div>
          <div className="register">{online.length}</div>
          <div className="register">{online.promptly}</div>
          <div className="register">{online.misdescribed}</div>
          <br />
          <div className="web">{online.business}</div>
          <div className="register">{online.extent}</div>
          <div className="register">{online.returning}</div>
          <div className="register">{online.part}</div>
        </div>
        <div>
          <div className="about">{online.oblig}</div>
          <div className="register">{online.agreeThat}</div>
          <div className="register">{online.compl}</div>
          <div className="register">{online.ene}</div>
          <div className="register">{online.usings}</div>
          <div className="register">{online.page}</div>
        </div>
        <div>
          <div className="about">{online.termTerm}</div>
          <div className="register">{online.orders}</div>
          <div className="register">{online.contract}</div>
          <div className="register">{online.exercising}</div>
          <div className="register">{online.accordance}</div>
          <div className="register">{online.clause}</div>
        </div>
        <div>
          <div className="about">{online.ends}</div>
          <div className="register">{online.time}</div>
          <div className="register">{online.serious}</div>
          <div className="register">{online.illegal}</div>
          <div className="register">{online.fail}</div>
          <div className="register">{online.consumer}</div>
          <div className="register">{online.terminates}</div>
        </div>
        <div>
          <div className="about">{online.lib}</div>
          <div className="register">{online.adequate}</div>
          <div className="register">{online.damage}</div>
          <div className="register">{online.exclude}</div>
          <br />
          <div className="web">{online.only}</div>

          <div className="register">{online.did}</div>
          <br />
          <div className="web">{online.businessClause}</div>
          <div className="register">{online.paid}</div>
          <div className="register">{online.liable}</div>
          <div className="register">{online.indirect}</div>
          <div className="register">{online.whether}</div>
          <div className="register">{online.profit}</div>
          <div className="register">{online.corruption}</div>
          <div className="register">{online.systems}</div>
          <div className="register">{online.equipment}</div>
          <div className="register">{online.loss}</div>
          <div className="register">{online.opport}</div>
          <div className="register">{online.savings}</div>
          <div className="register">{online.goodwill}</div>
          <div className="register">{online.provides}</div>
        </div>
        <div>
          <div className="about">{online.general}</div>
          <div className="register">{online.notify}</div>
          <div className="register">{online.these}</div>
          <div className="register">{online.situation}</div>
          <div className="register">{online.parties}</div>
          <div className="register">{online.previously}</div>
          <div className="register">
            {online.claims}
            <span className="web">{online.youConsume}</span>
            {online.united}
          </div>
        </div>
      </Styled.Accept>
    </>
  );
};

export default OnlineTerms;
