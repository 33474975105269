import HttpApis from "../services/HttpApis";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { invoiceSuccess } from "../redux/slices/invoicePaymentSlice";

import {
  InvoiceDownload,
  TableInvoicePayment,
  InvoiceCourseNumber,
  InvoiceCourseName,
  RenderDate,
  DeclarationPreview,
} from "../containers/Corporate/BusinessUserLicense/CustomRender";

export const useInvoiceHook = () => {
  const invoiceList = useSelector(
    (state) => state?.invoicePaymentReducer?.invoiceList?.data?.cources
  );
  const [loading, setLoading] = useState(false);
  const [downloadData, setDownloadData] = useState(null);
  const [invoicePreview, setInvoicePreview] = useState(false);

  const generateAndDownloadPDF = async (selector) => {
    const captureParent = document.querySelector(selector);
    captureParent.scrollTop = captureParent.scrollHeight;
    captureParent.style.opacity = 1;
    captureParent.style.height = "1px";
    captureParent.style.overflow = "auto";
    const capture = document.querySelector(`${selector} > div`);
    capture.style = {};
    var height = capture.offsetHeight;
    var width = capture.offsetWidth;
    capture.style.width = `${width}px`;
    capture.style.height = `${height}px`;
    html2canvas(capture).then((canvas) => {
      const doc = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("img/jpg");
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save("Invoice.pdf");
      captureParent.style.opacity = 0;
      captureParent.style.height = "1px";
      captureParent.style.overflow = "auto";
      setDownloadData(null);
    });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (downloadData) {
      generateAndDownloadPDF(".invoice");
    }
  }, [downloadData]);

  const TableHeaderInvoice = [
    {
      label: "Date",
      mappedkey: "createdAt",
      defaultSortOrder: "descend",
      sorter: true,
      customRender: RenderDate,
    },
    {
      label: "Number of Courses",
      mappedkey: "OrderItems",
      customRender: InvoiceCourseNumber,
    },
    {
      label: "Course Name",
      mappedkey: "OrderItems",
      customRender: InvoiceCourseName,
    },
    {
      label: "Total",
      customRender: TableInvoicePayment,
    },

    {
      label: "Invoice PDF",
      mappedkey: "invoicePDF",
      action: (row) => {
        setInvoicePreview(row);
      },
      customRender: DeclarationPreview,
    },
    {
      label: "Download",
      mappedkey: "Download",
      action: (row) => {
        setDownloadData(row);
      },
      customRender: InvoiceDownload,
    },
  ];

  const getInvoiceData = async (data) => {
    setLoading(true);
    try {
      const response = await HttpApis.getInvoiceAndPayment(data);
      dispatch(invoiceSuccess(response?.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("error");
      return null;
    }
  };

  return {
    TableHeaderInvoice,
    getInvoiceData,
    invoiceList,
    downloadData,
    invoicePreview,
    setInvoicePreview,
    loading,
  };
};
