import { createSlice } from "@reduxjs/toolkit";

const feedBackSlice = createSlice({
  name: "preCourse",
  initialState: {
    questionList: [],
    preCourseQuestionnaire: [],
    loading: false,
    error: null,
  },
  reducers: {
    feedBackSuccess: (state, action) => {
      state.loading = false;
      state.questionList = action.payload;
    },
    feedBackFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.data;
    },
  },
});
export const { feedBackSuccess, feedBackFail } = feedBackSlice.actions;
export default feedBackSlice.reducer;
