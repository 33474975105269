import { color } from "./Color";

export const themeConfig = {
  token: {
    fontFamily: "Raleway"
  },
  components: {
    Steps: {
      colorTextLabel: `${color._ffffff}`,
      colorPrimary: `${color._DADADA}`,
    },
    Checkbox: {
      colorPrimary: "#6D7D8B",
      colorBorder: "#6D7D8B",
      colorPrimaryHover: "#6D7D8B",
      colorText: "rgba(0, 0, 0, 0.66)",
    },
    Radio: {
      colorPrimary: "#6D7D8B",
      colorBorder: "#6D7D8B",
      colorPrimaryHover: "#6D7D8B",
      colorText: "rgba(0, 0, 0, 0.66)",
    },
  },
};
