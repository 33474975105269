import React from 'react'
import MainLayout from '../../layout/MainLayout'
import MultiCart from '../../containers/CartPages/MultiCart'

const CartPage = () => {
  return (
    <MainLayout>
      <MultiCart />
    </MainLayout>
  )
}

export default CartPage
