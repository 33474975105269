import * as Styled from "./style";
import { constant } from "../../../constants/Constants";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/routes";

const SignupHeader = ({ title }) => {
  const navigate = useNavigate();
  return (
    <>
      <Styled.Heading>
        <Styled.SingleTitle>{title}</Styled.SingleTitle>
        <Styled.Pipe />
        <Styled.SignUp>{constant.SignUp}</Styled.SignUp>
      </Styled.Heading>
      <Styled.Title>
        <Styled.Acc>{constant.AlreadyHaveAnAccount}</Styled.Acc>
        <Styled.SignIn onClick={() => navigate(Routes?.Signin)}>
          {constant.SignIn}
        </Styled.SignIn>
      </Styled.Title>
    </>
  );
};

export default SignupHeader;
