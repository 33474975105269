import { createSlice } from "@reduxjs/toolkit";

const billingSlice = createSlice({
  name: "billingList",
  initialState: {
    billingList: [],
    loading: false,
    error: null,
    billingForm: null,
  },
  reducers: {
    billingStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    billingSuccess: (state, action) => {
      state.loading = false;
      state.billingList = action.payload;
    },
    billingFail: (state, action) => {
      state.loading = false;
      state.error = action.payload.data;
    },

    updateEditBillingAddress: (state, action) => {
      state.loading = true;
      state.billingList = action.payload;
      state.loading = false;
    },
  },
});
export const {
  billingStart,
  billingSuccess,
  billingFail,
  updateEditBillingAddress,
} = billingSlice.actions;
export default billingSlice.reducer;
