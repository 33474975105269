import React from "react";
import { Multiuser } from "../../containers/MultiuserCourseDetailPage/Multiuser";
import MainLayout from "../../layout/MainLayout";

const MultiUserDetailPage = () => {
  return (
    <>
      <MainLayout>
        <Multiuser />
      </MainLayout>
    </>
  );
};

export default MultiUserDetailPage;
