import React, { useState } from "react";
import * as Styled from "./style";
import { AiOutlineSearch } from "react-icons/ai";
import AntdTable from "../../../components/Atoms/Table";
import usePermissions from "../../../hooks/PermissionsHook";
import Button from "../../../components/Atoms/Button";
import HttpApis from "../../../services/HttpApis";
import { useDispatch } from "react-redux";
import {
  updateRole,
  usersList,
} from "../../../redux/slices/corporateUserSlice";

const CorporateStaff = () => {
  const {
    userColumn,
    corporateUsers,
    count,
    handleSearch,
    handleTableChange,
    search,
    tableParams,
    openModal,
    setOpenModal,
  } = usePermissions();
  const [editRole, setEditRole] = useState({
    userId: "",
    roleId: "",
  });
  const dispatch = useDispatch();

  const handleOk = () => {
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleChange = (e) => {
    setEditRole({
      ...editRole,
      userId: openModal?.id,
      roleId: e.target.value === "Operations" ? 6 : 7,
    });
  };

  const handleSave = async (data) => {
    const response = await HttpApis.updateRole(data);
    dispatch(updateRole(data));
    if (response.status === 200) {
      dispatch(usersList());
      handleOk();
    }
    return response;
  };

  return (
    <div>
      <Styled.CompletedCourses>
        <span className="courses">{"Users & Permissions"}</span>
        <Styled.Pipe />
        <span className="number">{count ? count : 0}</span>
      </Styled.CompletedCourses>
      <Styled.BelowText>{"Below are the list of users"}</Styled.BelowText>
      <Styled.MainContainer>
        <Styled.TableHeader>
          <Styled.SearchContainer>
            <AiOutlineSearch className="searchIcon" size={12} />
            <Styled.Input
              type="text"
              placeholder="Search"
              value={search?.search}
              onChange={handleSearch}
            />
          </Styled.SearchContainer>
        </Styled.TableHeader>
        <AntdTable
          data={corporateUsers}
          columns={userColumn}
          numberOfTableData={tableParams.pagination.pageSize}
          onChange={handleTableChange}
          {...tableParams}
        />
      </Styled.MainContainer>
      {openModal && (
        <Styled.ModalContainer
          width={600}
          footer={null}
          open={openModal}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Styled.Name>
            {openModal?.firstName ? openModal?.firstName : ""}
          </Styled.Name>
          <Styled.Permissions>Permissions</Styled.Permissions>
          <Styled.Divider />
          <Styled.FormContainer>
            <Styled.Field>
              <Styled.Label>Current</Styled.Label>
              <Styled.ModalInput>{openModal?.type}</Styled.ModalInput>
            </Styled.Field>
            <Styled.Field>
              <Styled.Label>Change Role</Styled.Label>
              <Styled.Select
                name="roleId"
                id="role"
                onChange={(e) => handleChange(e)}
                value={editRole?.id}
              >
                <option>Select Role</option>
                <option>Operations</option>
                <option>Finance Account</option>
              </Styled.Select>
            </Styled.Field>
          </Styled.FormContainer>
          <Styled.FooterButton>
            <Button
              title={"Save Changes"}
              className="btn"
              onClick={() => handleSave(editRole)}
            />
          </Styled.FooterButton>
        </Styled.ModalContainer>
      )}
    </div>
  );
};

export default CorporateStaff;
