import React from "react";
import MainLayout from "../../layout/MainLayout";
import AboutPage from "../../containers/AboutPage/AboutPage";

export const AboutUsPage = () => {
  return (
    <MainLayout>
    <AboutPage/> 
    </MainLayout>
  );
};
