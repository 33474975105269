import styled from "styled-components";
import { Modal } from "antd";
import { color } from "../../../theme/Color";

export const Courses = styled.div`
  display: flex;
  gap: 7px;
  padding-bottom: 18px;
  align-items: center;
  .courses {
    color: ${color._0f2a3c};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .number {
    color: ${color._6091a8};
    font-family: Open Sans;
    margin-top: 1px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Pipe = styled.div`
  display: flex;
  background-color: ${color._6091a8};
  color: ${color._6091a8};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 2px;
  height: 20px;
  margin-top: 4px;
`;

export const BelowText = styled.div`
  padding-bottom: 20px;
  color: ${color._68828e};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SearchContainer = styled.div`
  gap: 5px;
  .searchIcon {
    color: ${color._16384E};
    margin-top: 3px;
    margin-left: 6px;
  }
  display: flex;
  align-items: center;
  width: 210px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
`;

export const Input = styled.input`
  outline: none;
  border: none;
  flex-shrink: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${color._000000};
  &::placeholder {
    color: ${color._000000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Dropdown = styled.div`
  padding: 10px 0px;
  display: flex;
  width: 186px;
  gap: 5px;
  height: 36px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 8px;

  .filter {
    font-size: 12px;
  }
`;

export const DropData = styled.div`
  .gsat {
    border: none;
    padding-right: 92px;
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Filter = styled.div`
  padding: 10px 0px;
  display: flex;
  width: 210px;
  gap: 5px;
  height: 36px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 8px;

  .filter {
    font-size: 12px;
  }
`;

export const NewestFirst = styled.div`
  .newest {
    border: none;
    padding-right: 82px;
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    outline: none;
  }
`;

export const DotIcon = styled.div`
  width: 38px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const TableHeader = styled.div`
  padding: 13px 20px 13px 17px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  padding-bottom: 14px;
  .tablecontainer {
    display: flex;
    gap: 12px;
  }
`;

export const MainContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 0.7px solid ${color._ededde};
  background: ${color._ffffff};
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
`;

export const Component = styled.div`
  margin-top: 40px;
  position: relative;
  left: -1px;
`;
export const Main = styled.div`
  display: flex;
  margin-left: 3px;
  gap: 10px;
  margin-top: 18px;
`;
export const Wrapper = styled.div`
  .nonActive {
    height: 25px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._f1fbff};
    color: ${color._177a9c};
    border: none;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    padding: 10px;
    display: flex;
    align-items: center;
  }
  .active {
    color: ${color._ffffff};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    background: ${color._177a9c};
    border: none;
    height: 25px;
    border-radius: 2px;
    padding: 10px;
    display: flex;
    align-items: center;
  }
`;

export const BackContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  .icon {
    color: #0089a0;
    width: 24px;
    height: 24px;
  }
`;

export const Back = styled.div`
  color: #68828e;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CourseName = styled.div`
  color: #0f2a3c;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 18px;
  margin-top: 10px;
`;

export const Line = styled.div`
  height: 1px;
  background: #d9d9d9;
`;

export const Container = styled.div`
  margin-left: 10px;
  width: 95%;
`;
export const AddCourseContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const AddCourse = styled.div`
  .add {
    width: 137px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 2px;
    background: #177a9c;
    border: none;
    color: ${color._ffffff};
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }
  .plus {
    margin-top: 2px;
  }
`;
export const ModalContainer = styled(Modal)`
  & .ant-modal-content {
    padding: 32px 45px 50px 45px;
  }
  &.ant-modal {
    max-width: calc(100% - 32px);
  }
`;
