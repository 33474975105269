
export const License = {
  gsat: "GSAT",
  issue: "Issue a license",
  course: "Course with Licenses",
  no: "2",
  relate: " Related Courses ",
};
export const Declaration = {
  forms: "Declaration Forms",
  no: "3",
};
export const Security = {
  account: "Account Security",
  update: "Update Password",
};
export const Profile = {
  profile: "Your Profile",
  about: "About you",
  airways: "British Airways",
  firstName: "First name",
  lastName: "Last name",
  mobile: "Mobile Number",
  email: "Email Address",
  details: "Company Details",
  companyName: "Company Name",
  avatav: "AVTAV Airways",
  company: "Company Address",
  landlines: "Landline Number",
  regNo: "Company Reg. Number",
  nature: "Nature of Business",
  emp: "Account Type",
  type: "Type | Corporate User",
  aviation: "Aviation industry?",
  save: "Save changes",
  yes: "YES",
  choose: "Change",
  cancel: "Cancel",
  remove: "Remove",
  Airline: "Airline",
};
export const ModalText = {
  previewText: "Preview Declaration & Sign",
  Enroll: "Enroll new users",
  GSAT: "GSAT",
  AddUsers: "Add Users",
  UploadviaCSV: "Upload via CSV",
  Name: "Name",
  TraineeSurname: "Trainee Surname",
  TraineeFirstName: "Trainee First Name",
  TraineeEmail: "Trainee Email",
  Trainee: " Trainee Contact Number",
  TraineeRole: "Trainee Role",
  Selectcourse: " Select course",
  SelectcourseType: " Select course type",
  Confirm:
    " I confirm that the trainee has undergone a full 5-year background check.",
  AddMoreUsers: "Add more users",
  Not: "Not signed yet ",
  Signed: "Signed ",
  AIRSIDE: " AIRSIDE",
  Has: "Has an",
  Security:
    "Security Restricted Area (SRA) and/or Critical Part of Security Restricted Area (CPSRA) ",
  Job: " job or has been assigned a job within an aviation security role where access to the ",
  is: "is required or has been assigned a job with an aviation security role as specified above and is eligible to undertake security training that contains material that is not available to the public, and which is relevant to his/her role or position.",
};
