import * as Styled from "./style";
import React from "react";
import { Avatar, Tag } from "antd";
import { useSelector } from "react-redux";

const CorporateHeader = ({ headerBackground }) => {
  const headerData = useSelector((state) => state.authReducer.user);
  const firstName = headerData?.firstName
    ? headerData?.firstName?.charAt(0).toUpperCase() +
      headerData?.firstName?.slice(1)
    : "";
  const lastName = headerData?.lastName
    ? headerData?.lastName?.charAt(0).toUpperCase() +
      headerData?.lastName?.slice(1)
    : "";
  const avtarfirstName = headerData?.firstName
    ? headerData?.firstName?.charAt(0).toUpperCase()
    : "";
  const avtarLastName = headerData?.lastName
    ? headerData?.lastName?.charAt(0).toUpperCase()
    : "";

  const roleType = (headerData) => {
    if (headerData?.type === "Corporate User") {
      return "Business Account - Super Admin";
    } else if (headerData?.type === "Finance Account") {
      return "Business Account - Finance";
    } else if (headerData?.type === "Operations") {
      return "Business Account - Operations";
    } else {
      return "Single User Account";
    }
  };

  return (
    <Styled.Header headerBackground={headerBackground}>
      <Avatar
        style={{
          backgroundColor: "#FFBE32BA",
          alignItems: "middle",
          fontWeight: 800,
          fontSize: 16,
        }}
        size={54}
        gap={4}
      >
        {avtarfirstName + " " + avtarLastName}
      </Avatar>
      <Styled.Tab>
        <Tag
          style={{
            color: "white",
            background: "rgba(255, 255, 255, 0.26)",
            fontSize: 12,
            height: 22,
            textAlign: "center",
            fontWeight: 500,
          }}
          bordered={false}
        >
          {roleType(headerData)}
        </Tag>
        <Styled.CompanyName>{firstName + " " + lastName}</Styled.CompanyName>
      </Styled.Tab>
    </Styled.Header>
  );
};

export default CorporateHeader;
