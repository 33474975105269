export const dataItem = {
    Completed: "Declaration Forms",
    Number: "3",
    BelowText: "Below are the declaration forms you have provided for each AVSEC course you purchased",
    GSAT: "GSAT",
    Selectcourse:"Select course",
    Newestfirst: "Newest first",
    Oldestfirst:"Oldest first",
    DownloadSpreadsheet: "Download Spreadsheet",
    invoice: "Search by course Name",
  };

export const DeclarationButton = [
  {
    id: 0,
    title: "Signed by your employer"
  },
  {
    id: 1,
    title: "Signed by you (Sole Trader)"
  },
]