import CardImage from "../../../assets/Svg/CardImage.svg";

export const RegulateDataMap = [
  {
    id: 0,
    banner: CardImage,
    imgTitle: "Former Co",
    title: "General Awareness Security Training (GSAT)",
  },
  {
    id: 1,
    banner: CardImage,
    imgTitle: "Former COD",
    title: "Hold Baggage Reconciliation (HBR)",
  },
];
export const invitedData = {
  id: 0,
  banner: CardImage,
  imgTitle: "Former Co",
  title: "General Awareness Security Training (GSAT)",
};
export const invitedCoursesData = {
  id: 0,
  banner: CardImage,
  imgTitle: "Former COD",
  title: "Hold Baggage Reconciliation (HBR)",
};
export const TextData = {
  text: "Below are the courses you have provided",
  individualCourses: "Individual Courses",
  invitedCourses: "Invited Courses",
  individualText: "Below are the courses you have purchased"
};

export const CoursesButton = [
  {
    id: 0,
    title: "Invited Courses"
  },
  {
    id: 1,
    title: "Individual Courses"
  },
  {
    id: 2,
    title: "Add Courses"
  },
]