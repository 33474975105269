export const dataItem = {
  Completed: "Individual Certificates",
  Number: "1",
  BelowText: "Below is a list of the certificates of the courses you purchased",
  GSAT: "GSAT",
  Newestfirst: "Newest first",
  Oldestfirst: "Oldest first",
  DownloadSpreadsheet: "Download Spreadsheet",
  invoice: "Search",
};
export const CorporatedataItem = {
  Completed: "Corporate Certificates",
  Number: "2",
  BelowText:
    "Below is a list of the certificates of the courses purchased for you.",
  GSAT: "GSAT",
  Newestfirst: "Newest first",
  DownloadSpreadsheet: "Download Spreadsheet",
  invoice: "Search",
};
export const ModalItem = {
  training: "This training is valid for 5 years and expires on DD/MM/YYYY.",
  expire: "Training expiration",
  download: " Download Certificate",
  preview: "Preview Certificate",
  issue: "Issue Date",
  institute: "Issuing Institute",
  recName: "Recipients name",
  stacey: "Stacey Matthews",
  email: "stacey@avtavair.co.uk",
  eng: "English",
  general: "General Security Awareness Training",
  course: "Course name",
  gsat: "(GSAT)",
  certificateModal: "Certificate of completion",
  contact: " If you require of this certificate, please contact:",
  name: "Employer_Name",
};

export const CertificateButton = [
  {
    id: 0,
    title: "Individual",
  },
  {
    id: 1,
    title: "Corporate",
  },
];
