import CertificateModal from "../containers/SingleUserProfile/Certificate/CertificateModal";
import { MdDownload } from "react-icons/md";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  AccountType,
  Courses,
  DeclarationPreview,
  ExpirationDate,
  InvoiceDownload,
  InvoiceDownloadDisable,
  Percentage,
  RenderDate,
} from "../containers/Corporate/BusinessUserLicense/CustomRender";
import HttpApis from "../services/HttpApis";
import { useDispatch, useSelector } from "react-redux";
import { individualCertificatesSuccess } from "../redux/slices/individualCertificatesSlice";
import { useEffect, useState } from "react";
import useCommonHook from "./CommonHook";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const useIndividualCertificatesHook = () => {
  const dispatch = useDispatch();
  const [sortData, setSortData] = useState("DESC");
  const [certificatePreview, setCertificatePreview] = useState(false);
  const [loading, setLoading] = useState();
  const { useDebounce } = useCommonHook();
  const [count, setCount] = useState();
  const [downloadSheet, setDownloadSheet] = useState(false);

  const handleType = (e) => {
    setSortData(e.target.value);
  };
  const [downloadData, setDownloadData] = useState(null);
  const certificateList = useSelector(
    (state) => state?.individualCertificatesReducer?.individualCertificates
  );

  const generateAndDownloadPDF = async (selector) => {
    const captureParent = document.querySelector(selector);
    captureParent.scrollTop = captureParent.scrollHeight;
    captureParent.style.opacity = 1;
    captureParent.style.height = "1px";
    captureParent.style.overflow = "auto";
    const capture = document.querySelector(`${selector} > div`);
    capture.style = {};
    var height = capture.offsetHeight;
    capture.style.width = `950px`;
    capture.style.height = `${height}px`;
    html2canvas(capture).then((canvas) => {
      const doc = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("img/jpg");
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save("Certificate.pdf");
      captureParent.style.opacity = 0;
      captureParent.style.height = "1px";
      captureParent.style.overflow = "auto";
    });
  };
  useEffect(() => {
    if (downloadData) {
      generateAndDownloadPDF(".certificate");
    }
  }, [downloadData]);
  const [search, setSearch] = useState({
    page: 1,
    limit: 5,
    course_name: "",
  });
  const debouncedSearch = useDebounce(search, 500);
  const [tableParams, setTableParams] = useState({
    pagination: {
      total: count,
      current: 1,
      pageSize: 5,
    },
  });
  useEffect(() => {
    setTableParams({
      pagination: {
        total: count,
        current: 1,
        pageSize: 5,
      },
    });
  }, [count]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };
  const handleSearch = (e) => {
    setSearch({ ...search, course_name: e.target.value });
  };

  const individualCertificatesForm = async (params) => {
    try {
      setLoading(true);
      const response = await HttpApis.certificatesList(params);
      dispatch(individualCertificatesSuccess(response.data?.data?.certificate));
      setCount(response.data?.data?.count);
      if (response.status === 200) {
        setLoading(false);
      }
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (downloadSheet) {
      const handleDeclaration = async () => {
        const response = await individualCertificatesForm();
        if (response && response?.data?.certificate) {
          const dataToDownload = response?.data?.certificate?.map((item) => ({
            "Completion Date": item.createdAt
              ? new Date(item.createdAt).toLocaleDateString("en-GB")
              : "",
            "Trainee Name": item?.trainee_name,
            "Course Name": item?.course_name,
            "Purchasing Account	": item?.purchase_account,
            "Pass/Fail": item?.exam_status,
          }));
          const ws = XLSX.utils.json_to_sheet(dataToDownload);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });
          saveAs(data, "Certificate Data.xlsx");
        } else {
          console.error("No data to download");
        }
      };

      handleDeclaration();
      setDownloadSheet(false);
    }
  }, [downloadSheet]);
  const handleClick = () => {
    setDownloadSheet(true);
  };

  const numberOfTableData = tableParams.pagination.pageSize;
  const TableCourse = [
    {
      label: "Completion Date",
      mappedkey: "completion_date",
      defaultSortOrder: "descend",
      sorter: true,
      customRender: RenderDate,
    },
    {
      label: "Expiry Date",
      customRender: ExpirationDate,
    },
    {
      label: "Trainee Name",
      mappedkey: "trainee_name",
      customRender: Courses,
    },
    {
      label: "Course Name ",
      mappedkey: "course_name",
      customRender: Courses,
    },
    {
      label: "Purchasing Account",
      mappedkey: "purchase_account",
      customRender: AccountType,
    },
    {
      label: "Certification",
      customRender: DeclarationPreview,
      action: (row) => {
        setCertificatePreview(row);
      },
    },
    {
      label: "Pass/Fail",
      mappedkey: "exam_status",
      customRender: Percentage,
    },
    {
      label: "Download",
      mappedkey: "Download",
      customRender: InvoiceDownload,
      action: (row) => {
        setDownloadData(row);
      },
    },
  ];

  const CorporateTableCourse = [
    {
      label: "Completion Date",
      mappedkey: "completion_date",
      defaultSortOrder: "descend",
      sorter: true,
      customRender: RenderDate,
    },
    {
      label: "Expiry Date",
      mappedkey: "createdAt",
      customRender: ExpirationDate,
    },
    {
      label: "Trainee Name",
      mappedkey: "trainee_name",
      customRender: Courses,
    },
    {
      label: "Course Name ",
      mappedkey: "course_name",
      customRender: Courses,
    },
    {
      label: "Purchasing Account",
      mappedkey: "corporate_purchase_account",
      customRender: Courses,
    },
    {
      label: "Pass/Fail",
      mappedkey: "exam_status",
      customRender: Percentage,
    },
    {
      label: "Certification",
      customRender: DeclarationPreview,
      action: (row) => {
        setCertificatePreview(row);
      },
    },

    {
      label: "Download",
      mappedkey: "Download",
      customRender: InvoiceDownload,
      action: (row) => {
        setDownloadData(row);
      },
    },
  ];
  return {
    TableCourse,
    CorporateTableCourse,
    certificateList,
    certificatePreview,
    setCertificatePreview,
    loading,
    handleSearch,
    handleTableChange,
    count,
    numberOfTableData,
    handleType,
    sortData,
    tableParams,
    downloadData,
    handleClick,
    individualCertificatesForm,
    debouncedSearch,
  };
};
