import CardImage from "../../../assets/Svg/CardImage.svg";

export const regulateDataMap = [
  {
    id: 0,
    banner: CardImage,
    imgTitle: "Former Co",
    title: "Former Co",
    CardTitle: "Please note this course is currently only classroom based.",
    Browse: "Browse Course Information",
    Number: "07483161620",
    Phonenumber: "02039844195",
    Email: "training@avtav.co.uk",
  },
  {
    id: 1,
    banner: CardImage,
    imgTitle: "Former COD",

    title: "Former COD",
    CardTitle: "Please note this course is currently only classroom based.",
    Browse: "Browse Course Information",
    Number: "07483161620",
    Phonenumber: "02039844195",
    Email: "training@avtav.co.uk",
  },
  {
    id: 2,
    banner: CardImage,
    imgTitle: "Formerly COS (NX)",

    title: "Formerly COS (NX)",
    CardTitle: "Please note this course is currently only classroom based.",
    Browse: "Browse Course Information",
    Number: "07483161620",
    Phonenumber: "02039844195",
    Email: "training@avtav.co.uk",
  },
];
export const LabelData = [
  {
    id: 0,
    name: "Trainee First Name",
    input: "Ibrahim",
    icon: "",
  },
  {
    id: 1,
    name: "Trainee Surname ",
    input: "Maltovo",
    icon: "",
  },
  {
    id: 2,
    name: "Trainee Email ",
    input: "Ibrahimibrahimmaltovo@ba.com",
    icon: "",
  },
  {
    id: 3,
    name: "Trainee Contact Number *",
    input: "+44 123 24341",
    icon: "",
  },
];

export const buttonName = {
  Invite: "Invite",
  Trainee: "Add Trainee",
  Users: "Invite All Users",
};
export const DeclarationModalText = {
  corporate: " Corporate – Trainee Background Check | Declaration Form",
  single:"Single User Background Check | Declaration Form",
  Text: "The form has been pre-filled with the information you’ve provided",
};

