import React from 'react'
import MainLayout from '../../layout/MainLayout'
import Vetting from '../../containers/Vetting/Vetting'


export const VettingPage = () => {
  return (
    <MainLayout>
    <Vetting/>
    </MainLayout>
  )
}
