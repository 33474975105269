import { createSlice } from "@reduxjs/toolkit";

const coursesSlice = createSlice({
  name: "courses",
  initialState: {
    courses: [],
    courseDetails: {},
  },
  reducers: {
    UpdatedCoursesList: (state, action) => {
      state.courses = action.payload;
    },
    UpdatedCourseDetails: (state, action) => {
      state.courseDetails = action.payload;
    },
    // clearCourseDetails: (state) => {
    //   state.courseDetails = {};
    // },
  },
});

export const { UpdatedCoursesList, UpdatedCourseDetails, clearCourseDetails } =
  coursesSlice.actions;
export default coursesSlice.reducer;
