import * as Styled from "../style";
import Footer from "../../../components/Organisms/Footer/Footer";
import MultiStepProgressBar from "../../../components/Atoms/MultiStepProgressBar/MultiStepProgressBar";
import React from "react";
import logAvtav from "../../../assets/Svg/logAvtav.svg";
import useDeclarationHook from "../../../hooks/DeclarationDataHook";
import useStepper from "../../../hooks/StepperHook";
import { Navigation } from "../../../components/Organisms/Navigation/Navigation";
import { constant } from "../../../constants/Constants";
import { browseData } from "../BrowseData";

const CorporateUserDeclaration = () => {
  const {
    orderConfirmation,
    current,
    prev,
    next,
    declareData,
    errors,
    setErrors,
    setFinalSign,
    finalSign,
  } = useStepper();

  const { handleData } = useDeclarationHook();
  return (
    <>
      <Navigation />
      <Styled.ContentContainer>
        <Styled.Contents>
          <Styled.DeclareContainer>
            <img src={logAvtav} alt="avtav" />
            <span className="declare">
              {browseData.declare}
              <br />
              <span className="aviation">{browseData.aviation}</span>
            </span>
          </Styled.DeclareContainer>
        </Styled.Contents>
        <MultiStepProgressBar
          steps={orderConfirmation}
          nextButton={constant.Next}
          previousButton={constant.Previous}
          buttonHide={true}
          status="orderDetails"
          current={current}
          nextOrder="nextOrder"
          next={next}
          errors={errors}
          handleData={handleData}
          prev={prev}
          type={"corporate_declaration"}
          setErrors={setErrors}
          declareData={declareData}
          setFinalSign={setFinalSign}
          finalSign={finalSign}
        />
      </Styled.ContentContainer>
      <Footer />
    </>
  );
};

export default CorporateUserDeclaration;
