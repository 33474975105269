export const Profiles = {
  profile: "Your Profile",
  about: "About you",
  airways: "British Airways",
  firstName: "First name",
  lastName: "Last name",
  mobile: "Mobile Number",
  email: "Email Address",
  details: "Company Details",
  companyName: "Company Name",
  avatav: "AVTAV Airways",
  company: "Company Address",
  landlines: "Landline Number",
  regNo: "Company Reg. Number",
  nature: "Nature of Business",
  emp: "Account Type",
  type: "Type | Single User",
  aviation: "Aviation industry?",
  save: "Save Changes",
  yes: "YES",
  cancel: "Cancel",
  Airline: "Airline",
};
