import AcceptablePage from "../pages/Acceptable/AcceptablePage";
import AskingPages from "../pages/AskingPage/AskingPages";
import BrowseDeclarationPage from "../pages/BrowseDeclarationPage/BrowseDeclarationPage";
import CartPage from "../pages/CartPage/CartPage";
import CheckOutPage from "../pages/CheckOutPage/CheckOutPage";
import CorporateUserDeclaration from "../containers/BrowseTrainingDeclaration/CorporateUserDeclaration/CorporateUserDeclaration";
import CourseCompletion from "../containers/TrainingScreen/CourseCompletion/CourseCompletion";
import CoursesDetail from "../containers/Corporate/Courses/CoursesDetail";
import EndUserPage from "../pages/EndUser/EndUser";
import ExamCoursePage from "../pages/ExamCoursePage/ExamCoursePage";
import InductionChecklist from "../containers/TrainingScreen/InductionChecklist/InductionChecklist";
import InviterUserSignUp from "../containers/InvitedUserSignUp/InviterUserSignUp";
import MultiUserDetailPage from "../pages/MultiUserDetailPage/MultiUserDetailPage";
import OnlineTermsPage from "../pages/OnlineTermsPage/OnlineTermsPage";
import OrderConfirmPage from "../pages/OrderConfirmation/OrderConfirmPage";
import PaymentMessage from "../containers/BrowseTrainingDeclaration/PaymentMessage";
import PaymentSoleTrader from "../containers/BrowseTrainingDeclaration/PaymentSoleTrader";
import PreCourse from "../containers/PreCourseInvited/PreCourse";
import PrivacyNotesPage from "../pages/PrivacyPage/PrivacyNotes";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import Signup from "../containers/CorporateSignUp/Signup";
import SingleProfilePage from "../pages/SingleProfilePage/SingleProfilePage";
import SingleSignIn from "../containers/SingleSingInPage/SingleSignIn";
import SingleSignUp from "../containers/SingleSignUp/SingleSignUp";
import TermsPage from "../pages/TermsPage/TermsPage";
import TrainingCoursePage from "../pages/trainingCourses/TrainingCoursePage";
import TrainingCoursesPage from "../pages/TrainingCoursePage/TrainingCoursesPage";
import { AboutUsPage } from "../pages/AboutUs/AboutUsPage";
import { ContactPage } from "../pages/Contact/ContactPage";
import { HomePage } from "../pages/Home/HomePage";
import { RecruitmentPage } from "../pages/Recruitment/RecruitmentPage";
import { ResourcesPage } from "../pages/Resources/ResourcesPage";
import { VettingPage } from "../pages/Vetting/VettingPage";
import { Routes } from "./routes";
import OrderCancelForm from "../containers/InvitedUserSignUp/OrderCancelForm";
import FeedbackForm from "../containers/TrainingScreen/FeedbackForm/FeedbackForm";
import ExamForm from "../containers/TrainingScreen/ExamForm/ExamForm";
import InvitedUserPreCourse from "../containers/PreCourseInvited/InvitedUserPreCourse";
import SingleUserPreCourse from "../containers/PreCourseInvited/SingleUserPreCourse";
import DeclarationFormPdf from "../components/Atoms/DeclarationForm/DeclarationFormPdf";

export const protectedRoutes = [
  {
    id: 0,
    title: "trainingCourse",
    path: Routes?.trainingCourse,
    data: <TrainingCoursePage />,
    isProtected: false,
  },
  {
    id: 1,
    title: "askingPage",
    path: Routes?.askingPage,
    data: <AskingPages />,
    isProtected: false,
  },
  {
    id: 2,
    title: "corporateSignup",
    path: Routes?.corporateSignup,
    data: <Signup />,
    isProtected: false,
  },
  {
    id: 3,
    title: "singleUserSignup",
    path: Routes?.singleUserSignup,
    data: <SingleSignUp />,
    isProtected: false,
  },
  {
    id: 4,
    title: "Signin",
    path: Routes?.Signin,
    data: <SingleSignIn />,
    isProtected: false,
  },
  {
    id: 5,
    title: "multiUserDetailPage",
    path: `${Routes?.multiUserDetailPage}/:slug`,
    data: <MultiUserDetailPage />,
    isProtected: false,
  },
  {
    id: 6,
    title: "invitedUserSignUp",
    path: Routes?.invitedUserSignUp,
    data: <InviterUserSignUp />,
    isProtected: false,
  },
  {
    id: 7,
    title: "multiCart",
    path: Routes?.multiCart,
    data: <CartPage />,
    isProtected: false,
  },
  {
    id: 8,
    title: "checkoutPage",
    path: Routes?.checkoutPage,
    data: <CheckOutPage />,
    isProtected: true,
  },
  {
    id: 9,
    title: "userProfile",
    path: Routes?.userProfile,
    data: <ProfilePage />,
    isProtected: true,
  },
  {
    id: 10,
    title: "orderConfirmation",
    path: Routes?.orderConfirmation,
    data: <OrderConfirmPage />,
    isProtected: true,
  },
  {
    id: 11,
    title: " browseDeclaration",
    path: Routes?.browseDeclaration,
    data: <BrowseDeclarationPage />,
    isProtected: true,
  },
  {
    id: 12,
    title: " paymentMessage",
    path: Routes?.paymentMessage,
    data: <PaymentMessage />,
    isProtected: true,
  },
  {
    id: 13,
    title: " paymentSoleTrader",
    path: Routes?.paymentSoleTrader,
    data: <PaymentSoleTrader />,
    isProtected: true,
  },
  {
    id: 14,
    title: "corporateUserDeclaration",
    path: Routes?.corporateUserDeclaration,
    data: <CorporateUserDeclaration />,
    isProtected: true,
  },
  {
    id: 15,
    title: "coursesDetail",
    path: Routes?.coursesDetail,
    data: <CoursesDetail />,
    isProtected: true,
  },
  {
    id: 16,
    title: "homePage ",
    path: Routes?.homePage,
    data: <HomePage />,
    isProtected: false,
  },

  {
    id: 17,
    title: "termsPage",
    path: Routes?.termsPage,
    data: <TermsPage />,
    isProtected: false,
  },
  {
    id: 18,
    title: "aboutUsPage",
    path: Routes?.aboutUsPage,
    data: <AboutUsPage />,
    isProtected: false,
  },
  {
    id: 19,
    title: "privacyNotes",
    path: Routes?.privacyNotes,
    data: <PrivacyNotesPage />,
    isProtected: false,
  },
  {
    id: 20,
    title: "recruitmentPage ",
    path: Routes?.recruitmentPage,
    data: <RecruitmentPage />,
    isProtected: false,
  },
  {
    id: 21,
    title: "acceptablePage",
    path: Routes?.acceptablePage,
    data: <AcceptablePage />,
    isProtected: false,
  },
  {
    id: 22,
    title: "vettingPage ",
    path: Routes?.vettingPage,
    data: <VettingPage />,
    isProtected: false,
  },
  {
    id: 23,
    title: "resourcesPage",
    path: Routes?.resourcesPage,
    data: <ResourcesPage />,
    isProtected: false,
  },
  {
    id: 24,
    title: "contactPage",
    path: Routes?.contactPage,
    data: <ContactPage />,
    isProtected: false,
  },
  {
    id: 25,
    title: "onlineTermsPage",
    path: Routes?.onlineTermsPage,
    data: <OnlineTermsPage />,
    isProtected: false,
  },
  {
    id: 26,
    title: "endUserPage",
    path: Routes?.endUserPage,
    data: <EndUserPage />,
    isProtected: false,
  },
  {
    id: 27,
    title: "preCourseInvited",
    path: Routes?.preCourseInvited,
    data: <InvitedUserPreCourse />,
    isProtected: true,
  },
  {
    id: 28,
    title: "singleUserProfile",
    path: Routes?.singleUserProfile,
    data: <SingleProfilePage />,
    isProtected: true,
  },
  {
    id: 29,
    title: "helloJohn",
    path: Routes?.helloJohn,
    data: <PreCourse />,
    isProtected: false,
  },
  {
    id: 30,
    title: "examCourse",
    path: Routes?.examCourse,
    data: <ExamCoursePage />,
    isProtected: false,
  },

  {
    id: 31,
    title: "aboutTrainingCourse",
    path: Routes?.aboutTrainingCourse,
    data: <TrainingCoursesPage />,
    isProtected: false,
  },
  {
    id: 32,
    title: "inductionChecklist",
    path: Routes?.inductionChecklist,
    data: <InductionChecklist />,
    isProtected: false,
  },
  {
    id: 33,
    title: "courseCompletion",
    path: Routes?.courseCompletion,
    data: <CourseCompletion />,
    isProtected: false,
  },
  {
    id: 34,
    title: "OrderCancelForm",
    path: Routes?.OrderCancelForm,
    data: <OrderCancelForm />,
    isProtected: true,
  },
  {
    id: 35,
    title: "feedbackForm",
    path: Routes?.feedbackForm,
    data: <FeedbackForm />,
    isProtected: false,
  },
  {
    id: 36,
    title: "exam",
    path: Routes?.exam,
    data: <ExamForm />,
    isProtected: false,
  },
  {
    id: 37,
    title: "singlePreCourse",
    path: Routes?.singlePreCourse,
    data: <SingleUserPreCourse />,
    isProtected: false,
  },
  {
    id: 38,
    title: "managerDecPdf",
    path: Routes?.managerDecPdf,
    data: <DeclarationFormPdf />,
    isProtected: false,
  },
];
