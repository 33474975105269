import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Heading = styled.div`
  display: flex;
  gap: 9px;
  color: ${color._0F2A3C};

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 39px;
`;
export const PasswordContainer = styled.div`
  .password {
    border-radius: 2px;
    padding-left: 10px;
    background: ${color._f1fbff};
    border: none;
    height: 53px;
    width: 666px;
    color: ${color._177a9c};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    display: flex;
    align-items: center;
  }
`;
export const Input = styled.input`
  width: 550px;
  outline: none;

  &::placeholder {
    color: ${color._177a9c};
    padding-left: 10px;
  }
  border-color: ${(props) => (props?.active ? color._f1fbff : color._f4f4f4)};
  background: ${(props) => (props?.active ? color._f1fbff : color._F8F8F8)};
  border: none;
  outline: none;
`;
export const Error = styled.div`
  color: #ff0000;
`;

export const MainPassword = styled.div`
  width: 666px;
  height: 45px;
  outline: none;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid;
  border-color: ${(props) => (props?.active ? color._f1fbff : color._f4f4f4)};
  background: ${(props) => (props?.active ? color._f1fbff : color._F8F8F8)};
  display: flex;
  justify-content: space-between;

  .passwordEye {
    position: relative;
    background: ${color._fbfbfb};
    outline: none;
    border: none;
    width: 250px;
  }
  .eye {
    cursor: pointer;
  }
`;
export const InputBox = styled.div``;
export const MainContainer = styled.div``;
export const UpdateButton = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  .update {
    color: ${color._ffffff};
    border: none;
    box-shadow: none;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 2px;
    background: ${color._15374d};
    width: 169px;
    height: 31px;
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
  }
`;
export const Label = styled.div`
  color: ${color._565656};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 95%;
  margin-top: 24px;
  margin-bottom: 6px;

  .star {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    margin-left: 4px;
    color: ${color._f20000};
  }
`;
