import * as Styled from "./style";
import React, { useState } from "react";

const Tab = (props) => {
  const { tabData, TabWidth, initialIndex = "1" } = props;
  const [value, setValue] = useState(initialIndex);
  const onChange = (key) => {
    setValue(key);
  };

  return (
    <Styled.Tab
      activeKey={value}
      items={tabData}
      TabWidth={TabWidth}
      onChange={onChange}
      value={value}
      style={{
        shadowOffset: { height: 0, width: 0 },
        shadowColor: "transparent",
        shadowOpacity: 0,
        elevation: 0,
      }}
    />
  );
};

export default Tab;
