import * as Styled from "./style";
import LOGOAvtavTraining from "../../assets/Svg/LOGOAvtavTraining.svg";
import React from "react";
import useCommonHook from "../../hooks/CommonHook";
import { BsArrowUpRight } from "react-icons/bs";
import { FiAlertCircle } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { constant } from "../../constants/Constants";
import { Routes } from "../../routes/routes";
import Button from "../../components/Atoms/Button";

const AskingPage = () => {
  const headerData = useSelector((state) => state.authReducer.user);
  const loginReferer = useSelector((state) => state.commonReducer.loginReferer);
  const { updateCommonState } = useCommonHook();
  const navigate = useNavigate();
  const handleRegisterSingle = () => {
    if (!headerData && loginReferer === "checkout") {
      updateCommonState({
        loginReferer: "checkout",
      });
    } else if (!headerData && loginReferer === "cart") {
      updateCommonState({
        loginReferer: "cart",
      });
    }
    navigate(Routes?.singleUserSignup);
  };

  const handleCorporateSignup = () => {
    if (!headerData && loginReferer === "checkout") {
      updateCommonState({
        loginReferer: "checkout",
      });
    } else if (!headerData && loginReferer === "cart") {
      updateCommonState({
        loginReferer: "cart",
      });
    }
    navigate(Routes?.corporateSignup);
  };
  const handleSingleBtn = () => {
    if (!headerData && loginReferer === "checkout") {
      updateCommonState({
        loginReferer: "checkout",
      });
    } else if (!headerData && loginReferer === "cart") {
      updateCommonState({
        loginReferer: "cart",
      });
    }
    navigate(Routes?.Signin);
  };

  return (
    <Styled.Container>
      <Styled.MainContainer>
        <Styled.Head>
          <Styled.CircleIcon>
            <FiAlertCircle className="icon" />
          </Styled.CircleIcon>
          <Styled.Enroll>{constant.Enroll}</Styled.Enroll>
        </Styled.Head>
        <Styled.MainAccount>
          <Styled.Account>
            <div className="imgicon">
              <Styled.ImgIcon src={LOGOAvtavTraining} alt="logotraining" />
            </div>
            <Styled.SecondContainer>
              <Styled.Heading>{constant.Account}</Styled.Heading>
              <Styled.Buttons>
                <Styled.SubHead>{constant.Already}</Styled.SubHead>
                <button
                  type=""
                  className="singleBtn"
                  onClick={() => handleSingleBtn()}>
                  {constant.Single}
                  <BsArrowUpRight className="iconRight" />
                </button>
              </Styled.Buttons>
            </Styled.SecondContainer>
          </Styled.Account>
          <Styled.Line />
          <Styled.SingleUser>
            <Styled.SingleData>
              <Styled.SingleHead>
                {constant.SingleUserAccount}
              </Styled.SingleHead>
              <Styled.SinglePara>{constant.SingleLine}</Styled.SinglePara>
            </Styled.SingleData>
            <Styled.RegisterBtn>
              <Button
                className="register"
                onClick={() => handleRegisterSingle()}
                title={"Register Account"}
              />
            </Styled.RegisterBtn>
          </Styled.SingleUser>
          <Styled.Line />
          <Styled.SingleUser>
            <Styled.SingleData>
              <Styled.SingleHead>{constant.MultiUser}</Styled.SingleHead>
              <Styled.SinglePara>{constant.SingleLine}</Styled.SinglePara>
            </Styled.SingleData>
            <Styled.RegisterBtn>
              <Button
                className="register"
                onClick={() => handleCorporateSignup()}
                title={"Register Account"}
              />
            </Styled.RegisterBtn>
          </Styled.SingleUser>
        </Styled.MainAccount>
      </Styled.MainContainer>
    </Styled.Container>
  );
};
export default AskingPage;
