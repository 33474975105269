import * as Styled from "./style";
import { ReactComponent as LogoHead } from "../../../assets/Svg/logoHead.svg";
import { ReactComponent as ResultTick } from "../../../assets/Svg/resultTick.svg";
import { constant } from "../../../constants/Constants";

const StepperLabel = ({ title, isLogo=false, isIcon=false, isSign=false }) => {
  return (
    <Styled.StepperLabelContainer>
      <Styled.Declaration>
        <Styled.IconLogo className="icon">
          {isLogo && <LogoHead />}
        </Styled.IconLogo>
        <Styled.StepperLabelText isLogo={isLogo}>
          {title}
        </Styled.StepperLabelText>
      </Styled.Declaration>
      <Styled.ResultLogo>
        {isIcon && <ResultTick className="resultLogo" />}
        {isSign && <Styled.Sign>{constant.Sign}</Styled.Sign>}
      </Styled.ResultLogo>
    </Styled.StepperLabelContainer>
  );
};



export default StepperLabel;
