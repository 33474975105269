import * as Styled from "./style";
import AVSECCoursesCard from "../../components/Atoms/CoursesCard/AVSECCoursesCard";
import React from "react";
import aboutLogo from "../../assets/Svg/aboutLogo.svg";
import bannerTitle from "../../assets/Svg/bannerTitle.svg";
import { trainingConstant } from "./data";

const TrainingCourses = () => {
  return (
    <Styled.TrainingPage>
      <Styled.ImgContainer>
        <div className="container" style={{ paddingTop: "80px" }}>
          <Styled.TitleContainer src={bannerTitle} alt="bannerTitle" />
          <Styled.SubTitle className="subtitle">
            {trainingConstant.title}
          </Styled.SubTitle>
        </div>
      </Styled.ImgContainer>
      <Styled.AboutContentContainer>
        <Styled.AboutContainer>
          <div className="aboutLogo">
            <img src={aboutLogo} alt="aboutLogo" />
          </div>
          <Styled.ContentContainer>
            {trainingConstant.content} <br /> <br />
            {trainingConstant.subContent}
          </Styled.ContentContainer>
        </Styled.AboutContainer>
      </Styled.AboutContentContainer>
      <AVSECCoursesCard iswidth={true} />
    </Styled.TrainingPage>
  );
};

export default TrainingCourses;
