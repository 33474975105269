import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useState } from "react";
import { message, Radio } from "antd";
import { exam } from "../ExamCourse/data";
import { feedback } from "./data";
import { useSelector } from "react-redux";
import HttpApis from "../../../services/HttpApis";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/routes";

const FeedbackData = ({ courseId, title, username }) => {
  const navigate = useNavigate();
  const question_list = useSelector(
    (state) => state?.feedBackReducer?.questionList
  );
  const [selectedAnswers, setSelectedAnswers] = useState({});

  const handleText = (e, questionId) => {
    let value = e.target.value;
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: {
        answers_id: value,
        question_id: questionId,
        courseId: courseId,
      },
    });
  };

  const handleChange = (answerId, questionId) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: {
        answers_id: answerId,
        question_id: questionId,
        courseId: courseId,
      },
    });
  };

  const handleSubmit = async () => {
    const allAnswered =
      question_list &&
      question_list?.every(
        (question) =>
          selectedAnswers[question.id] && selectedAnswers[question.id].answers_id
      );

    if (!allAnswered) {
      message.error("Please answer all questions before submitting the form.");
      return;
    }
    const formattedFormData = Object.values(selectedAnswers);
    const response = await HttpApis.postFeedBackForm(formattedFormData);
    if (response?.status === 200) {
      navigate(Routes?.inductionChecklist, {
        state: {
          courseId: courseId,
          title: title,
          username: username,
        },
      });
    }
    return response;
  };

  return (
    <>
      <Styled.ExamData>
        <div className="learn">{feedback.learn}</div>
        <div className="exam">
          {feedback.feed}
          <span className="form">{feedback.form}</span>
        </div>
        <div className="feed">{feedback.important}</div>
        <Styled.Security>
          <div>
            <div className="name">
              {exam.name}
              <span className="title">{username}</span>
            </div>
            <div className="name">
              {exam.title}
              <span className="title">{title}</span>
            </div>
          </div>
        </Styled.Security>
        <div className="how">{feedback.how}</div>
        {question_list?.map((itemQuestion, index) => {
          return (
            <Styled.Question key={itemQuestion.id}>
              <div className="ques">{`Question ${index + 1}`}</div>
              <div className="inside">{itemQuestion?.question}</div>
              {itemQuestion?.feedbackforms?.map((item) => {
                return item?.questionType === "singlechoice" ||
                  item?.questionType === "singleselect" ? (
                  <div className="job" key={item.id}>
                    <Radio.Group
                      value={selectedAnswers[itemQuestion.id]?.answers_id}
                      onChange={(e) =>
                        handleChange(e.target.value, itemQuestion.id)
                      }
                    >
                      <Radio value={item.id}>{item?.question}</Radio>
                    </Radio.Group>
                  </div>
                ) : (
                  <div className="job" key={item.id}>
                    <Styled.Input
                      type="text"
                      name="answers_id"
                      onChange={(e) => handleText(e, itemQuestion?.id)}
                    />
                  </div>
                );
              })}
            </Styled.Question>
          );
        })}
        <div className="secured">
          <Button
            title={"Submit Form"}
            className="start"
            onClick={handleSubmit}
          />
        </div>
      </Styled.ExamData>
    </>
  );
};

export default FeedbackData;
