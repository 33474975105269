import React from "react";
import * as Styled from "./style";
import { ReactComponent as BusinessLogo } from "../../../assets/Svg/BusinessLogo.svg";
import { ModalItem } from "./data";

export const DownloadCorporateCertificate = ({ item }) => {
  return (
    <div style={{ padding: "20px" }}>
      <Styled.ModalHeader>
        <Styled.ModalTitle>{ModalItem.certificateModal}</Styled.ModalTitle>
      </Styled.ModalHeader>
      <Styled.ModalBoxCertificate>
        <div>
          <div className="logo">
            <BusinessLogo className="business" />
          </div>
          <div className="contact">{ModalItem.contact}</div>
          <div className="employee">
            {" "}
            {item?.corporate_purchase_account
              ? item?.corporate_purchase_account
              : ""}
          </div>
        </div>
      </Styled.ModalBoxCertificate>
    </div>
  );
};
