import * as Styled from "./style";
import React from "react";
import { ends } from "./data";

const EndUser = () => {
  return (
    <>
      <Styled.TermsDiv>{ends.mainHead}</Styled.TermsDiv>
      <Styled.MainData className="container">
        <div>
          <div className="about">{ends.imp}</div>
          <div className="register">
            {ends.coversLms}
            <br />
            <span className="web">{ends.carefully}</span>
            {ends.subjects}
            <br />
            {ends.obligations}
            <br />
            {ends.redistribution}
            <br /> {ends.using} <span className="web">{ends.com}</span>
            {ends.personals}
            <br />
            {ends.collect}
          </div>
        </div>
      </Styled.MainData>
      <Styled.LineBack className="container" />
      <Styled.Accept className="container">
        <div>
          <div className="about">{ends.end}</div>
          <div className="register">
            {ends.under}
            <span className="avtavLtd">{ends.avtavLtd}</span>
            {ends.englandRegis} <span className="avtavLtd">{ends.weus}</span>
          </div>
          <div className="register">
            {ends.accessEnd}
            <span className="avtavLtd">{ends.eulas}</span>
            {ends.constituts}
            <span className="avtavLtd">{ends.yoursUse}</span>
            {ends.softUse}
          </div>
          <div className="register">{ends.agree}</div>
          <div className="register">
            {ends.below}
            <br />
            <span className="avtavLtd">{ends.mails}</span>
            {ends.ukmail}
            <br />

            <span className="avtavLtd">{ends.phn}</span>
            {ends.nom}
          </div>
        </div>
        <div>
          <div className="about">{ends.licence}</div>
          <div className="register">{ends.accordance}</div>
          <div className="register">{ends.personal}</div>
          <div className="register">{ends.before}</div>
          <div className="register">{ends.internal}</div>
          <div className="register">{ends.order}</div>
          <div className="register">{ends.business}</div>
          <div className="register">{ends.support}</div>
        </div>
        <div>
          <div className="about">{ends.resp}</div>
          <div className="register">{ends.keeping}</div>
          <div className="register">{ends.technologyComply}</div>
          <div className="register">{ends.download}</div>
          <div className="register">{ends.respect}</div>
          <div className="register">{ends.copy}</div>

          <div className="register">{ends.found}</div>
          <div className="register">{ends.immediately}</div>
          <div className="register">{ends.access}</div>

          <div className="register">{ends.denial}</div>
          <div className="register">{ends.provide}</div>
          <div className="register">{ends.unauthorised}</div>
          <div className="register">{ends.third}</div>
          <div className="register">{ends.ensure}</div>
          <div className="register">{ends.interruptions}</div>
        </div>
        <div>
          <div className="about">{ends.restriction}</div>
          <div className="register">{ends.not}</div>
          <div className="register">{ends.distribute}</div>
          <div className="register">{ends.contained}</div>
          <div className="register">{ends.immoral}</div>
          <div className="register">{ends.agreed}</div>
        </div>
        <div>
          <div className="about">{ends.ship}</div>
          <div className="register">{ends.belong}</div>
          <div className="register">{ends.expressly}</div>
          <div className="register">{ends.source}</div>
        </div>
        <div>
          <div className="about">{ends.fault}</div>
          <div className="register">{ends.does}</div>
          <br />
          <div className="web"> {ends.consumerU}</div>

          <div className="register">{ends.conformity}</div>
          <div className="register">{ends.lifespan}</div>
          <div className="register">{ends.entitled}</div>
          <div className="register">{ends.then}</div>
          <div className="register">{ends.length}</div>
          <div className="register">{ends.promptly}</div>
          <div className="register">{ends.soon}</div>
          <br />
          <div className="web"> {ends.businessU}</div>
          <div className="register">{ends.naturenotify}</div>
          <div className="register">{ends.returning}</div>
          <div className="register">{ends.whole}</div>
        </div>
        <div>
          <div className="about">{ends.liba}</div>
          <div className="register">{ends.must}</div>
          <div className="register">{ends.warrant}</div>
          <div className="register">{ends.uninterrupted}</div>
          <div className="register">{ends.viruses}</div>
          <div className="register">{ends.businessDo}</div>
          <br />
          <div className="web">{ends.foll}</div>
          <div className="register">{ends.provision}</div>
          <div className="register">{ends.inter}</div>
          <div className="register">{ends.saving}</div>
          <div className="register">{ends.goodwill}</div>
          <div className="register">{ends.data}</div>
          <div className="register">{ends.revenue}</div>
          <div className="register">{ends.consequential}</div>
          <div className="register">{ends.unforeseeable}</div>
          <div className="register">{ends.clause}</div>
          <div className="register">{ends.liable}</div>
          <br />
          <div className="web">{ends.apply}</div>

          <div className="register">{ends.negligent}</div>
          <div className="register">{ends.losses}</div>

          <div className="register">{ends.strictly}</div>
          <div className="register">{ends.advice}</div>
        </div>
        <div>
          <div className="about">{ends.termination}</div>
          <div className="register">{ends.commit}</div>
          <div className="register">{ends.also}</div>
          <div className="register">{ends.thisThat}</div>
          <div className="register">{ends.for}</div>
          <div className="register">{ends.stops}</div>
          <div className="register">{ends.software}</div>
        </div>
        <div>
          <div className="about">{ends.gen}</div>
          <div className="register">
            {ends.no9}
            <span className="regist">{ends.eulaInter}</span>
            {ends.thisElua}
          </div>
          <div className="register">{ends.firm}</div>
          <div className="register">{ends.headings}</div>
          <div className="register">{ends.statutes}</div>
          <div className="register">{ends.similar}</div>
          <div className="register">{ends.but}</div>
          <div className="register">{ends.together}</div>
          <div className="register">
            {ends.no92}
            <span className="regist">{ends.thirdP}</span>
            {ends.act}
          </div>
          <div className="register">
            {ends.no93}
            <span className="regist">{ends.costs}</span>
            {ends.legals}
          </div>
          <div className="register">
            {ends.no94}
            <span className="regist">{ends.survival}</span>
            {ends.terminations}
          </div>
          <div className="register">
            {ends.no95}
            <span className="regist">{ends.relationship}</span>
            {ends.dependent}
          </div>
          <div className="register">
            {ends.no96}
            <span className="regist">{ends.otherDeal}</span>
            {ends.subcontract}
          </div>
          <div className="register">
            {ends.no97}
            <span className="regist">{ends.entireAgree}</span>
            {ends.acknowledges}
          </div>
          <div className="register">
            {ends.no98}
            <span className="regist">{ends.variations}</span>
            {ends.giving}
          </div>
          <div className="register">
            {ends.no99}
            <span className="regist">{ends.serve}</span>
            {ends.clauseServe}
          </div>
          <div className="register">
            {ends.no90}
            <span className="regist">{ends.waiver}</span>
            {ends.omission}
          </div>
          <div className="register">
            {ends.no11}
            <span className="regist">{ends.nots}</span>
            {ends.notsParty}
          </div>
          <div className="register">
            {ends.no12}
            <span className="regist">{ends.jurisdiction}</span>
            {ends.england}
            <span className="web">{ends.ireland}</span>
            {ends.northern}
          </div>
        </div>
      </Styled.Accept>
    </>
  );
};

export default EndUser;
