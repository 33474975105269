import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Training = styled.div`
  flex: 0.75;

  .secure {
    background: ${color._15374d};
    height: 60px;
    color: ${color._ffffff};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 34px;
    font-family: Raleway;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Module = styled.div`
  display: flex;
`;
export const TickData = styled.div`
  flex: 0.25;
  .mainModule {
    color: ${color._f5f5f5};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  .maincourse {
    background: var(--Question-Color, ${color._007097});
    height: 93px;
  }
  .mod {
    color: ${color._ffffff};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .mod5 {
    color: ${color._f5f5f5};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  .cancel {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-left: 10px;
  }
  .maincourse {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 61px;
  }
`;

export const ExamData = styled.div`
  width: 100%;
  padding: 35px;
  .secured {
    margin-top: 30px;
  }
  .start {
    width: 123px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._0073de};
    color: ${color._ffffff};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    margin-top: 25px;
  }
  .exam {
    color: ${color._15374d};
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-left: 3px solid ${color._5193aa};
    height: 52px;
    padding-left: 18px;
  }
  .start {
    width: 123px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._0073de};
    color: ${color._ffffff};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    margin-top: 25px;
  }
`;
export const Security = styled.div`
  margin-top: 25px;
  width: 420px;
  height: 67px;
  flex-shrink: 0;
  border-radius: 2px;
  background: ${color._f3fbff};
  padding: 10px;

  .name {
    margin-top: 5px;
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .title {
    color: ${color._16384e};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Introduction = styled.div`
  .intro {
    color: ${color._000000};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .secured {
    margin-top: 30px;
  }
  .mains {
    margin-top: 20px;
  }
  .before {
    color: ${color._767676};
    font-size: 15px;
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .percent {
    color: ${color._177a9c};
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const SingleLine = styled.div`
  height: 0.5px;
  width: 900px;
  background: #d6d6d6;
  margin-top: 33px;
`;
export const Question = styled.div`
  margin-top: 33px;
  .quesNum {
    color: ${color._007097};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 163.986%;
    margin-top: 18px;
  }
  .select {
    color: ${color._6d7d8b};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 18px;
  }
  .options {
    margin-top: 9px;
    color: ${color._5F5F5F};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
  }
  .quesText {
    color: ${color._000000};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    margin-top: 3px;
    line-height: 163.986%;
  }
`;
