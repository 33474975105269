import { styled } from "styled-components";
import { color } from "../../../theme/Color";

export const Order = styled.div`
  display: flex;
  gap: 13rem;
  margin-top: 32px;
  padding-bottom: 37px;
  @media (max-width: 992px) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 11px;
  }
`;
export const OrderText = styled.div`
  .details {
    color: ${color._071b28};
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .number {
    color: ${color._6091a8};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .order {
    color: ${color._6091a8};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const Checkbox = styled.div`
  display: flex;
  gap: 9px;
`;
export const Logo = styled.div``;
export const LogoContainer = styled.div`
  display: flex;
  gap: 1.6rem;
  justify-content: center;
`;
export const FooterLine = styled.hr`
  margin: 0;
  height: 0.5px;
  color: rgba(0, 0, 0, 0.1);
`;
export const PrintButton = styled.div`
  padding-top: 29px;
  padding-bottom: 50px;
  display: flex;
  gap: 10px;
  .print {
    padding: 10px;
    border-radius: 2px;
    background: ${color._15374D};
    color: ${color._fff};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .download {
    padding: 10px;
    background: ${color._fff};
    border-radius: 2px;
    border: 0.5px solid ${color._002E4D};
    color: ${color._15374d};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const OrderDataContainer = styled.table`
  height: 100%;
  width: 100%;
  .line {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
  .product {
    width: 50%;
  }
  .quantity {
    width: 25%;
    text-align: center;
  }
  .price {
    width: 25%;
    text-align: end;
  }
  .value {
    text-align: end;
  }
`;
export const OrderdataGeneralSecurity = styled.div`
  color: ${color._fff};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0px;
`;
export const OrderdataMasterCard = styled.div`
  color: ${color._fff};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;
export const Orderdata = styled.div`
  color: ${color._fff};
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ flag }) => (flag ? "400" : "700")};
  line-height: normal;
  margin: 10px 0px;
`;
export const OrderDescription = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: ${color._6090a8};
  display: flex;
  flex-direction: column;
  padding: 40px;
`;
