import { styled } from "styled-components";
import { color } from "../../../theme/Color";

export const EnquireFormWrapper = styled.form`
  height: 100%;
  background-color: ${color._17374C};
  margin-top: 50px;
  padding: 20px 50px 30px 50px;
  color: ${color._ffffff};
`;
export const TopFormFields = styled.div`
  width: 70%;
`;
export const BottomFormFields = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
export const BottomLeft = styled.div`
  width: 50%;
`;
export const BottomRight = styled.div`
  width: 50%;
`;
export const FormHeading = styled.div`
  font-size: 34px;
  font-weight: bold;
  text-align: center;
`;

export const FormFields = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: ${color._000000};
`;

export const Label = styled.label`
  color: ${color._ffffff};
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  padding: 10px;
  border: 2px solid ${color._ffffff};
  border-radius: 17px;
  background-color: ${color._b6b6b6};
  height: 40px;
`;

export const Select = styled.select`
  padding: 0 5px;
  border: 2px solid ${color._ffffff};
  border-radius: 17px;
  background-color: ${color._b6b6b6};
  height: 40px;
`;

export const Button = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: lightgray;
  cursor: pointer;
`;
