import styled from "styled-components";
import { Drawer } from "antd";
import { color } from "../../../theme/Color";

export const ImgCr = styled.div`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;
export const Container = styled.div`
  position: absolute;
  top: 20%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  .hamburger {
    display: none;
  }
  @media only screen and (max-width: 800px) {
    top: 8%;
    .hamburger {
      display: block;
      position: fixed;
      color: white;
      position: relative;
      left: -8%;
      font-size: 61px;
    }
    .nav {
      display: none;
    }
    .icons {
      display: flex;
      position: relative;
      left: 8%;
    }
  }
`;
export const MobileSide = styled.div`
  @media only screen and (max-width: 800px) {
    background-attachment: fixed;
    background-position: center center;
    max-width: 100%;
    position: relative;
    width: 100%;
  }
`;
export const SideBarOpen = styled.div`
  .activeSideBar {
    display: none;
  }
  display: inline-grid;
  @media only screen and (max-width: 800px) {
    .activeSideBar {
      display: block;
    }
  }
`;
export const DropDown = styled.div`
  .dropdown {
    display: inline-block;
    position: relative;
  }
  .dropdown:hover {
    display: block;
  }
`;

export const Content = styled.div`
  .dropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  .dropdown-content {
    display: block;
  }
`;
export const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  .cartLength {
    position: relative;
  }
  .cartList {
    background: ${color._ffbe32};
    border-radius: 50%;
    color: ${color._16384e};
    position: absolute;
    top: -4px;
    left: 12px;
    padding-bottom: 3px;
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .notification {
    width: 24px;
    height: 24px;
    color: ${color._ffffff};
    &:hover {
      color: #5193aa;
    }
    @media only screen and (max-width: 800px) {
      width: 53px;
      height: 56px;
    }
  }
  .cart {
    width: 25px;
    height: 22px;
    color: ${color._ffffff};
    &:hover {
      color: #5193aa;
    }
    @media only screen and (max-width: 800px) {
      width: 53px;
      height: 56px;
    }
  }

  .JFBtn {
    background: ${color._ffffff};
    border-radius: 50%;
    border: none;
    width: 31px;
    height: 31px;
    color: #081e2c;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
    @media only screen and (max-width: 800px) {
      width: 52px;
      height: 52px;
      color: #081e2c;
      font-size: 32px;
    }
  }
`;

export const NotificationHeading = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 15px;

  .count {
    color: #6d7d8b;
    font-weight: 400;
    font-size: 14px;
    @media only screen and (max-width: 800px) {
      min-width: 50px;
    }
  }

  .heading {
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
  }
  .cartLength {
    position: relative;
  }
  .cartList {
    background: ${color._ffbe32};
    border-radius: 50%;
    color: ${color._16384e};
    position: absolute;
    top: -4px;
    left: 12px;
    padding-bottom: 3px;
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .notification {
    width: 24px;
    height: 24px;
    color: ${color._ffffff};
    @media only screen and (max-width: 800px) {
      width: 53px;
      height: 56px;
    }
  }
`;

export const Draw = styled(Drawer)`
  .ant-drawer-close {
    font-size: 56px;
    color: white;
  }
`;
export const ImageContainer = styled.div`
  background: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 111px;
  padding: 31px;
`;
export const NavItems = styled.div`
  text-decoration: none;
  position: relative;
  color: white;
  align-items: center;
  display: flex;
  z-index: 1;
  cursor: pointer;
  height: 200%;
  &:hover {
    color: #6091a8;
  }
  &.active {
    border-bottom: solid 3px ${color._ffbe32};
  }
`;
export const ItemsSide = styled.div`
  text-decoration: none;
  padding-bottom: 20px;
  position: relative;
  color: white;
  align-items: center;
  display: flex;
  z-index: 1;
  cursor: pointer;

  &.active {
    border-bottom: solid 3px ${color._ffbe32};
  }
  &:hover {
    color: #6091a8;
  }
  @media only screen and (max-width: 800px) {
    font-size: 45px;
  }
`;
export const DropdownContainer = styled.div`
  display: none;
  position: absolute;
  padding: 20px 30px;
  top: 100%;
  z-index: 2;
  ${NavItems}:hover & {
    display: block;
    height: auto;
    min-width: 200px;
    width: max-content;
    background-color: ${color._17374C};
  }
`;

export const DropdownItems = styled.div`
  transition: all 0.2s ease-in-out 0s;
  background: transparent;
  padding: 5px 0;
  display: inline-block;
  font-size: 15px;
  position: relative;
  cursor: pointer;
  color: ${color._ffffff};
  width: 100%;
`;

export const NavContainer = styled.div`
  display: flex;
  width: 60%;
  height: 50px;
  align-items: center;
  justify-content: space-evenly;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export const NavButton = styled.div`
  display: flex;
  gap: 6%;
  @media only screen and (max-width: 800px) {
    gap: 10%;
    position: relative;
    top: 3px;
  }
  .register-button {
    width: 86px;
    height: 29px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    border: none;
    color: ${color._15374D};
    border-radius: 2px;
    background: ${color._fff};
    &:hover {
      background-color: #5193aa;
      color: #ffffff;
    }
  }

  .login-button {
    width: 86px;
    height: 29px;
    border-radius: 2px;
    border: 0.4px solid rgba(239, 243, 249, 0.74);
    background: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: ${color._ffffff};
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
`;

export const Background = styled.div`
  position: relative;
  width: 100%;
  @media only screen and (max-width: 800px) {
    background-attachment: fixed;
    background-position: center center;
    max-width: 100%;
  }
`;

export const ImgContainer = styled.div`
  width: 20%;
  padding-left: 40px;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export const MainContainer = styled.div``;
export const Data = styled.div`
  width: 100%;
  height: 434px;
  background: #16384e;
  color: white;
`;
export const IconData = styled.div`
  display: flex;
  align-items: center;
`;
export const Heading = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const Mail = styled.div`
  color: #6091a8;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const MailData = styled.div`
  padding: 15px 0px 10px 34px;
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(96, 145, 168, 0.14);
  margin: 8px auto;
`;
export const CourserContainer = styled.div`
  padding: 9px 34px 10px 34px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const SettingMethod = styled.div`
  padding: 9px 34px 5px 34px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  .payment {
    margin-top: 22px;
  }
  .account {
    width: 100%;
    height: 31px;
    display: flex;
    align-items: center;
    border: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: white;
    background: rgba(255, 255, 255, 0.46);
  }
`;
export const Help = styled.div`
  padding: 9px 34px 10px 34px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const Business = styled.div`
  display: flex;
  gap: 3%;
  justify-content: center;
  .avtav {
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .business {
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const ParaIcon = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  .buttonNo {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    border: none;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const Notification = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
`;

export const Box = styled.div`
  background: rgba(249, 251, 251, 0.17);
  padding: 10px;
  color: #fff;
  margin-bottom: 12px;
`;
