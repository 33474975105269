import styled from "styled-components";
import { color } from "../../../theme/Color";

export const ThreeDots = styled.div`
  width: 30px;
  height: 30px;
  background: ${color._6d7d8b1f};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color._6d7d8b};
  cursor: pointer;
  position: relative;
`;
