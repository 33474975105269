import * as Styled from "./style";
import BackgroundImageUn from "../../assets/image/BackgroundImageUn.jpg";
import React, { useState } from "react";
import Sidebar from "../../components/Organisms/Sidebar/Sidebar";
import useAuthHook from "../../hooks/AuthHook";
import useCommonHook from "../../hooks/CommonHook";
import { AiOutlineArrowDown } from "react-icons/ai";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { constant } from "../../constants/Constants";
import { Routes } from "../../routes/routes";
import { signIn } from "./SingleSignInData";

const SingleSignIn = () => {
  const [eyeVisible, setEyeVisible] = useState(false);
  const loginReferer = useSelector((state) => state.commonReducer.loginReferer);
  const { updateCommonState } = useCommonHook();
  const { handleLogin, handleLoginChange, loginDetails } = useAuthHook();
  const navigate = useNavigate();
  const handleClickIcon = () => {
    navigate("/");
  };
  const loginButtonHandler = async () => {
    const userData = await handleLogin(loginDetails);
    if (userData && loginReferer === "checkout") {
      updateCommonState({ loginReferer: "" });
      navigate(Routes?.multiCart);
    } else if (userData && loginReferer === "cart") {
      updateCommonState({ loginReferer: "" });
      navigate(Routes?.multiCart);
    } else if (userData && userData?.status) {
      navigate("/");
    }
  };
  const handleEyeChange = () => {
    setEyeVisible(!eyeVisible);
  };
  return (
    <>
      <Styled.Container>
        <Styled.Cross>
          <MdCancel onClick={handleClickIcon} className="cross" />
        </Styled.Cross>

        <Styled.SignContainer>
          <Styled.ImageContainer
            src={BackgroundImageUn}
            alt={constant.CorporateImage}
          >
            <Sidebar />
          </Styled.ImageContainer>
        </Styled.SignContainer>

        <Styled.FormContainer>
          <Styled.Head>
            <Styled.Heading>
              {constant.Login}
              <Styled.Icon>
                <AiOutlineArrowDown className="arrowIcon" />
              </Styled.Icon>
            </Styled.Heading>
            {/* <Styled.Title>
              <Styled.Acc>{constant.DontHaveAccount}</Styled.Acc>
              <Styled.SignIn onClick={() => navigate(Routes?.askingPage)}>
                {constant.register}
              </Styled.SignIn>
            </Styled.Title> */}
          </Styled.Head>

          <Styled.Line />
          <Styled.Box>
            <Styled.DetailsButton>
              <Styled.Details>
                <Styled.Columns>
                  <Styled.Label>
                    {signIn?.username}
                    <span className="star">{constant.star}</span>
                  </Styled.Label>
                  <Styled.Input
                    type="text"
                    name="userName"
                    value={loginDetails?.userName}
                    onChange={handleLoginChange}
                  />
                  <Styled.Label className="bottom">
                    {signIn?.password}
                    <span className="star">{constant.star}</span>
                  </Styled.Label>
                  <Styled.MainPassword>
                    <input
                      type={eyeVisible ? "text" : "password"}
                      name="password"
                      value={loginDetails?.password}
                      onChange={handleLoginChange}
                      className="passwordEye"
                    />
                    <span onClick={handleEyeChange} className="eye">
                      {eyeVisible ? (
                        <FaEye className="eyw" />
                      ) : (
                        <FaEyeSlash className="eyw" />
                      )}
                    </span>
                  </Styled.MainPassword>
                </Styled.Columns>
              </Styled.Details>
              <Styled.NextBtn>
                <button
                  className="next_step"
                  onClick={() => loginButtonHandler()}
                >
                  {constant.NextStep}
                </button>
              </Styled.NextBtn>
            </Styled.DetailsButton>
          </Styled.Box>
        </Styled.FormContainer>
      </Styled.Container>
    </>
  );
};

export default SingleSignIn;
