export const data = {
  Hello: "Hello,",
  JohnDoe: "John Doe",
  thankyou: "Thank you for joining us here.",
  info: "Your information was already provided by your employer ",
  employer: "employer_name_here ",
  password:
    "  so we have all we need to setup your account except for your password.",
  proceed:
    "Please proceed to the next step where you can provide it to keep your account secure.",
};
export const website = {
  terms: "Website Terms of Use",
  aboutMain: `1. ABOUT THESE TERMS`,
  apply: `1.1 These terms apply to your access and use of www.avtav.co.uk website `,
  web: `(the Website). `,
  rev: `You should also review our Website Privacy Notice (https://avtav.co.uk/resources/) which explains what personal data we collect when you use our website, as well as our Acceptable Use Policy (https://avtav.co.uk/resources/) on posting content to our Website.`,
  accessing: `1.2 By accessing our Website, you acknowledge that these terms are legally binding. If you don’t agree with any of these terms, you should stop accessing and using our Website.`,
  about: "2 . ABOUT US",
  registered:
    "2.1 We are AVTAV LTD (trading as AVTAV LTD), a company registered in England and Wales under company registration number 12704296. Our registered office is at Suite A, Ground floor Sovereign Court Sipson Road, West Drayton and our postal address is UB70JE. Our VAT registration number is 380839665.",
  with: " 2.2 We are registered with the following regulator: ICO under registration number: ZB147955.",
  questions:
    "2.3 If you have any questions about the Website, please contact us by:",
  sending: "2.3.1 sending an email to hr@avatv.co.uk;",
  filling:
    "2.3.2 filling out and submitting the online form available here https://avtav.co.uk/contact/;",
  calling:
    " 2.3.3 calling us on 0203 9844196 (our telephone lines are open Monday to Friday 09.00am - 17.00pm).",
  using: "3 . USING THE WEBSITE ",
  reserve:
    "3.1 We reserve the right to suspend the operation of our Website at any time. We may also restrict access to some parts of our Website to users who have registered with us.",
  responsible:
    "3.2 You are responsible for maintaining your own internet connection of accessing the Website.",
  condition:
    "3.3 As a condition of your use of the Website, you agree to comply with our Acceptable Use Policy available at https://avtav.co.uk/resources/ and agree not to:",
  misuse:
    " 3.3.1 misuse or attack our Website by knowingly introducing viruses, trojans, worms, logic bombs or any other material which is malicious or technologically harmful (such as by way of a denial-of- service attack);",
  unauthorised:
    "3.3.2 attempt to gain unauthorised access to our Website, the server on which our Website is stored or any server, computer or database connected to our Website; or",
  remove:
    "3.3.3 remove, delete, obscure, disable, modify, add to, tamper with, or circumvent any program code or data, copyright, trademark, or other proprietary notices, labels or copy protection software contained on the Website.",
  prevent:
    "3.4 We may prevent or suspend your access to the Website if you do not comply with these terms or any applicable law.",
  suspend:
    "3.5 We may suspend or terminate access or operation of the Website at any time as we see fit.",
  promise:
    "3.6 We do not promise that the Website will be available at all times or that your use of the Website will be uninterrupted or error-free.",
  password: "4. USER ACCOUNT AND PASSWORD SECURITY",
  need: "4.1 If you need a username or password to access any part of our Website you agree to:",
  detailsText: "4.1.1 ensure that any details provided to us are accurate;",
  secure: "4.1.2 keep your username or password secure and confidential;",
  compromised:
    "4.1.3 let us know promptly if you believe that your username or password has been compromised.",
  terminate:
    "4.2 We may terminate your access to any Website or any password-protected areas of our Website at any time, if we believe that your use of our Website is affecting the security and stability of our Website or is detrimental to other users. ",
  INTELLECTUAL: " 5. INTELLECTUAL PROPERTY RIGHTS",
  licensors:
    "5.1 The intellectual property rights in the Website and in any text, images, video, audio or other multimedia content, software or other information or material submitted to or accessible from the Website ",
  owned: "are owned by us and our licensors.",
  content: " (Content) ",
  anywhere:
    "5.2 We and our licensors reserve all our intellectual property rights (including, but not limited to, all copyright, trade marks, domain names, design rights, database rights, patents and all other intellectual property rights of any kind) whether registered or unregistered anywhere in the world. This means, for example, that we remain owners of them and are free to use them as we see fit.",
  grants:
    "5.3 Nothing in these terms grants you any legal rights in the Website or the Content other than as necessary for you to access it.",
  express:
    "5.4 You may not use any trade marks or trade names that are displayed on this Website, unless you have our express written permission.",
  on: "6 INFORMATION ON THE WEBSITE ",
  sure: " 6.1 We try to make sure that the Website is accurate, up-to-date and free from bugs, but we do not guarantee that it will be. ",
  suitable:
    " 6.2 We do not guarantee that the Website will be fit or suitable for any particular purpose. Any reliance that you may place on the information on the Website is at your own risk.",
  sites: "7. THIRD PARTY SITES",
  control:
    "7.1 We have no control over, and don’t accept any responsibility for, the content of any third party website. If we include a link to any third party website, this doesn’t mean that we endorse or recommend the organisation which operates it. Your use of a third party site may be governed by the terms and conditions of that third-party site and is at your own risk.",
  limit: "8. LIMIT OF LIABILITY",
  responsibility:
    "8.1 Except for any legal responsibility that we cannot exclude in law (such as for death or personal injury) or arising under applicable laws relating to the protection of your personal information, we are not legally responsible for any: ",
  losses:
    "8.1.1 losses that were not foreseeable to you and us when these terms were formed; ",
  caused: "8.1.2 losses that were not caused by any breach on our part;",
  business: "8.1.3 business losses; and ",
  non: "8.1.4 losses to non-consumers.",
  variation: "9. VARIATION",
  responsibilityText:
    "9.1 We reserve the right to amend these terms. Our updated terms will be displayed on the Website. By continuing to use and access the Website, you agree to be bound by any future updates to these terms as and when they are updated. It is your responsibility to check these terms from time to time to verify such variations.",
  complaint: "10. COMPLAINTS AND QUERIES ",
  contact:
    "10.1 If you have any questions about our Website, or have any complaints about its contents, please contact us at https://avtav.co.uk/contact/.",
  law: `11. LAW AND JURISDICTION `,
  consumer:
    "11.1 The laws of England and Wales apply to these terms. Any disputes will be subject to the exclusive jurisdiction of the courts of England and Wales, unless you are a consumer and live in either Northern Ireland or Scotland. In which case, you can choose to bring a claim in England and Wales or in the courts of another part of the United Kingdom in which you live.",
  notice: "Website Privacy Notice",
  back: "1 BACKGROUND",
  update: " Last updated: 3 November 2023.",
  this: "1.1 This notice",
  not: " (Privacy Notice)",
  where: ` tells you how we look after your personal data when you visit our website at https://avtav.co.uk/ (Website), where you are a prospective customer of our business, or where you are another type of business contact, such as a supplier or service provider to our business.`,
  personal: `1.2 This notice sets out what information we collect about you, what we use it for and whom we share it with. It also explains your rights under data protection laws and what to do if you have any concerns about your personal data.`,
  regularly: `1.3 We may sometimes need to update this Privacy Notice to comply with new business practices or legal requirements. You should check this Privacy Notice regularly to see whether any changes have occurred.`,
  impInfo: ` 2 WHO WE ARE AND OTHER IMPORTANT INFORMATION`,
  ltd: `2.1 We are AVTAV LTD, registered in England and Wales with company number 12704296 with our registered address at 625 - 635 Sovereign Court Suite A, Ground Floor, West Drayton, UB7 0JE (we, us or our).`,
  visit: `2.2 For all visitors to our Website we are the`,
  controller: "controller",
  collect:
    "of your information (which means we decide what information we collect and how it is used).",

  number: `2.3 We are registered with the Information Commissioner’s Office (ICO), the UK regulator for data protection matters, under number ZB147955.`,
  contactDet: "3 CONTACT DETAILS",
  touch: `3.1 If you have any questions about this Privacy Notice or the way that we use information, please get in touch using the following details:`,
  email: `- Email address: `,
  uk: `hr@avtav.co.uk`,
  postal: ` - Postal address: `,
  court: `Suite A, Ground Floor 625-635 Sovereign Court Sipson Road, West Drayton UB7 0JE`,
  transfer: "7 WHERE YOUR INFORMATION IS LOCATED OR TRANSFERRED TO ",
  servers: " 7.1 We store your personal data on our servers in the UK.",
  secretary:
    "7.2 We will only transfer information outside of the UK or EEA where we have a valid legal mechanism in place (to make sure that your personal data is guaranteed a level of protection, regardless of where in the world it is located, e.g. by using contracts approved by the ICO or the UK Secretary of State).",
  organisation: ` 7.3 If you access our Website whilst abroad then your personal data may be stored on servers located in the same country as you or your organisation.`,
  safe: `8 HOW WE KEEP YOUR INFORMATION SAFE `,
  being: `8.1 We have implemented security measures to prevent your personal data from being accidentally or illegally lost, used or accessed by those who do not have permission. These measures include: -`,
  authentication: `- access controls and user authentication (including multi-factor authentication);`,
  network: ` - internal IT and network security;`,
  review: `- regular testing and review of our security measures;`,
  staff: "- staff policies and training;",
  breach: `- incident and breach reporting processes.`,
  informed: `8.2 If there is an incident which has affected your personal data and we are the controller, we will notify the regulator and keep you informed (where required under data protection law). Where we act as the processor for the affected personal data, we notify the controller and support them with investigating and responding to the incident.`,
  please: ` 8.3 If you notice any unusual activity on the Website, please contact us hr@avtav.co.uk.`,
  KEEP: `  9 HOW LONG WE KEEP YOUR INFORMATION`,
  fulfil: ` 9.1 Where we act as the controller, we will only retain your personal data for as long as necessary to fulfil the purposes we collected it for.`,
  revenue: `9.2 To decide how long to keep personal data (also known as its retention period), we consider the volume, nature, and sensitivity of the personal data, the potential risk of harm to you if an incident were to happen, whether we require the personal data to achieve the purposes we have identified or whether we can achieve those purposes through other means (e.g. by using aggregated data instead), and any applicable legal requirements (e.g. minimum accounting records for HM Revenue & Customs).`,
  contractual: `9.3 We may keep Identity Data, Contact Data and certain other data (specifically, any exchanges between us by email or any other means) for up to seven years after the end of our contractual relationship with you.`,
  purposes: ` 9.4 If you browse our Website, we keep personal data collected through our analytics tools for only as long as necessary to fulfil the purposes we collected it for. `,
  details: ` 9.5 If you have asked for information from us or you have subscribed to our mailing list, we keep your details until you ask us to stop contacting you.`,
  legal: `10 YOUR LEGAL RIGHTS`,
  relation: `10.1 You have specific legal rights in relation to your personal data.`,
  always: `10.2 We can decide not to take any action in relation to a request where we have been unable to confirm your identity (this is one of our security processes to make sure we keep information safe) or if we feel the request is unfounded or excessive. Usually there is no cost for exercising your data protection rights, but we may charge a fee where we decide to proceed with a request that we believe is unfounded or excessive. If this happens we will always inform you in writing.`,
  extension: `10.3 We will respond to your legal rights request without undue delay, but within one month of us receiving your request or confirming your identity (whichever is later). We may extend this deadline by two months if your request is complex or we have received multiple requests at once. If we need to extend the deadline, we will let you know and explain why we need the extension.`,
  instruction: `10.4 We do not respond directly to requests which relate to personal data for which we act as the processor. In this situation, we forward your request to the relevant controller and await their instruction before we take any action.`,
  belowyou: ` 10.5 If you wish to make any of the right requests listed below, you can reach us at hr@avtav.co.uk.`,
  rightsInclude: `10.6 Your rights include:`,
  access: " - Access:",
  usingDes: `You must be told if your personal data is being used and you can ask for a copy of your personal data as well as information about how we are using it to make sure we are abiding by the law.`,
  correction: `- Correction:`,
  new: ` You can ask us to correct your personal data if it is inaccurate or incomplete. We might need to verify the new information before we make any changes.`,
  deletion: ` - Deletion:`,
  restriction: ` You can ask us to delete or remove your personal data if there is no good reason for us to continue holding it or if you have asked us to stop using it (see below). If we think there is a good reason to keep the information you have asked us to delete (e.g. to comply with regulatory requirements), we will let you know and explain our decision.`,
  Restriction: `- Restriction:`,
  temporarily: ` You can ask us to restrict how we use your personal data and temporarily limit the way we use it.`,
  objection: ` - Objection:`,
  explain: ` You can object to us using your personal data if you want us to stop using it. If we think there is a good reason for us to keep using the information, we will let you know and explain our decision.`,
  probability: `- Portability:`,
  ask: ` You can ask us to send you or another organisation an electronic copy of your personal data.`,
  complaints: `- Complaints:`,
  reaches: `If you are unhappy with the way we collect and use your personal data, you can complain to the ICO or another relevant supervisory body, but we hope that we can respond to your concerns before it reaches that stage. Please contact us at hr@avtav.co.uk. `,
  message: ` 11 WHEN WE SEND YOU MARKETING MESSAGES`,
  consented: ` 11.1 If you have consented to receiving marketing messages from us, you can opt out of these at any time. Just let us know at hr@avtav.co.uk.`,
  affect: ` 11.2 Opting out of marketing will not affect our processing of your personal data in relation to any order you have with us and where we are required to use your personal data to fulfil that order or provide you with certain information.`,
  shareInfo: `6 WHO WE SHARE YOUR INFORMATION WITH`,
  sharePersonal: `6.1 We share (or may share) your personal data with:`,
  ourPer: `- Our personnel: `,
  confidentiality: `our employees (or other types of workers) who have contracts containing confidentiality and data protection obligations.`,
  supplyChain: `- Our supply chain:`,
  protection: ` other organisations that help us provide our goods. We ensure these organisations only have access to the information required to provide the support we use them and have a contract with them that contains confidentiality and data protection obligations.`,
  regulatoryAuth: ` - Regulatory authorities:`,
  customs: `such as HM Revenue & Customs.`,
  professional: `- Our professional advisers:`,
  conduct: `such as our accountants or legal advisors where we require specialist advice to help us conduct our business.`,
  providing: `6.2 If we were asked to provide personal data in response to a court order or legal request (e.g. from the police), we would seek legal advice before disclosing any information and carefully consider the impact on your rights when providing a response.`,
  aboutYouInfo: `4 THE INFORMATION WE COLLECT ABOUT YOU`,
  no4: `4.1 `,
  dataPersonal: `Personal data`,
  identifyInfo: `means any information which does (or could be used to) identify a living person. We have grouped together the types of personal data that we collect, and where we receive it from, below.`,
  second: `4.2 Type of personal data:`,
  ident: `- Identity Data:`,
  name: ` your first and last name or title.`,
  contactData: `- Contact Data:`,
  emailAddress: `your email address, telephone numbers, home address.`,
  technial: ` - Technical Data:`,
  usage: ` internet protocol (IP) address, browser type and version, time zone setting and generic location, browser plug-in types and versions, operating system and platform on the devices you use to access our systems. - Usage Data: information about how you use our systems.`,
  useData: `- Usage Data:`,
  system: `information about how you use our systems.`,
  financialData: ` - Financial Data:`,
  locationData: `- Location Data:`,
  questionnaires: `where you provide this over our Website to purchase one of our products or services. - Location Data: your device location if you log into our systems remotely. - Feedback: information and responses you provide when completing surveys and questionnaires.`,
  remotely: ` your device location if you log into our systems remotely.`,
  feedback: `- Feedback:`,
  completing: ` information and responses you provide when completing surveys and questionnaires.`,
  profileData: ` - Profile Data:`,
  accessed: `email address, password, username, chat logs, audit trail of systems used and documents accessed and downloaded.`,
  market: ` - Marketing and Communication Data:`,
  parties: `includes your preferences in receiving marketing from us and our third parties and your communication preferences.`,
  yourInfo: `5 HOW WE USE YOUR INFORMATION`,
  mostrelevant: `5.1 We are required to identify a legal justification (also known as a lawful basis) for collecting and using your personal data. There are six legal justifications which organisations can rely on. The most relevant of these to us are where we use your personal data to: `,
  listed: ` 5.2 Below is set out the lawful basis we rely on when we use your personal data. If we intend to use your personal data for a new reason that is not listed below, we will update our Privacy Notice.`,
  no5: `5.2.1 `,
  legalOblig: `Legal Obligation`,
  marketingRecord: `- Recording your preferences (e.g. marketing) to ensure that we comply with data protection laws. - Where we send you information to comply with a legal obligation (e.g. where we send you information about your legal rights).`,
  complywhere: ` - Where we send you information to comply with a legal obligation (e.g. where we send you information about your legal rights).`,
  retainInfo: `- Where we retain information to enable us to bring or defend legal claims. `,
  no52: `5.2.2 `,
  legitimate: `Legitimate Interests`,
  pursue: ` - Where using your information is necessary to pursue our legitimate business interests to:`,
  optimise: `a) improve and optimise our Website;`,
  enhance: `b) monitor and make improvements to our Website to enhance security and prevent fraud;`,
  functioning: `c) provide our services to you and ensure the proper functioning of our Website; and`,
  defendProtect: ` d) protect our business and defend ourselves against legal claims.`,
  infringe: `- Where we use your information for our legitimate interests, we have assessed whether such use is necessary and that such use will not infringe on your other rights and freedoms.`,
  example: ` 5.3 Where we need to collect your personal data (for example, in order to fulfil a contract we have with you), failure to provide us with your personal data may mean that we are not able to provide you with the services. Where we do not have the information required about you to fulfil an order, we may have to cancel the service ordered.`,
  comp: `- comply with a  `,
  obj: `legal obligation`,
  have: ` that we have;`,
  pursueOur: ` - pursue our  `,
  int: `legitimate interests `,
  just: `(our justifiable business aims) but only if those interests are not outweighed by your other rights and freedoms (e.g. your right to privacy).`,
};
export const acceptable = {
  accept: "Acceptable Use Policy",
  policy: "1 ABOUT THIS POLICY ",
  sets: `1.1 This Policy sets out the standards that apply when you use, upload and view our website www.avtav.co.uk `,
  website: `(Website).`,
  agreeing: `1.2 By using our Website, you are agreeing to follow the terms of this policy.`,
  Ifyoudo: ` If you do not agree with this policy, you must not use our Website.`,
  agree: ` If you do not agree with this policy, you must not use our Website.`,
  updates: `1.3 We may amend this policy from time to time if the needs of our business changes. Please check back regularly to check for any updates.`,
  alongside: `1.4 This policy should be read alongside our website terms and conditions https://avtav.co.uk/resources/`,
  terms: `(Website Terms of Use)`,
  found: ` and our privacy notice found here https://avtav.co.uk/resources/.`,
  we: ` 2 WHO ARE WE`,
  drayton: `2.1 We are AVTAV LTD, registered in England and Wales with company number 12704296 whose registered address is 625 - 635 Sovereign Court Suite A, Ground Floor, West Drayton, UB7 0JE`,
  us: `(we/ us/ our).`,
  using: ` 3 USING OUR WEBSITE`,
  not: ` 3.1 When using our Website, you must not use our Website: `,
  national: ` 3.1.1 in any way that breaches any applicable local, national or international law or regulation;`,
  fraudulent: `3.1.2 in any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect;`,
  harming: `3.1.3 for the purpose of harming or attempting to harm minors in any way;`,
  humiliate: `3.1.4 to bully, insult, intimidate or humiliate any person;`,
  content: `3.1.5 to send, knowingly receive, upload, download, use or re-use any material which does not comply with our terms of User Generated Content below; `,
  spam: `3.1.6 to transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam);`,
  operation: `3.1.7 to knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware;`,
  terrorist: `3.1.8 to upload terrorist content.`,
  res: `4 YOUR RESPONSIBILITIES`,
  must: ` 4.1 When using our website you must:`,
  unlawful: `4.1.1 notify us immediately if you suspect that any use of the Services or Website is, or will lead to activity that is, fraudulent or unlawful;`,
  rights: ` 4.1.2 notify us immediately of any circumstances where any intellectual property rights or any other rights of any third party may have been infringed;`,
  distress: ` 4.1.3 not do or promote anything likely to impair, interfere with or damage our platform or electronic communications network, or cause harm, harassment or distress to any persons; `,
  messaging: `4.1.4 not do anything which involves the transmission of junk mail, chain letters, unsolicited mass mailing, instant messaging, “spimming” or “spamming”;`,
  denial: `4.1.5 not misuse or attack our networks or platforms by knowingly introducing viruses, trojans, worms, logic bombs or any other material which is malicious or technologically harmful (such as by way of a denial-of-service attack);`,
  contravenes: `4.1.6 not use our Website in any way which contravenes our Website Terms of Use, including:`,
  re: `- reproducing, duplicating, copying or re-selling any part of the Website; `,
  without: `- accessing any of the Website without our authority; or`,
  damaging: `- damaging or disrupting any part of the Website.`,
  generate: `5 USER GENERATED CONTENT`,
  enables: `5.1 Where our Website enables you to post messages, profiles or any other form of communication on the Website  `,
  userr: `(User Generated Content),`,
  you: `you must not:`,
  hatred: `5.1.1 promote racism, bigotry, hatred or physical harm of any kind against any group or individual;`,
  harass: `5.1.2 harass or advocate harassment of another person; `,
  sexually: `5.1.3 display pornographic or sexually explicit material; `,
  libellous: `5.1.4 promote any conduct that is abusive, threatening, obscene, defamatory or libellous;`,
  activities: `5.1.5 promote any illegal activities;`,
  violating: `5.1.6 provide instructional information about illegal activities, including violating someone else’s privacy;`,
  shall: `5.1.7 create computer viruses or implement any form of software or scripts onto the Website that have the appearance of coming from a user or candidate (for the avoidance of doubt, this shall not apply to API use); `,
  inaccurate: `5.1.8 promote or contain information that you know or believe to be inaccurate, false or misleading;`,
  sweepstakes: `5.1.9 engage in the promotion of contests, sweepstakes and pyramid schemes, without our prior written consent;`,
  exploit: `5.1.10 exploit people in a sexual or violent manner;`,
  third: `5.1.11 invade or violate any third party’s right to privacy.`,
  links: `5.2 We reserve the right to refuse to publish any User Generated Content or links to third party content, or to at any time remove or edit User Generated Content (in whole or in part) or any link to third party content, if we have reason to believe that a user has breached this policy.`,
  beach: `6 BREACH OF THIS POLICY`,
  occurred: `6.1 Failure to follow the terms of this policy will be considered a material breach of our Website Terms of Use. Where we consider a breach has occurred, we may (but are not limited to):`,
  issue: ` 6.1.1 issue a warning to you; `,
  part: `6.1.2 suspend your use of all or part of the Website; `,
  remove: `6.1.3 remove any User Generated Content you have made on the Website;`,
  suspends: ` 6.1.4 close or suspend any user account details you have with us; `,
  authorities: ` 6.1.5 disclose any User Generated Content to law enforcement authorities as appropriate or as required by law; and/or`,
  take: `6.1.6 take legal action against you.`,
  comp: `7 COMPLAINTS `,
  please: `7.1 If you wish to complain about our Website, or query any content uploaded by other users, including any User Generated Content or third party content, please contact us at https://avtav.co.uk/contact/.`,
  laws: ` 8 LAW AND JURISDICTION `,
  kingdom: `8.1 Please note that the laws of England and Wales apply to the terms of this policy. Any disputes will be subject to the exclusive jurisdiction of the courts of England and Wales, unless you are a consumer and live in either Northern Ireland or Scotland. In which case, you can choose to bring a claim in England and Wales or in the courts of another part of the United Kingdom in which you live.`,
};
export const online = {
  online: "Online Terms for the Sale of Digital Content",
  about: `1 ABOUT THESE TERMS`,
  no2: `1.2`,
  pleaseRead: ` Please read these Terms carefully. `,
  provide: `1.1 These terms and conditions`,
  termsMain: `(Terms)`,
  out: ` set out the terms on which you can purchase LMS `,
  contentMain: `(Content)`,
  webs: ` through our website `,
  websiteMain: `(Website)`,
  acceptMain: `. You must accept these Terms for us to provide the Content to you. `,
  liability: `These Terms tell you who we are and other important information. They describe how we sell our Content, set out your rights and responsibilities and tell you what to do if there is a problem.`,
  limited: ` These Terms also limit our liability.`,
  clearly: `1.3 Some of these Terms only apply if you are purchasing Content as a `,
  consume: `consumer`,
  prime: `. This means that you primarily use the Content for personal use (and will not use our Content for commercial, business or resale purposes). Terms that only apply to consumers will be marked clearly.`,
  purchasing: ` 1.4 By purchasing our Content, you are agreeing to these Terms. `,
  termsMains: `If you do not agree to these Terms, you must not purchase our Content.`,
  please: `1.6 We will collect some personal data about you in order to process your order (e.g. your name, email address, payment details and delivery information). For information regarding how we process personal data, please see our privacy policy at https://avtav.co.uk/training/.`,
  website: ` 1.7 These terms cover the terms and conditions if you purchase Content via the Website. For information regarding access to and use of our Website, please see our Website terms of use at https://avtav.co.uk/resources/.`,
  who: ` 2 WHO ARE WE?`,
  drayton: ` 2.1 We are AVTAV LTD, registered in England and Wales with company number 12704296 whose registered address is 625 - 635 Sovereign Court Suite A, Ground Floor, West Drayton, UB7 0JE (we/ us/ our). Our VAT number is 38083665.`,
  training: `2.2 We are a Training company and in the business of selling Training courses.`,
  question: `2.3 If you have any questions about these Terms, please contact us using the details below:`,

  mail: ` Email`,
  train: `: training@avtav.co.uk`,
  ph: ` Phone`,
  phNo: `: 0203 9844195`,
  place: `3 PLACING AN ORDER`,
  purchase: `3.1 To purchase our Content, you need to place an order on our Website`,
  accurate: ` 3.2 Please check your order carefully before confirming it. You are responsible for ensuring that your order is complete and accurate.`,
  email: `3.3 The order will only be accepted when we send you a written acceptance of the order by email, at which point a contract between you and us will be created that is subject to these Terms. We will then send you a confirmation email to let you know how you can receive the Content.`,
  consumerText: ` 3.4 We reserve the right to accept or reject any order at our discretion. If we are unable to accept your order, we will notify you as soon as possible. If you are a consumer:`,
  youCon: `If you are a consumer: `,
  individual: `3.5 By placing an order, you confirm that you are an individual who is:`,
  least: `3.5.1 at least 16 years old; and `,
  resident: `3.5.2 a resident in the UK.`,
  make: `3.6 If you would like to make any changes to your order after you have submitted it, please contact us as soon as possible and we will let you know if it is possible to change your order.`,
  desc: `4 DESCRIPTION OF OUR CONTENT`,
  set: ` 4.1 The description of the Content is as set out on our Website.`,
  your: ` 4.2 We cannot guarantee that the colours displayed on your device when using purchased Content will match the exact colours shown on our Website. The colours of the Content may vary depending on what device you are using and your settings.`,
  clear: `4.3 Where your device is required to meet technical requirements to run or display Content purchased on our Website, we will make this clear on our Website.`,
  requirements: `4.4 We are not responsible for Content which fails to meet its description where your device does not meet the technical requirements as set out on our Website.`,
  providing: `4.5 When providing our Content to you, we will:`,
  theseText: `4.5.1 provide our Content to you in accordance with these Terms; and `,
  applicable: `4.5.2 comply with all applicable laws.`,
  digital: ` 5 DELIVERY OF DIGITAL CONTENT`,
  link: `5.1 The Content you purchased can be downloaded or accessed via the link we provided you in your order confirmation email.`,
  downloading: `5.2 If you download Content onto someone else’s device, please make sure you obtain the owner’s permission before downloading Content onto their device.`,
  uk: `5.3 If you are having any trouble downloading or accessing the Content, please email us at training@avtav.co.uk or call us at 0203 9844195. `,
  licence: ` 6 LICENCE`,
  where: ` 6.1 Where you use the Content in accordance with these Terms: `,
  businessText: ` 6.1.1 we grant you a non-exclusive, non-transferable licence to use the Contentand, where you are a business customer, for use within your business and to access the content provided for internal business purposes only; and`,
  number: `6.1.2 you shall be limited to the number of users paid for in accordance with our charges. We will confirm the number of users to you.`,
  internal: `6.2 So as long as you do not violate any restrictions set out in these Terms, you may make a limited number of copies of the Content for the purpose of providing a backup in accordance with your own internal operating procedures.`,
  restriction: `7 LICENCE RESTRICTIONS `,
  must: `7.1 You must not: `,
  partiesText: `7.1.1 sell, distribute, reproduce, transfer, publicly display, translate, modify, adapt, create derivative works from, deconstruct, reverse engineer, decompile or disassemble, rent, lease, loan, sub-license or otherwise deal in copies or reproductions of the Content to other parties in any way except as permitted by these Terms;`,
  contained: `7.1.2 remove, delete, obscure, disable, modify, add to, tamper with, or circumvent any program code or data, copyright, trademark, or other proprietary notices, labels or copy protection Content contained on or within the Content; `,
  literature: `7.1.3 exploit the Content (or part of), content contained in it, any of our related literature, for any commercial purpose;`,
  mimic: ` 7.1.4 export or re-export the Content or any copy or adaptation in violation of any applicable laws or regulations; 7.1.5 create data or executable programs which mimic data or functionality in the Content;`,
  illegalText: `7.1.6 use the Content for any illegal or immoral purposes;`,
  programme: ` 7.1.7 use the Content to make any other content or programme which is substantially similar to the Content.`,
  unless: `7.2 You agree not to perform any security testing on the Content unless agreed with us in writing.`,
  permitted: `7.3 You do not have permission and are not permitted to access the Content in source code form.`,
  rightInt: `8 OUR INTELLECTUAL PROPERTY RIGHTS`,
  except: `8.1 You agree that we and our licensors own all intellectual property rights in the Content. These Terms do not grant you any rights to any intellectual property rights in the Content except as expressly set out in these Terms.`,
  written: ` 8.2 You must not use our trade marks or our trade names on your website or in any marketing materials without our express written consent. 9 PRICE AND PAYMENT `,
  pricePay: `9 PRICE AND PAYMENT `,
  inclusive: `9.1 The price for our Content will be shown on the Website and as set out in your order (Price). The Price is inclusive of VAT.`,
  affect: ` 9.2 Prices for our Content may change at any time. This will not affect existing orders unless: `,
  materially: `9.2.1 the information you provided us in relation to your order was materially different from the information we required in order to provide the Content; or`,
  will: ` 9.2.2 there has been an error on the Website regarding the pricing of any of our Content and this affects your order, we will try to contact you using the contact details you provided when you placed your order. If this happens, we will give you the option to re-confirm your order at the correct price or to cancel your order. `,
  advance: `9.3 We will charge the Price to the credit or debit card that you have provided at the time we accept the order. All amounts due must be paid in full in advance.`,
  authorised: `9.4 All credit card and debit card payments need to be authorised by the relevant card issuer.`,
  invoice: ` 9.5 If we agree to provide you with an invoice for payment, we will invoice you as follows:`,
  within: ` 9.6 Your invoice will be sent to the email address you provided when you placed your order and must be paid by you within 7 days of receipt.`,
  shall: `9.7 If your payment is not received by us when due, we may charge interest on any balance outstanding at the rate of 2% per year above the Bank of England’s base rate (or where the Bank of England’s base rate is less than 0%, interest shall be charged at 2% per year).`,
  stripe: ` 9.8 We use a third-party payment provider (Stripe ) to process payments on our Website.`,
  applies: `This clause 10 only applies where you purchase Content from us as a consumer.`,
  rightCon: `10 YOUR RIGHTS AS A CONSUMER`,
  date: ` 10.1 Except as set out in clause 10.2, you have 14 days from the date of your order confirmation email to change your mind and cancel your order.`,
  following: `10.2 You will lose your right to cancel in the following circumstances:`,
  broken: `10.2.1 where we have provided you with Content in physical form and you have broken the seal or wrapping; `,
  during: `10.2.2 where you have started using the Content during the 14-day cancellation period; or`,
  whereyou: `10.2.3 where you have downloaded the Content during the 14-day cancellation period.`,
  cancel: ` 10.3 To cancel your order, please email us at training@avtav.co.uk or call us on 0203 9844195. You can also use the cancellation form available at https://avtav.co.uk/training/.`,
  quickly: ` 10.4 To help us process your cancellation more quickly, please have your order details ready or include them in the email or cancellation form you send to us.`,
  whichever: `10.5 Where we have provided you with Content in a physical format (e.g. in CD or DVD disks) and you have already received your order, you must return the products to us within 14 days of telling us that you want to cancel your order. The deadline is met if you send the products back to us before the 14-day period has expired. We strongly recommend that you get proof of postage. We may withhold the refund until we have received the products back from you or until you have provided us with evidence that you have sent the products back (whichever is earlier).`,
  possession: `10.6 Content must be returned to us in a new and unused condition and, to the extent possible, in its original packaging. We may make a deduction from the refund amount if you have handled the product in a way that has diminished the value of the product, if such handling was beyond what is necessary to establish the nature, characteristics and functioning of the products. You are responsible for the products while they are in your possession. `,
  visit: `10.7 Unless the Content is faulty or misdescribed, you are responsible for the cost of returning any Content provided via physical means to us. For information on how to return Content to us, please visit https://avtav.co.uk/training/. `,
  once: ` 10.8 Once we have received the returned Content and are satisfied that the wrapping for the Content is in new and unused condition, we will provide you with a full refund (including basic delivery charges) as soon as possible. If you cancelled your order before you received the Content, we will issue the refund no later than 14 days after the day on which you told us that you want to cancel. If you have sent any physical products back to us, we will issue the refund no later than 14 days after the day we receive the products back from you or, if earlier, 14 days after the day you provide us with evidence that you have sent the products back. `,
  sameRefund: ` 10.9 We will issue your refund to the same payment method you used when you placed your order. `,
  advice: `10.10 For more detailed information on your consumer rights, visit the Citizens Advice website at  `,
  no: `or call 0808 223 1133.`,
  linkWeb: `www.citizensadvice.org.uk `,
  faulty: `11 IF THE CONTENT IS FAULTY `,
  meet: `11.1 Where the Content or deliverables do not meet the descriptions on our Website:`,
  are: ` If you are a consumer:`,
  conformity: `11.2 The Content that we provide to you must be as described, fit for purpose and of satisfactory quality. We are under a legal duty to supply material that is in conformity with our contract with you.`,
  entitled: `11.3 During the expected lifespan of the Content, you are entitled to the following:`,
  days: `11.3.1 Up to 30 days: If the Content is faulty, you can get an immediate refund.`,
  full: `11.3.2 Up to six months: If the Content cannot be repaired or replaced, then you are entitled to a full refund in most cases.`,
  length: ` 11.3.3 Up to six years: If the Content does not last a reasonable length of time, you may be entitled to some money back.`,
  promptly: `11.4 If you have returned the Content to us because it was faulty or misdescribed, we will refund the Price and the delivery costs to you on your original payment method promptly upon receiving the Content.`,
  misdescribed: `11.5 If the Content is faulty or misdescribed, please contact us as soon as reasonably possible.`,
  business: ` If you are a business customer:`,
  extent: `11.6 You must notify us in writing within 7 days of receipt of the Content and any deliverables setting out the nature and extent of the faults or defects;`,
  returning: ` 11.7 You are responsible for the cost of returning the Content to us; and `,
  part: ` 11.8 We shall, at our option, remedy the fault with the Content or refund (in whole or in part) the Price for the Content. `,
  oblig: `12 YOUR OBLIGATIONS AND RESTRICTIONS`,
  agreeThat: `12.1 You agree that: `,
  compl: `12.1.1 you will provide complete and accurate information when placing an order; `,
  ene: `12.1.2 you are responsible for making sure that the information you provide us in order to enable us to provide the Content is correct;`,
  usings: `12.1.3 you will comply with these Terms, and any other documents referred to in it, when using our Website and placing an order for Content; and`,
  termTerm: `13 TERM AND TERMINATION`,
  orders: `13.1 A contract shall start when we have provided you with written acceptance of your order and shall go on until:`,
  page: ` 12.1.4 in reading and accepting these Terms, you are aware of and understand your rights and responsibilities, and if you are not sure, you have contacted us on our Contact Page for help or more information.`,
  contract: ` 13.1.1 all Content has been delivered; in which case the contract shall expire; `,
  exercising: `13.1.2 you cancel the contract exercising your rights under clause 10 (your rights as a consumer); `,
  accordance: ` 13.1.3 the contract is terminated in accordance with clause 11 (if the content is faulty);`,
  clause: `13.1.4 we exercise our right to end the contract under clause 14 (our right to end the contract).`,
  ends: ` 14 OUR RIGHT TO END THE CONTRACT `,
  time: `14.1 We may terminate any and all contracts we have with you at any time by contacting you in writing if:`,
  serious: `14.1.1 you commit a serious breach of these Terms; `,
  illegal: ` 14.1.2 you do or take part in anything illegal when using our Website or purchasing our Content; or`,
  fail: `14.1.3 you fail to pay any amount due under a contract on the due date.`,
  consumer: `14.2 You can cancel your order under clause 10 (your rights as a consumer) or clause 11 (if the content is faulty). `,
  terminates: ` 14.3 Our right to terminate does not affect any of your rights.`,
  lib: `15 OUR LIABILITY TO YOU`,
  adequate: `15.1 We are not liable to you for any losses you incur where the delivery of the Content is delayed or cannot be delivered because you fail to make information available to us or fail to provide us with adequate instructions or information to allow us to deliver the Content.`,
  damage: `15.2 We are not liable to you for any loss or damage that was not foreseeable, any loss or damage not caused by our breach or negligence, or any business loss or damage. If we are affected by an unforeseeable event, we will promptly write to you to let you know if this means we are unable to fulfil the contract.`,
  exclude: `15.3 Nothing in these Terms excludes or limits our liability for any death or personal injury caused by our negligence, liability for fraud or fraudulent misrepresentation, or any other liability that the law does not allow us to exclude or limit.`,
  only: ` The following clause in this clause 15 only applies to you if you are a consumer.`,
  did: ` 15.4 If we breach these Terms or are negligent, we are liable to you for foreseeable loss or damage that you suffer as a result. By ‘foreseeable’ we mean that, at the time the contract was made, it was either clear that such loss or damage would occur, or you and we both knew that it might reasonably occur, as a result of something we did (or failed to do). `,
  businessClause: `The following clauses in this clause 15 only apply if you are a business customer.`,
  paid: `15.5 Subject to the below, our liability under or in connection with these Terms (regardless of whether such liability arises in tort, contract or in any other way and whether or not caused by negligence or misrepresentation) will not exceed the price for the Content paid by you. `,
  liable: ` 15.6 We will not be liable to you under or in connection with these Terms (regardless of whether such liability arises in tort, contract or in any other way and whether or not caused by negligence or misrepresentation) for:`,
  indirect: `15.6.1 consequential, indirect or special losses; or `,
  whether: `15.6.2 any of the following (whether direct or indirect): `,
  profit: `15.6.3 loss of profit;`,
  corruption: `15.6.4 loss or corruption of data;`,
  systems: `15.6.5 loss or corruption of Content or systems;`,
  equipment: `15.6.6 loss or damage to equipment;`,
  loss: ` 15.6.7 loss of use;`,
  opport: ` 15.6.8 loss of opportunity; `,
  savings: `15.6.9 loss of savings, discount or rebate (whether actual or anticipated); or`,
  goodwill: ` 15.6.10 harm to reputation or loss of goodwill.`,
  provides: `15.7 Where we have failed to provide you with the Content or some or all of the materials described on the Website as part of the Content purchased, we shall only be liable up to the limit of the price paid for the Content.`,
  general: `16 GENERAL `,
  notify: `16.1 You are not allowed to transfer your rights or obligations under these Terms to anyone without our prior written consent. We may transfer our rights and obligations under these Terms to another business without your consent, but we will notify you of the transfer and make sure that your rights are not adversely affected as a result.`,
  these: ` 16.2 If any provision of these Terms (or part of any provision) is or becomes illegal, invalid or unenforceable, the legality, validity and enforceability of any other provision of these Terms will not be affected.`,
  situation: `16.3 If you breach these Terms and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these Terms. `,
  parties: `16.4 Under these Terms, notices must be in writing and sent to the other party's address or email address, as set out in the order confirmation. Letters sent in the United Kingdom will be deemed delivered in 3 business days (excluding English Bank Holidays), after sending. Emails will be deemed delivered the same day (or the next business day, if sent on a non-business day or after 5pm on any business day at the recipient's location). `,
  previously: `16.5 Each contract, these Terms and the terms of each accepted order represent the entire agreement between us and replace any terms and conditions of purchase or supply that you have been provided with previously. `,
  claims: ` 16.6 Each contract, these Terms and the terms of each accepted order and any dispute or claim arising out of them will be governed by, and interpreted in accordance with, the laws of England and Wales and the parties agree that the courts of England and Wales will have exclusive jurisdiction to settle such disputes or claims.`,
  youConsume: ` If you are a consumer `,
  united: `and live in either Northern Ireland or Scotland, you can choose to bring a claim in England and Wales or in the courts of another part of the United Kingdom in which you live.`,
};
export const ends = {
  end: "1 INTRODUCTION ",
  under: `1.1 We are`,
  avtavLtd: ` AVTAV LTD`,
  englandRegis: ` registered in England and Wales under company number 12704296 with our office at 625 - 635 Sovereign Court Suite A, Ground Floor, Sipson Road, West Drayton, United Kingdom, , West Drayton, UB7 0JE`,
  weus: ` (we, us, our).`,
  accessEnd: `1.2 This End User Licence Agreement`,
  eulas: ` (EULA)`,
  constituts: ` constitutes a legal agreement between us and you (or`,
  yoursUse: ` your`,
  softUse: `) for your use and access of the Software.`,
  agree: ` 1.3 By using the Software, you agree to be bound by the terms of this EULA.`,
  below: `1.4 If you have any questions about this EULA, please contact us using the details below:`,
  mails: ` Email:`,
  ukmail: ` training@avtav.co.uk`,
  phn: ` Phone:`,
  nom: ` 02039844195 `,
  licence: `2 THIS LICENCE `,

  accordance: ` 2.1 Where you use the Software in accordance with this EULA: `,
  personal: `2.1.1 we grant to you a non-exclusive, non-transferable, revocable licence to use the Software and to access the content provided for, if you are a business user, internal business purposes and, if you are a consumer user, your personal purposes only; and`,
  before: ` 2.1.2 we will provide the Software to meet the description, in all material respects, of the Software as set out on our website at https://avtav.co.uk/training/. We may update or require you to update the Software, provided that the Software shall meet the description, in all material respects, that we provided to you before you bought it.`,
  internal: ` 2.2 As long as you do not violate any restrictions set out in this EULA, you may make a limited number of copies of the Software, strictly as is required for the purpose of providing a back-up in accordance with your own internal operating procedures.`,
  order: ` 2.3 Each user must create a user account with us in order to have access to the Software.`,
  business: `2.4 If you are a business user, your business shall be limited to the number of users paid for in accordance with our charges. We will confirm the number of users and charges during the registration process.`,
  support: ` 2.5 This EULA does not entitle you to other services such as implementation support or training in how to use our Software.`,
  resp: ` 3 YOUR RESPONSIBILITIES`,
  keeping: `3.1 You are responsible for keeping any user account details you create safe.`,
  technologyComply: ` 3.2 You agree to comply with all applicable laws relevant to use of the Software, including all applicable technology control or export laws and regulations.`,
  download: `3.3 Where we permit multiple users on one single sign on, or access for multiple users from a single source of download, you are responsible for:`,
  respect: `3.3.1 the acts and omissions of your directors, employees, contractors, sub-contractors or agents (Personnel) with respect to their use of the Software and you remain liable for the acts and omissions of your Personnel in respect of their use of the Software; and`,
  copy: ` 3.3.2 ensuring that your Personnel are provided with a copy of this EULA and comply with its terms.`,
  found: `3.4 You are responsible for making sure all your payments for the Software are up to date and in accordance with our normal payment terms which can be found at https://avtav.co.uk/training.`,
  immediately: ` 3.5 You must notify us immediately in writing if:`,
  access: `3.5.1 you know of or suspect there has been any unauthorised access of or use of the Software; and/or`,
  denial: ` 3.5.2 you are aware of or suspect that your own network and systems have been compromised (including any attack on your systems such as a denial of service attack or ransomware).`,
  provide: ` 3.6 Where we have been notified of any of the events above, we shall be entitled to suspend access to the Software to you, without any liability to you, until we are satisfied that we are able to provide the Software to you without the risk that: `,
  unauthorised: ` 3.6.1 the Software may be accessed by an unauthorised person; or `,
  third: ` 3.6.2 that our own network and systems, or that of a third-party, could be compromised. `,
  ensure: ` 3.7 Where you access our Software through your browser, it is your responsibility to ensure that your browser is up to date.`,
  interruptions: `3.8 You will require an internet connection which you must procure at your own expense, to use and access the Software. We will not be responsible for any interruption to your use of the Software due to interruptions to your internet connection. `,
  restriction: ` 4 RESTRICTIONS`,
  not: `4.1 You must not:`,
  distribute: `4.1.1 sell, distribute, reproduce, transfer, publicly display, translate, modify, adapt, create derivative works from, deconstruct, reverse engineer, decompile or disassemble, rent, lease, loan, sub-license or otherwise deal in copies or reproductions of the Software to other parties in any way except as expressly permitted by this EULA; `,
  contained: `4.1.2 remove, delete, obscure, disable, modify, add to, tamper with, or circumvent any program code or data, copyright, trademark, or other proprietary notices, labels or copy protection Software contained on or within the Software;`,
  immoral: ` 4.1.3 use the Software for any illegal or immoral purposes.`,
  agreed: ` 4.2 You agree not to perform any security testing on the Software unless agreed with us in writing.`,
  ship: ` 5 OWNERSHIP`,
  belong: ` 5.1 You agree that the Software and any intellectual property rights contained in the Software (including any content that was provided with the Software and any accompanying literature) anywhere in the world belong to us or anyone we hold a licence with.`,
  expressly: `5.2 Except as expressly set out in this EULA, you gain no rights in or in relation to the Software.`,
  source: ` 5.3 You do not have permission and are not permitted to access the Software in source code form.`,
  fault: `6 IF THE SOFTWARE IS FAULTY`,
  does: ` 6.1 Where the Software does not meet the descriptions under clause 2.1.2:`,
  consumerU: ` If you are a consumer user: `,
  conformity: ` 6.2 The Software that we provide to you must be as described, fit for purpose and of satisfactory quality. We are under a legal duty to supply material that is in conformity with our contract with you. `,
  lifespan: ` 6.3 During the expected lifespan of the Software, you are entitled to the following:`,
  entitled: `6.3.1 Up to 30 days: If the Software is faulty, you may be entitled to a refund.`,
  then: ` 6.3.2 Up to 6 months: If the Software cannot be repaired or replaced, then you are entitled to a full refund in most cases.`,
  length: ` 6.3.3 Up to 6 years: If the Software does not last a reasonable length of time, you may be entitled to some money back.`,
  promptly: `6.4 If you have returned the Software to us because it was faulty or misdescribed, we will refund the price and the delivery costs to you on your original payment method promptly upon receiving the Software.`,
  soon: `6.5 If the Software is faulty or misdescribed, please contact us as soon as reasonably possible. `,
  businessU: `If you are a business user:`,
  naturenotify: ` 6.6 You must notify us in writing within 14 days of receipt of the Software and any deliverables setting out the nature and extent of the faults or defects;`,
  returning: `6.7 You are responsible for the cost of returning the Software to us; and`,
  whole: `6.8 We shall, at our option, remedy the fault with the Software or refund (in whole or in part) the Price for the Software.`,
  liba: `7 LIMIT OF OUR LIABILITY`,
  must: ` 7.1 You agree that the Software has not been developed to meet your particular requirements, including any legal or regulatory requirements you may have. You must ensure that the Software meets your requirements.`,
  warrant: `7.2 We provide the Software on an “as is” basis only, and do not warrant that:`,
  uninterrupted: ` 7.2.1 your use of the Software will be uninterrupted or error-free; or `,
  viruses: ` 7.2.2 the Software will be free from vulnerabilities or viruses. `,
  businessDo: `7.3 We do not exclude or limit any liability for (i) death or personal injury arising from our negligence; (ii) fraud or fraudulent misrepresentation; or (iii) any other liability to the extent it cannot be excluded or limited by law.`,
  foll: ` The following clauses in this clause 7 only apply if you are a business customer.`,
  provision: `7.4 We will not be liable to you, under or in connection with this EULA or the provision of the Software, for:`,
  inter: `7.4.1 business interruption; `,
  saving: ` 7.4.2 loss of anticipated savings;`,
  goodwill: `7.4.3 loss of business opportunity, goodwill or reputation;`,
  data: ` 7.4.4 loss or corruption of data or information;`,
  revenue: ` 7.4.5 loss of revenue or profit;`,
  consequential: ` 7.4.6 any indirect or consequential loss or damage; and/or`,
  unforeseeable: ` 7.4.7 any other unforeseeable losses arising out of unusual or special circumstances.`,
  clause: ` 7.5 Other than the losses set out in clause`,
  liable: ` 7.6 (for which we are not liable), our maximum total liability under or in connection with this EULA will, where it is permitted by law, be limited to £. `,
  apply: `The following clauses in this clause 7 only apply to you if you are a consumer. `,
  negligent: ` 7.7 If we breach these terms or are negligent, we are liable to you for foreseeable loss or damage that you suffer as a result. By ‘foreseeable’ we mean that, at the time the contract was made, it was either clear that such loss or damage would occur, or you and we both knew that it might reasonably occur, as a result of something we did (or failed to do). `,
  losses: `7.8 We are not liable to you for any losses you incur where the Software is delayed or cannot be provided to you because you fail to comply with the terms of this EULA. `,
  strictly: `7.9 The Software provided under this EULA is strictly for your personal use. If you use the Software for any commercial, business or resale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, loss of business opportunity or other business-related losses.`,
  advice: ` 7.10 Nothing in this EULA affects your consumer rights. For more information on your consumer rights, visit the Citizens Advice website at www.citizensadvice.org.uk/consumer or call 0808 223 1133.`,
  termination: ` 8 TERMINATION AND SUSPENSION`,
  commit: ` 8.1 If you commit a serious or persistent breach of this EULA, we may terminate this EULA immediately on written notice to you, or suspend your access to the Software. If the breach is capable of being addressed and put right by you, you will have 14 days to address the breach and if you fail to adequately address the breach in this time, then this EULA will be terminated or we may suspend your access to the Software.`,
  also: ` 8.2 We may also terminate this EULA immediately on written notice to you in the event that you (or your business) become insolvent, fail to pay your debts, become the subject of any voluntary or involuntary proceeding in bankruptcy, liquidation, dissolution, receivership, attachment or composition for the benefit of creditors or in the event that you (or your business) undergoes a change of control (within the meaning of section 1124 of the Corporation Tax Act 2010).`,
  thisThat: ` 8.3 Where this EULA has been terminated for any reason:`,
  for: ` 8.3.1 any rights for you to use the Software, the content contained within it and any associated literature will end immediately;`,
  stops: ` 8.3.2 you must stop doing anything that was only permitted under this EULA; and`,
  software: ` 8.3.3 you must immediately and permanently remove the Software from all computers, mobile devices or any other computer equipment in your control. On our request, you must confirm in writing that you have done so. `,
  gen: ` 9 GENERAL`,
  no9: ` 9.1 `,
  eulaInter: ` Interpretation of this EULA:`,
  thisElua: ` In this EULA:`,
  firm: ` 9.1.1 a person means an individual, a firm or a company (whether or not having a separate legal identity from its members or owners);`,
  headings: ` 9.1.2 clause, schedule and paragraph headings shall not affect the interpretation of this EULA;`,
  statutes: ` 9.1.3 references to statutes, regulations or other legislation or enactments referenced herein shall be deemed to be references to that enactment as amended, supplemented, re-enacted or replaced from time to time;`,
  similar: ` 9.1.4 the words include, including and similar words or expressions will not limit the meaning of the words that come before them;`,
  but: ` 9.1.5 reference to writing or written includes email but not any other form of electronic communication; and `,

  together: `9.1.6 each of the parties shall be referred to as a party or together, the parties.`,
  no92: `9.2 `,
  thirdP: `Third party rights:`,
  act: ` For the purposes of the Contracts (Rights of Third Parties) Act 1999, this EULA is not intended to and does not give any person who is not a party to it any right to enforce any of its provisions. However, this does not affect any rights or remedy of such a person that exists or is available apart from that Act.`,
  no93: ` 9.3 `,
  costs: `Costs:`,
  legals: ` Each party is responsible for its legal and other costs in relation to the preparation and performance of this EULA.`,
  no94: ` 9.4 `,
  survival: `Survival of terms:`,
  terminations: ` The parties intend the following terms to survive termination: clauses 4, 5, 7, 8, and 9 shall survive termination of this EULA.`,
  no95: ` 9.5 `,
  relationship: `Relationship of the parties:`,
  dependent: ` The parties are independent businesses and not partners, principal and agent, or employer and employee, or in any other relationship of trust to each other.`,
  no96: ` 9.6 `,
  otherDeal: `Assignment and other dealings:`,
  subcontract: `No party may assign, subcontract or encumber any right or obligation under this EULA, in whole or in part, without the other party’s prior written consent or except as expressly permitted in this EULA. `,
  no97: ` 9.7 `,
  entireAgree: `Entire agreement:`,
  acknowledges: ` This EULA, and any document referred to in it, contains the whole EULA between the parties relating to its subject matter and supersedes any prior EULAs, representations or understandings between them unless expressly referred to in this EULA. Each party acknowledges that it has not relied on, and will have no remedy in respect of, any representation (whether innocent or negligent) made but not covered in this EULA. Nothing in this clause limits or excludes any liability for fraud or fraudulent misrepresentation.`,
  no98: ` 9.8 `,
  variations: ` Variation:`,
  giving: ` We may amend this EULA at any time by giving you written notice. Your continued use of the Software and any associated content or literature following receipt of the notice will be taken as your acceptance of the new terms of this EULA. If you do not accept this EULA or any amendments to it, you must immediately stop using the Software and follow the procedure set out at clause 8.3.`,
  no99: ` 9.9 `,
  serve: ` Severability:`,
  clauseServe: ` If any clause in this EULA (or part of a clause) is or becomes illegal, invalid or unenforceable under applicable law, but would be legal, valid and enforceable if the clause or some part of it was deleted or modified (or the duration of the relevant clause reduced), the relevant clause (or part of it) will apply with such deletion or modification as may be required to make it legal, valid and enforceable, and the parties will promptly and in good faith seek to negotiate a replacement provision consistent with the original intent of this EULA as soon as possible.`,
  no90: ` 9.10 `,
  waiver: `Waiver:`,
  omission: ` No delay, act or omission by either party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.`,
  no11: ` 9.11 `,
  nots: ` Notices:`,
  notsParty: ` Notices under this EULA must be in writing and sent to the other party's address or email address. For the purpose of this clause, we will use the details where you provided to us when you downloaded the Software. Letters sent in the United Kingdom will be deemed delivered 3 business days (excluding English Bank Holidays), after sending. Emails will be deemed delivered the same day (or the next business day, if sent on a non-business day or after 5pm on any business day at the recipient's location).`,
  no12: ` 9.12 `,
  jurisdiction: `Governing law and jurisdiction:`,
  england: ` This EULA is governed by the law of England and Wales. All disputes under this EULA will be subject to the exclusive jurisdiction of the courts of England and Wales.`,
  ireland: `If you are a consumer`,
  northern: ` and live in either Northern Ireland or Scotland, you can choose to bring a claim in England and Wales or in the courts of another part of the United Kingdom in which you live.`,
  mainHead: `End User Licence Agreement (EULA)`,
  imp: `IMPORTANT NOTES`,
  coversLms: ` - This EULA covers use and access of LMS , referred to in this document as the Software. This EULA also covers the content of the Software and any associated literature.`,
  carefully: `- Please ensure that you read the terms of this EULA carefully before purchasing, using and accessing the Software.`,
  subjects: ` Your use, and access of, the Software is subject to the terms set out in this EULA.`,
  obligations: ` - There are important terms in this EULA such as terms which limit and exclude our liability to you and obligations you have when using the Software. It is therefore important that you read them carefully.`,
  redistribution: ` - Any use, reproduction, or redistribution of the Software that is not in accordance with the terms of this EULA is expressly prohibited.`,
  using: ` - Some of these terms only apply if you are using Software as a`,
  com: ` consumer`,
  personals: `. This means that you use the Software for your personal use (and will not use our Software for commercial, business or resale purposes). Terms that only apply to consumers will be marked clearly.`,
  collect: ` - Personal data that we collect in the course of your use, and access, of the Software shall be treated in accordance with our privacy policy which is available at https://avtav.co.uk/training.`,
};
export const confirm = {
  cancel: "Order cancellation form",
  to: `To:`,
  avtav: ` Avtav Ltd,`,
  suite: ` Suite A, Ground Floor 625-635`,
  sov: ` Sovereign Court`,
  road: ` Sipson Road, West Drayton UB7 0JE`,
  order: ` Order Number / ID`,
  first: "  First Name ",
  last: "  Last Name ",
  mail: "Email",
  mobile: "Mobile",
  address1: " Address Line 1 ",
  address2: " Address Line 1 ",
  town: "Town/City",
  post: "Postcode",
  submit: "Submit",
  wish: `Do you wish to postpone or cancel your order`,
  box: ` I wish to postpone order (prompts a box with postpone from /// - to ////)`,
  cancelOrder: `I wish to  cancel my order`,
  postponement: `Reason for cancelation / postponement (not compulsory)`,
  agreed: ` I have read and agreed to the `,
  sale: ` terms and conditions of the sale of digital content`,
};
