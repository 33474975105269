import { createSlice } from "@reduxjs/toolkit";
const OrderCancelFormSlice = createSlice({
  name: "OrderCancelForm",
  initialState: {
    OrderCancelForm: [],
    loading: false,
    error: null,
  },
  reducers: {
    OrderCancelFormStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    OrderCancelFormSuccess: (state, action) => {
      state.loading = false;
      state.OrderCancelForm = action.payload;
    },
    OrderCancelFormFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { OrderCancelFormStart, OrderCancelFormSuccess, OrderCancelFormFailure } =
OrderCancelFormSlice.actions;
export default OrderCancelFormSlice.reducer;
