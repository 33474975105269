import * as Styled from "./Style";
import React from "react";
import StepperLabel from "../../Atoms/StepperLabel/StepperLabel";
import { constant } from "../../../constants/Constants";
import { SignData } from "./Data";

const AboutYou = (props) => {
  const { handleChange, signUpData, errors } = props;
  return (
    <>
      <Styled.LabelCount>
        <StepperLabel title={constant.AboutYou} />
      </Styled.LabelCount>
      <Styled.Box>
        <Styled.Details>
          <div className="FirstLast">
            <div>
              <Styled.Label>
                {SignData.first}
                <span className="star">*</span>
              </Styled.Label>
              <Styled.Input
                type="text"
                name="firstName"
                value={signUpData?.firstName}
                onChange={handleChange}
                required
              />
              {errors?.firstName && (
                <Styled.Error>{errors?.firstName}</Styled.Error>
              )}
            </div>
            <div>
              <Styled.Label>
                {SignData.last}
                <span className="star">*</span>
              </Styled.Label>

              <Styled.Input
                type="text"
                name="lastName"
                value={signUpData?.lastName}
                onChange={handleChange}
                required
              />
              {errors?.lastName && (
                <Styled.Error>{errors?.lastName}</Styled.Error>
              )}
            </div>
          </div>
          <div className="FirstLast">
            <div>
              <Styled.Label>
                {SignData.email}
                <span className="star">*</span>
              </Styled.Label>

              <Styled.Input
                type="email"
                name="email"
                value={signUpData?.email}
                onChange={handleChange}
                className="email-input"
                required
              />
              {errors?.email && <Styled.Error>{errors?.email}</Styled.Error>}
            </div>
            <div>
              <Styled.Label>
                {SignData.mobile} <span className="star">*</span>
              </Styled.Label>

              <Styled.Input
                type="tel"
                name="mobile"
                value={signUpData?.mobile}
                onChange={handleChange}
                required
              />
              {errors?.mobile && <Styled.Error>{errors?.mobile}</Styled.Error>}
            </div>
          </div>
        </Styled.Details>
      </Styled.Box>
    </>
  );
};

export default AboutYou;
