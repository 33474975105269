import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useState } from "react";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { ModalText } from "../businessConstant";
import { DeclarationModalPreview } from "./DeclarationModal";
import { buttonName } from "./LicenseData";
import { useLicense } from "../../../hooks/LicenseHook";
import { FaCheck } from "react-icons/fa6";

export const AddtraineeForm = ({
  setIssueLicenseOpen = () => null,
  errors,
  formData,
  handleChange,
  handleSubmit,
  index,
  isAddButtonClicked,
  handleDelete,
  fetchLicensesList,
  declarationStatus,
}) => {
  const { licenseTableData } = useLicense();
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Styled.LabelBox>
        <Styled.LeftContainer>
          <Styled.Labelcontainer>
            <Styled.InputField>
              <label className="labelStyled">
                {ModalText.TraineeFirstName}
              </label>
              <input
                className="inputStyled"
                type="text"
                placeholder="Enter FirstName"
                name="firstName"
                value={formData?.firstName}
                onChange={handleChange(index)}
                required
              />

              {errors[index]?.firstName && (
                <Styled.Error>{errors[index]?.firstName}</Styled.Error>
              )}
            </Styled.InputField>
            <Styled.InputField>
              <label className="labelStyled">{ModalText.TraineeSurname}</label>
              <input
                className="inputStyled"
                type="text"
                placeholder="Enter Surname"
                name="lastName"
                value={formData?.lastName}
                onChange={handleChange(index)}
                required
              />
              {errors[index]?.lastName && (
                <Styled.Error>{errors[index]?.lastName}</Styled.Error>
              )}
            </Styled.InputField>
          </Styled.Labelcontainer>
          <Styled.Labelcontainer>
            <Styled.InputField>
              <label className="labelStyled">{ModalText?.TraineeEmail} </label>
              <input
                className="emailInput"
                type="text"
                placeholder="Enter Email"
                name="email"
                value={formData?.email}
                onChange={handleChange(index)}
                required
              />
              {errors[index]?.email && (
                <Styled.Error>{errors[index]?.email}</Styled.Error>
              )}
            </Styled.InputField>
            <Styled.InputField>
              <label className="labelStyled">{ModalText?.Trainee}</label>
              <input
                className="inputStyled"
                type="text"
                placeholder="Enter Contact Number"
                name="contactNumber"
                value={formData?.contactNumber}
                onChange={handleChange(index)}
                required
              />
              {errors[index]?.contactNumber && (
                <Styled.Error>{errors[index]?.contactNumber}</Styled.Error>
              )}
            </Styled.InputField>
          </Styled.Labelcontainer>
        </Styled.LeftContainer>
        <Styled.RightContainer>
          <Styled.InputField>
            <Styled.DropdownContainer>
              <Styled.DropdownContent>
                <Styled.DropData>
                  <select
                    name="courseId"
                    id="courseId"
                    value={formData?.courseId}
                    onChange={handleChange(index)}
                  >
                    <option value="" disabled selected>
                      {ModalText.Selectcourse}
                    </option>
                    {licenseTableData?.map((course) => (
                      <option key={course?.courseId} value={course?.courseId}>
                        {course?.course?.title}
                      </option>
                    ))}
                  </select>
                </Styled.DropData>
              </Styled.DropdownContent>
            </Styled.DropdownContainer>
            {errors[index]?.courseId && (
              <Styled.Error>{errors[index]?.courseId}</Styled.Error>
            )}
          </Styled.InputField>
          <Styled.InputField>
            <label className="labelStyled">{ModalText?.TraineeRole}</label>

            <input
              className="inputStyled"
              type="text"
              placeholder="Enter Role"
              name="role"
              value={formData?.role}
              onChange={handleChange(index)}
              required
            />
            {errors[index]?.role && (
              <Styled.Error>{errors[index]?.role}</Styled.Error>
            )}
          </Styled.InputField>
        </Styled.RightContainer>
      </Styled.LabelBox>
      {formData?.courseType === "AVSEC" ? (
        <>
          <Styled.Checkbox>
            <Styled.CheckboxContainer>
              <div className="checkboxcontainer">
                <input
                  type="checkbox"
                  className="checkboxInput2"
                  name="is5YearTrainee"
                  checked={formData?.is5YearTrainee}
                  onChange={handleChange(index)}
                  required
                />
                <span className="textConfirm">{ModalText?.Confirm}</span>
              </div>
              <div className="checkboxcontainer2">
                <input
                  type="checkbox"
                  className="checkboxInput2"
                  name="isAirsideJob"
                  checked={formData?.isAirsideJob}
                  onChange={handleChange(index)}
                  required
                />
                <span className="textSize">
                  {ModalText.Has}
                  <span className="airsideText">{ModalText?.AIRSIDE}</span>
                  {ModalText.Job}
                  <span className="airsideText">{ModalText?.Security}</span>
                  {ModalText.is}
                </span>
              </div>
            </Styled.CheckboxContainer>
          </Styled.Checkbox>

          <Styled.ButtonTextContainer
            isSpaceBetween={true}
            isAddButtonClicked={isAddButtonClicked}
          >
            <Styled.TextContainer>
              <Styled.PreviewText
                type="view"
                onClick={() => setOpenModal(true)}
              >
                {"Preview Declaration & Sign"}
              </Styled.PreviewText>
              <DeclarationModalPreview
                openModal={openModal}
                setOpenModal={setOpenModal}
                handleClose={handleClose}
                formData={formData}
                isTraineeData={false}
                isSoleTrader={false}
                singleDeclaration={false}
                item={licenseTableData.length > 0 && licenseTableData[0]}
                index={index}
                fetchLicensesList={fetchLicensesList}
                declarationStatus={declarationStatus}
              />
              {formData?.is5YearTrainee && formData?.isAirsideJob ? (
                <Styled.SignedText className="text">
                  <span className="icon">
                    <FaCheck />
                  </span>{" "}
                  <span className="text">{ModalText.Signed}</span>
                </Styled.SignedText>
              ) : (
                <Styled.Text>
                  <span className="text">{ModalText.Not}</span>
                </Styled.Text>
              )}
            </Styled.TextContainer>
            <Styled.DeleteIcon>
              <RiDeleteBin5Fill
                className="deleteicon"
                onClick={() => handleDelete(index)}
              />
              {isAddButtonClicked && (
                <Button
                  className="inviteButton"
                  title={buttonName?.Invite}
                  icon={<RxCross2 className="icon" />}
                  onClick={handleSubmit}
                />
              )}
            </Styled.DeleteIcon>
          </Styled.ButtonTextContainer>
        </>
      ) : (
        <>
          <Styled.ButtonTextContainer
            isSpaceBetween={false}
            isAddButtonClicked={isAddButtonClicked}
          >
            <Styled.DeleteIcon>
              <RiDeleteBin5Fill
                className="deleteicon"
                onClick={() => handleDelete(index)}
              />
              {isAddButtonClicked && (
                <Button
                  className="inviteButton"
                  title={buttonName?.Invite}
                  icon={<RxCross2 className="icon" />}
                  onClick={handleSubmit}
                />
              )}
            </Styled.DeleteIcon>
          </Styled.ButtonTextContainer>
        </>
      )}

      <Styled.DeclarationLine hr />
    </div>
  );
};
