import styled from "styled-components";
import { Modal } from "antd";
import { color } from "../../../theme/Color";
import CourseScreenCompletesTraining from "../../../assets/image/CourseScreenCompletesTraining.jpg";

export const MainContainer = styled.div`
  height: 560px;
  .courseC {
    margin-left: 17px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .certificate {
    width: 667px;
    height: 53px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._f1fbff};
    color: ${color._177a9c};
    display: flex;
    align-items: center;
  }
`;
export const Description = styled.div`
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const Paginate = styled.div`
  height: 200px;
  .ant-pagination {
    display: flex;
    gap: 11px;
    justify-content: center;
  }
  .ant-pagination .ant-pagination-next {
    color: ${color._082429};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-left: 15px;
  }

  .ant-pagination .ant-pagination-prev {
    color: ${color._082429};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-right: 15px;
  }

  .ant-pagination .ant-pagination-item {
    color: ${color._ffffff};
    padding-top: 8px;
    width: 33px;
    height: 33px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._D9D9D9};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  .ant-pagination .ant-pagination-item-active {
    color: ${color._ffffff};
    padding-top: 8px;
    width: 33px;
    height: 33px;
    flex-shrink: 0;
    border-radius: 2px;
    border: none;
    background: ${color._5193aa};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
`;
export const ModalBox = styled.div`
  flex-shrink: 0;
  border-radius: 2px;
  background: ${color._0f2a3c};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
`;

export const Line = styled.div`
  height: 0.4px;
  background: ${color._DADADA};
`;

export const CourseName = styled.div`
  padding: 25px 43px;
  .english {
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${color._ffffff};
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .gsat {
    color: ${color._ffffff};
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .head {
    color: ${color._5193aa};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .general {
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${color._ffffff};
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Receipt = styled.div`
  padding: 25px 43px;
  display: flex;
  justify-content: space-between;

  .recName {
    color: ${color._5193aa};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .staceyM {
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Preview = styled.div`
  padding: 25px 43px;
  display: flex;
  justify-content: space-between;
  .certify {
    color: ${color._ffbe32};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-items: center;
    display: flex;
    gap: 0.4rem;
  }
  .down {
    height: 24px;
    width: 24px;
    color: ${color._5193aa};
  }
  .download {
    color: ${color._ffffff};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Training = styled.div`
  margin-top: 49px;
  .expire {
    color: ${color._0f2a3c};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .valid {
    color: ${color._0f2a3c};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 37px;
  .non-active {
    border-radius: 2px;
    background: ${color._f1fbff};
    border: none;
    color: ${color._177a9c};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    width: 155px;
    height: 35px;
  }
  .active {
    background: ${color._177a9c};
    color: ${color._ffffff};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    border: none;
    border-radius: 2px;
    width: 155px;
    height: 35px;
  }
`;
export const SearchContainer = styled.div`
  gap: 5px;
  .searchIcon {
    color: ${color._16384E};
    margin-top: 3px;
    margin-left: 6px;
  }
  display: flex;
  align-items: center;
  width: 210px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
`;
export const Input = styled.input`
  outline: none;
  border: none;
  flex-shrink: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${color._000000};
  &::placeholder {
    color: ${color._000000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Searchbar = styled.div`
  .ant-input-affix-wrapper .ant-input-prefix {
    width: 12px;
    margin-top: 1px;
  }
  .searchicon {
    margin-top: 5px;
  }
  .ant-input-affix-wrapper-lg {
    padding: 0px;
  }
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 234px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  .search {
    border: none;
    padding: 10px;
    outline: none;
  }
  .search {
    gap: 9px;
  }

  ::placeholder {
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Dropdown = styled.div`
  padding: 10px 0px;
  display: flex;
  width: 186px;
  gap: 5px;
  height: 36px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};

  .filter {
    font-size: 12px;
  }
`;
export const DropData = styled.div`
  .gsat {
    border: none;
    padding-right: 92px;
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Spreadsheet = styled.div`
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 32px 0px 11px;
  display: flex;
  align-items: center;
  width: 210px;
  height: 36px;
  gap: 10px;
  color: ${color._000000};
  cursor: pointer;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const Filter = styled.div`
  padding: 10px 0px;
  display: flex;
  width: 210px;
  gap: 5px;
  height: 36px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 8px;

  .filter {
    font-size: 12px;
  }
`;
export const NewestFirst = styled.div`
  .newest {
    border: none;
    padding-right: 82px;
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    outline: none;
  }
`;
export const DotIcon = styled.div`
  width: 38px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  align-items: center;
  display: flex;
  justify-content: center;
`;
export const TableHeader = styled.div`
  padding: 13px 20px 13px 17px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  padding-bottom: 14px;
  .tablecontainer {
    display: flex;
    gap: 12px;
  }
`;
export const Pipe = styled.div`
  display: flex;
  background-color: ${color._6091a8};
  color: ${color._6091a8};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 2px;
  height: 20px;
  margin-top: 4px;
`;
export const CompletedCourses = styled.div`
  display: flex;
  gap: 7px;
  padding-bottom: 18px;
  .courses {
    color: ${color._0f2a3c};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .number {
    color: ${color._6091a8};
    font-family: Open Sans;
    margin-top: 1px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const BelowText = styled.div`
  padding-bottom: 20px;

  color: ${color._68828e};

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const BelowText1 = styled.div`
  padding-bottom: 4px;
  color: ${color._68828e};

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const MainContainer2 = styled.div`
  margin-top: 26px;
  border-radius: 5px;
  border: 0.7px solid ${color._ededde};
  background: ${color._ffffff};
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
`;
export const AlertIcon = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: ${color._6d7d8b};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 2px;
  background: ${color._f1fbff};
  width: 456px;
  height: 35px;
  padding: 0px 7px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ModalTitle = styled.div`
  color: ${color._16384e};
  font-family: Raleway;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 25px;
`;
export const Icon = styled.div`
  width: 38px;
  height: 38px;
  background: rgba(239, 243, 249, 0.38);
  border-radius: 28px;
  cursor: pointer;
  .closeIcon {
    height: 24px;
    width: 24px;
    color: rgba(109, 125, 139, 1);
    margin-top: 7px;
    margin-left: 7px;
  }
`;
export const ModalContainer = styled(Modal)`
  & .ant-modal-content {
    padding: 25px 40px 40px 40px;
  }
  &.ant-modal {
    max-width: calc(100% - 32px);
  }
`;
export const ModalBoxCertificate = styled.div`
  height: 172px;
  flex-shrink: 0;
  border-radius: 2px;
  background: ${color._0f2a3c};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  .business {
    width: 32.998px;
    height: 33.854px;
  }
  .logo {
    text-align: center;
    padding-top: 23px;
  }
  .contact {
    color: ${color._5193aa};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 12px;
  }
  .employee {
    padding-top: 5px;
    color: ${color._ffffff};
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const CertificateMainContainer = styled.div`
  background-image: url(${CourseScreenCompletesTraining});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  text-align: center;
  .bgImage {
    width: 100%;
    height: 100%;
  }
  .imageContainer {
    position: relative;
  }
`;
export const ContentBox = styled.div`
  width: 100%;
  padding-bottom: 30px;
  padding-top: 17%;
  z-index: 2;
  color: #ffffff;
  top: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .firstHeading {
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 5px;
  }
  .secText {
    font-size: 20px;
    font-weight: 600;
  }
  .mainText {
    font-size: 22px;
    font-weight: 900;
    margin: 12px 0px;
  }
  .commonText {
    font-size: 16px;
  }
  .boldText {
    font-size: 20px;
    font-weight: 600;
  }
  .para {
    margin: 0px;
  }
  .modulesText {
    font-size: 14px;
  }
  .number {
    font-weight: 700;
  }
  .name {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    font-weight: 700;
    margin: 22px 0px;
  }
  .lastBox {
    font-size: 12px;
    font-weight: 600;
  }
  .signBox {
    margin: 10px 0px;
  }
`;
export const DateContainer = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0px;
`;
