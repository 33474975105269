import HttpApis from "../services/HttpApis";
import { message } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  updatePasswordFailure,
  updatePasswordSuccess,
} from "../redux/slices/authSlice";

export const usePasswordHook = () => {
  const [password, setPassword] = useState({
    oldPassword: "",
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState();

  const dispatch = useDispatch();
  const passwordData = async (passwordDetail, e) => {
    const newErrors = {};
    if (
      password?.oldPassword?.length === 0 &&
      password?.password?.length === 0 &&
      password?.confirm_password?.length === 0
    ) {
      newErrors.all = "Please fill out the required details";
      message.error("Please fill out the required details");
    } else {
      if (
        !password?.oldPassword?.trim() ||
        !/^\S*$/.test(password?.oldPassword)
      ) {
        newErrors.oldPassword = "Old Password is required";
      }
      if (!password?.password?.trim() || !/^\S*$/.test(password?.password)) {
        newErrors.password = "Password is required";
      }
      if (
        !password?.confirm_password?.trim() ||
        !/^\S*$/.test(password?.confirm_password)
      ) {
        newErrors.confirm_password = "Confirm Password is required";
      }
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        const res = await HttpApis.password(passwordDetail);
        setPassword({
          oldPassword: "",
          password: "",
          confirm_password: "",
        });
        setErrors([]);
        if (res && res?.data?.status) {
          message.success(res?.data?.message);
          dispatch(updatePasswordSuccess(res?.data));
        }
        return res;
      } catch (error) {
        dispatch(updatePasswordFailure(error.message));
      }
    }
  };
  return { passwordData, password, setPassword, errors };
};
