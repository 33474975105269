import paymenttick from "../.././../assets/Svg/paymenttick.svg";

export const paymentTick = [
  {
    id: 0,
    img: paymenttick,
  },
  {
    id: 1,
    img: paymenttick,
  },
  {
    id: 2,
    img: paymenttick,
  },
  {
    id: 3,
    img: paymenttick,
  },
  {
    id: 4,
    img: paymenttick,
  },
];
