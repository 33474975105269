export const course = {
  needs: `Sharing information about your support needs will help us to work with you to make your training as accessible as possible`,
  pronoun: `1. Preferred pronoun`,
  affect: `2. Do you consider yourself to have a disability/medical condition/learning support need that will affect your training?`,
  choices: `2a. From the choices below please choose all that apply to you:`,
  disability: `How might your disability/specific learning need/mental health condition impact on your training?`,
  information: `2b. Further information regarding your support needs`,
  assess: `3. Assessments`,
  part: `in partnership with`,
  avtav: ` AVTAV`,
  takes: ` to take the below course`,
  support: `Have you previously had support in assessments?`,
  useful: `3a. If yes, have you found this support useful?`,
  you: `3b. Do you require any of the following during assessments?`,
  lang: `4. Languages`,
  native: `4a. What is your native language?`,
  speak: `4b. What other languages do you speak?`,
  language: `4c. The training will be delivered in English. Do you require support with English language skills?`,
  what: `4d. If yes, please tell us what we can do to support you.`,
  computers: `5. Working with computers`,
  inv: `You have been invited by `,
  compName: ` COMPANY_NAME`,
  skill: `Some of your training will use computers. What is your skill level in using computers?`,
  austim: `Autism/Asperger`,
  hear: `Deaf or hearing impairment `,
  learning: `Learning disability `,
  long: ` Long term health condition`,
  mental: `Mental health`,
  mob: ` Mobility/Physical disability`,
  oth: ` Other`,
  comm: `Communication`,
  concen: ` Concentration`,
  conf: ` Confidence`,
  energy: ` Energy Levels`,
  exm: ` Exams`,
  gram: ` Grammar`,
  groupLook: ` Group Work`,
  hand: ` Handwriting `,
  hear: `Hearing`,
  meet: ` Meeting deadlines`,
  mobi: ` Mobility `,
  mood: `Mood`,
  moti: ` Motivation`,
  spec: `A specific font`,
  font: ` Font size`,
  back: ` Background colour`,
  othe: ` Other:`,
  noteTake: `Note taking`,
  organs: ` Organisation`,
  phy: ` Physical health`,
  present: ` Presentations`,
  read: ` Reading Accuracy`,
  mem: ` Short term memory`,
  spell: ` Spelling`,
  write: ` Writing`,
  times: ` Time management`,
  typing: ` Typing`,
  visions: ` Vision`,
  write: ` Writing Speed`,
  john: ` John Doe`,
  name: "Name: ",
  preCourse: `Pre-course Questionnaire`,
  please: `To make your user experience better please complete the below pre-course`,
  before: `questionnaire before starting your course`,
  yes: `Yes`,
  no: `No`,
  begineer: `Beginner`,
  inters: `Intermediate`,
  exp: `Expert`,
  hlo: `Hello, `,
  johns: ` John`,
  youField: `To assist you better with your learning experience, we will have to`,
  contantYou: ` contact you first before you can proceed to take this course.`,
  fully: `Give us a few minutes to get in contact with you, to ensure your learning experience is fully beneficial.`,
  returnMy: `Return to my courses pages`,
};

export const second = {
  policy: `Malpractice Policy and Procedures`,
  procedure: `Please familiarise yourself with our malpractice policy and procedure before starting your course.`,
  scope: `Scope`,
  conjunction: `This policy applied to training programmes based on DfT syllabuses. It should be read in conjunction with all other policies, particularly:`,
  assess: `Assessment Policy`,
  equality: ` Equality Policy`,
  data: ` Data Protection Policy`,
  appeal: ` Appeals Policy`,
  comp: ` Complaints and Whistleblowing Policy`,
  define: `Defining Malpractice`,
  authority: `The Appropriate Authority defines malpractice as any deliberate activity, neglect, default, or other practice that compromises the integrity of the assessment process/ or the validity of certificates, by either the Trainee or the Instructor. Examples include: `,
  collab: `Working in collaboration with others when an assessment must be completed by individual candidates`,
  someone: ` Pretending to be someone else`,
  another: ` Copying from another candidate`,
  work: ` Fabrication of work`,
  trainee: ` Impersonation of another trainee during the assessment`,
  assistance: ` Improper assistance by the instructor and falsification of marks`,
  instructor: ` Taking extracts from another person’s work, published or unpublished without using quotation marks and/or acknowledging the source`,
  deficiencies: ` In response to any identified deficiencies, the DfT will seek to apply the principles of the stepped approach adopted by the CAA Aviation Security Compliance team, and if necessary, prosecution for non-compliance with the relevant legislation.`,
  commit: ` Our commitment`,
  assessors: ` Valid and reliable assessment occurs when trainees present authentic and current evidence of their knowledge, understanding and skills which are assessed by assessors against specified standards.`,
  those: `The purpose of this policy is to limit the threat to the integrity of those assessment decisions.`,
  pro: ` Our procedures `,
  minimise: ` AVTAV adopts the following practices to minimise malpractice:`,
  clients: `We advise trainees and clients of our malpractice policy and their own responsibility for the prevention of malpractice`,
  instructors: ` We inform our clients, trainees and instructors of the implications of malpractice through corporate/ individual declarations prior to training, and during training either classroom based or e-learning`,
  questions: ` We utilise multiple choice questions for our trainees`,
  practices: ` We adopt regular reviews of our practices, procedures, and documentation to reduce the risk of malpractice.`,
  suspected: ` We follow formal procedures in cases when malpractice is suspected.`,
  malPro: `1. Malpractice procedures`,
  delegate: `In cases of suspected malpractice from a delegate AVTAV adopts the following procedures:`,
  stage1: `Stage 1`,
  notified: ` The individual will be notified of the issues and possible consequences. This will be added to the assessment feedback sheet. The trainee will be given the opportunity to present their case to the IAQ, or a nominated deputy.`,
  investigated: ` IAQ will be notified of the issues and will investigate by:`,
  scrutinising: ` Scrutinising evidence`,
  discussing: ` Discussing with the instructor/ assessor`,
  dis: ` Discussing with the trainee `,
  iaq: `IAQ will make a decision and will inform both the trainee and the instructor/ assessor and a sanction. Sanctions include:`,
  warn: ` A warning`,
  fail: ` A fail grade given to the summative assessment with no opportunity for resubmission`,
  grade: ` A fail grade to the summative assessment with an opportunity for resubmission`,
  from: ` Exclusion from the course`,
  records: `A record of the process, the evidence and the conclusion will be maintained and contribute to the training programme review and evaluation.`,
  stage2: ` Stage 2 `,
  must: `If the trainee disagrees with the outcome of the investigation by the IQA and/ or another sanction to be applied, they must submit an appeal to Quality Manager`,
  inc: ` Quality Manager review will include:`,
  document: `A scrutiny of all documentation `,
  inter: `An interview with the trainee`,
  acc: ` An interview with the instructor/ assessor`,
  qual: ` An interview with the Internal Quality Assessor (IQA)`,
  manager: ` Quality Manager will make a decision and informs the trainee, instructor/ assessor and Head of Training.`,
  prog: ` A record of the process, the evidence and the conclusion will be maintained and contribute to the training programme for review and evaluation `,
  stage3: ` Stage 3`,
  eviden: `When the trainee disagrees with the outcome of the investigation, they must notify Head of Training. They will review all evidence and contact all parties with their decision is final.`,
  cli: ` When a client identifies a suspected malpractice, AVTAV will investigate the matter thoroughly using this process.`,
  avai: ` Our malpractice policy is available to all trainees and clients upon request.`,
  ann: ` The policy is reviewed annually/ or when legislation changes. `,
  found: `Equality, Health and safety, Complaints, Malpractice, Appeals, Data protection -Policies and procedures can be found at `,
  ht: `https://avtav.co.uk/training/`,
  agr: `I agree to the Malpractice Policy and Procedures of AVTAV Ltd.`,
  start: `Start your training course`,
};
