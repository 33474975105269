import * as Styled from "./style";
import MultiStepProgressBar from "../../components/Atoms/MultiStepProgressBar/MultiStepProgressBar";
import React from "react";
import Sidebar from "../../components/Organisms/Sidebar/Sidebar";
import SignupHeader from "../../components/Atoms/SignupHeader/SignupHeader";
import sidebarbg from "../../assets/image/sidebarbg.jpg";
import useStepper from "../../hooks/StepperHook";
import { constant } from "../../constants/Constants";
import LogoSide from "../../../src/assets/Svg/LogoSide.svg";
import LogoTraining from "../../../src/assets/Svg/LogoTraining.svg";
import visitTraining from "../../../src/assets/Svg/visitTraining.svg";
import { FaFacebook } from "react-icons/fa";
import { AiFillLinkedin, AiOutlineInstagram } from "react-icons/ai";

const Signup = () => {
  const { next, prev, current, steps, signUpData, setErrors, errors } =
    useStepper();

  return (
    <Styled.Container>
      <Styled.SidebarContainer>
        <Styled.ImageContainer src={sidebarbg} alt={constant.CorporateImage}>
          <Sidebar />
        </Styled.ImageContainer>
      </Styled.SidebarContainer>
      <Styled.FormContainer>
        <Styled.ImgContainer>
          <Styled.Log>
            <Styled.ImgLogo src={LogoSide}></Styled.ImgLogo>
          </Styled.Log>
          <Styled.ParaDiv src={visitTraining}></Styled.ParaDiv>
        </Styled.ImgContainer>
        <Styled.MainContainer>
          <Styled.Head>
            <SignupHeader title={constant.CorporateUser} />
          </Styled.Head>
          <MultiStepProgressBar
            current={current}
            steps={steps}
            nextButton={constant.Next}
            previousButton={constant.Previous}
            buttonHide={true}
            prev={prev}
            next={next}
            status="isCorporate"
            signUpData={signUpData}
            errors={errors}
            setErrors={setErrors}
            type="corporate"
          />
        </Styled.MainContainer>

        <Styled.IconContainer>
          <Styled.Img2>
            <Styled.LogoImg src={LogoTraining} />
          </Styled.Img2>
          <Styled.Icons>
            <FaFacebook className="icon" />
            <AiFillLinkedin className="icon" />
            <AiOutlineInstagram className="icon" />
          </Styled.Icons>
        </Styled.IconContainer>
      </Styled.FormContainer>
    </Styled.Container>
  );
};

export default Signup;
