import { MdAddShoppingCart } from "react-icons/md";

export const courseTab = [];
export const tabData = [
  {
    id: 0,
    label: "Transport",
  },
  {
    id: 1,
    label: "Industrial",
  },
  {
    id: 2,
    label: "General Compliance",
  },
  {
    id: 3,
    label: "General Management",
  },
  {
    id: 4,
    label: "Wellbeing programmes",
  },
];

export const CoursesCardConstant = {
  courseName: "Course Type:",
  priceName: "Price:",
  pricesymbol: "£",
  Browse: "Browse Course Information",
  btn: true,
  cartButton: true,
  buttonName: "Browse Training Session",
  shoppingCart: <MdAddShoppingCart className="cartIcon" />,
};

export const trainingConstant = {
  title:
    "GSAT - REGULATED AGENT - KNOWN CONSIGNOR - HOLD BAGGAGE RECONCILIATION - AIRCRAFT SEARCH",
  content:
    "Our Aviation Training Packages adhere to all regulatory body standards and are delivered by fully qualified, DfT Certified Instructors, who have a wealth of training expertise and experience.",
  subContent:
    "We offer classroom-based courses which can be delivered at our AVTAV Training Centres, at our client’s premises or via our e-learning platform. If you are a direct employer or agency labour provider,our training courses will ensure that staff are provided only with the skills and training they need to effectively and compliantly carry out their role.",
  btnTitle: "Browse Course Information",
  heading: "AVSEC Courses",
  buttonTitle: "Browse Training Section",
  Regulated: "Regulated Agent Courses",
  Consignor: "Known Consignor Courses",
  Formerly_CO: "Formerly CO",
  Transport: "Transport Courses",
  Industrial: "Industrial Courses",
  Compliance: "General Compliance",
  Management: "General Management Training",
  Wellbeing: "Wellbeing Programmes",
  Agent: `Our Regulated Agent Courses cover <b>all levels of training </b>
  required for those who have access to and are involved in the
  handling, screening, processing, and transportation of secure air
  cargo and for those responsible for the supervising and managing of
  these duties.`,
  Back: "Back to list",
};
