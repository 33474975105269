import React, { useState } from "react";
import * as Styled from "./style";
import { LuImagePlus } from "react-icons/lu";
import Button from "../../../components/Atoms/Button/button";
import { ModalText } from "../businessConstant";
import { useLicense } from "../../../hooks/LicenseHook";
import Link from "antd/es/typography/Link";
import { IoIosSend } from "react-icons/io";
import { buttonName } from "./LicenseData";
import useCommonHook from "../../../hooks/CommonHook";
import { useSelector } from "react-redux";
import Signature from "../../../components/Atoms/Signature/Signature";
import { message } from "antd";

const UploadviaCSVModal = ({
  uplaodViaCSV,
  setUplaodViaCSV,
  setIssueLicenseOpen,
}) => {
  const [fileName, setFileName] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState({});
  const { licenseTableData, fetchLicensesList } = useLicense();
  const { uplaodCSV } = useCommonHook();

  const loggedInUser = useSelector((state) => state.authReducer.user);
  const handleCheckbox = (name, e) => {
    setUplaodViaCSV(() => {
      return { ...uplaodViaCSV, [name]: e.target.checked };
    });
  };
  const handleSelect = (e) => {
    const course = licenseTableData.find((c) => c.courseId == e.target.value);
    setSelectedCourse(course);
    const extra =
      selectedCourse?.course?.type !== "AVSEC"
        ? {
            courseId: e.target.value,
            is5YearTrainee: false,
            isAirsideJob: false,
            orderId: course.orderId ? course.orderId : 0,
          }
        : { courseId: e.target.value, orderId: course.orderId };
    setUplaodViaCSV(() => {
      return { ...uplaodViaCSV, ...extra };
    });
  };

  const onFileChange = (e) => {
    var file = e.target.files ? e.target.files[0] : e.target.value || undefined,
      supportedFormats = ["text/csv"];
    if (file && file.type) {
      if (0 > supportedFormats.indexOf(file.type)) {
        alert("unsupported format");
      } else {
        if (e.target.files && e.target.files[0]) {
          setUplaodViaCSV(() => {
            return { ...uplaodViaCSV, csv: e.target.files[0] };
          });
          setFileName(e.target.files[0].name);
        }
      }
    }
  };
  const signature = localStorage.getItem("sign");

  const handleUploadButton = async () => {
    let error = [];
    if (!uplaodViaCSV.courseId && !uplaodViaCSV.csv) {
      message.error("Please select Course and CSV file");
      error.push("Please select Course and CSV file");
    } else if (!uplaodViaCSV.courseId) {
      message.error("Please select Course");
      error.push("Please select Course");
    } else if (!uplaodViaCSV.csv) {
      message.error("Please select the CSV file");
      error.push("Please select the CSV file");
    } else if (
      selectedCourse?.course?.type === "AVSEC" &&
      uplaodViaCSV.is5YearTrainee === false &&
      uplaodViaCSV.isAirsideJob === false
    ) {
      message.error("Please select the checkbox");
      error.push("Please select the checkbox");
    } else if (
      selectedCourse?.course?.type === "AVSEC" &&
      uplaodViaCSV.is5YearTrainee === false
    ) {
      message.error("Please select the checkbox");
      error.push("Please select the checkbox");
    } else if (
      selectedCourse?.course?.type === "AVSEC" &&
      uplaodViaCSV.isAirsideJob === false
    ) {
      message.error("Please select the checkbox");
      error.push("Please select the checkbox");
    } else if (selectedCourse?.status === 0 || selectedCourse?.status === 2) {
      message.error(
        "The declaration form has either been denied or is awaiting approval"
      );
      error.push(
        "The declaration form has either been denied or is awaiting approval"
      );
    } else if (
      !licenseTableData[0]?.staffDetails?.sign &&
      signature === null &&
      (loggedInUser?.type === "Operations" ||
        loggedInUser?.type === "Finance Account")
    ) {
      message.error("Signature is required");
      error.push("Signature is required");
    }

    if (error.length === 0) {
      const formData = new FormData();
      formData.append("invitedUsers", uplaodViaCSV.csv);
      formData.append("courseId", uplaodViaCSV.courseId);
      formData.append("orderId", uplaodViaCSV.orderId);
      formData.append("is5YearTrainee", uplaodViaCSV.is5YearTrainee);
      formData.append("isAirsideJob", uplaodViaCSV.isAirsideJob);
      const resp = await uplaodCSV(formData);
      if (resp?.status) {
        message.success(resp?.message);
        fetchLicensesList();
      }
      if (resp?.status) {
        setIssueLicenseOpen(false);
      }
    }
  };

  const item = licenseTableData.length > 0 && licenseTableData[0];

  return (
    <div style={{ paddingRight: "10px" }}>
      <Styled.BoxContainer>
        <div>
          <Styled.ButtonContainer>
            <Link href={process.env.PUBLIC_URL + "/downloads/invitedusers.csv"}>
              <Styled.ButtonText>Download Template</Styled.ButtonText>
            </Link>
          </Styled.ButtonContainer>
          <Styled.NoteText>
            <span className="note">Note :</span> For inviting the trainees,
            please download this CSV Template first,
            <br /> add the trainee record, and upload the same CSV file.
          </Styled.NoteText>
        </div>
        <Styled.DropdownContainer>
          <Styled.DropdownContent>
            <Styled.DropData>
              <select name="courseId" id="language" onChange={handleSelect}>
                <option value="" disabled selected>
                  {ModalText.Selectcourse}
                </option>
                {licenseTableData?.map((course) => (
                  <option key={course?.courseId} value={course?.courseId}>
                    {course?.course?.title}
                  </option>
                ))}
              </select>
            </Styled.DropData>
          </Styled.DropdownContent>
        </Styled.DropdownContainer>
      </Styled.BoxContainer>
      <Styled.UploaderContainer>
        <Styled.FileContainer for="myfile">
          <Styled.FileText>
            <LuImagePlus className="icon" />
            <input
              className="inputFile"
              type="file"
              id="myfile"
              name="myfile"
              accept=".csv"
              onChange={(e) => onFileChange(e)}
            />
          </Styled.FileText>
          {fileName}
        </Styled.FileContainer>
      </Styled.UploaderContainer>
      {selectedCourse?.course?.type === "AVSEC" ? (
        <Styled.Checkbox>
          <Styled.CheckboxContainer>
            <div className="checkboxcontainer">
              <input
                type="checkbox"
                value={true}
                name={"is5YearTrainee"}
                className="checkboxInput"
                onChange={(e) => handleCheckbox("is5YearTrainee", e)}
              />
              <span className="textConfirm">{ModalText.Confirm}</span>
            </div>
            <div className="checkboxcontainer2">
              <input
                type="checkbox"
                value={true}
                name="isAirsideJob"
                className="checkboxInput2"
                onChange={(e) => handleCheckbox("isAirsideJob", e)}
              />
              <span className="textSize">
                {ModalText.Has}{" "}
                <span className="airsideText">{ModalText.AIRSIDE}</span>{" "}
                {ModalText.Job}
                <span className="airsideText">{ModalText.Security}</span>{" "}
                {ModalText.is}{" "}
              </span>
            </div>
          </Styled.CheckboxContainer>
        </Styled.Checkbox>
      ) : null}
      <Styled.SignatureContainer>
        {(loggedInUser?.type === "Operations" ||
          loggedInUser?.type === "Finance Account") &&
        !item?.staffDetails?.sign &&
        selectedCourse?.course?.type === "AVSEC" ? (
          <Signature
            userDetail={loggedInUser}
            fetchLicensesList={fetchLicensesList}
          />
        ) : (
          <></>
        )}
      </Styled.SignatureContainer>
      <hr />
      <Styled.FooterButton>
        <Button
          className="invitebutton"
          title={buttonName.Users}
          icon={<IoIosSend className="icon" />}
          onClick={handleUploadButton}
        />
      </Styled.FooterButton>
    </div>
  );
};
export default UploadviaCSVModal;
