import { useState } from "react";

export const useCounter = () => {
  const [count, setCount] = useState(1);

  const handleIncrement = () => {
    setCount(parseInt(count) + 1);
  };

  const handleDecrement = () => {
    if (count > 1) {
      setCount(parseInt(count) - 1);
    }
  };

  return { handleDecrement, handleIncrement, count, setCount };
};
