import * as Styled from "./style";
import FormData from "./FormData";
import React, { useEffect, useRef, useState } from "react";
import SideBarPage from "../FirstScreen/SideBarPage";
import cancel_24px from "../../../assets/Svg/cancel_24px.svg";
import { RxSlash } from "react-icons/rx";
import { course } from "../FirstScreen/data";
import { HeaderTraining } from "../Header/HeaderTraining";
import { useLocation } from "react-router-dom";
import { useCourseDetails } from "../../../hooks/CourseDetailsHook";
import { useSelector } from "react-redux";

const ExamForm = () => {
  const location = useLocation();
  const moduleDurations = location.state?.moduleDurations;
  const { coursesDetails } = useCourseDetails();
  const checkListData = useSelector(
    (state) => state?.SliderSlice?.getCheckList?.data?.checklistData
  );
  // const slider_HTMLData = JSON.parse(coursesDetails?.course?.slider_html);

  const slider_HTMLData =
    coursesDetails?.course?.slider_html &&
    JSON.parse(coursesDetails?.course?.slider_html);

  const [formatedSliderData, setFormatedSliderData] = useState();

  const moduleNameRef = useRef("");
  const moduleIndexRef = useRef(0);
  const slideIndex = useRef(0);
  const formatted_data = (checkedValueIndex) => {
    const commonPages = {};

    slider_HTMLData &&
      slider_HTMLData?.map((p, index) => {
        if (commonPages[p?.moduleName] === undefined) {
          commonPages[p?.moduleName] = [
            { ...p, checkedValue: index === 0 || checkedValueIndex },
          ];
        } else {
          commonPages[p?.moduleName]?.push({ ...p, checkedValue: false });
        }
      });
    setFormatedSliderData(commonPages);
    moduleNameRef.current = commonPages && Object.keys(commonPages)[0];
  };
  useEffect(() => {
    if (checkListData && Object.keys(JSON.parse(checkListData))?.length > 0) {
      setFormatedSliderData(JSON.parse(checkListData));
    }
  }, [checkListData]);

  return (
    <>
      <HeaderTraining />
      <Styled.Module>
        <Styled.Training>
          <div className="secure">
            <div className="cor">
              {course.cor}
              <RxSlash className="slash" />
              {coursesDetails?.course?.title}
            </div>
          </div>
          <div className="container">
            <FormData data={location} />
          </div>
        </Styled.Training>
        <Styled.TickData>
          <div>
            <div>
              <div className="maincourse">
                <div className="mod">
                  {course.mod}
                  <br />
                  <span className="mainModule">{course.moduleno}</span>
                </div>
                <div>
                  <img src={cancel_24px} className="cancel" alt="cancel-btn" />
                </div>
              </div>
            </div>
            <div>
              <SideBarPage
                slider_HTMLData={slider_HTMLData}
                moduleNameRef={moduleNameRef}
                moduleIndexRef={moduleIndexRef}
                slideIndex={slideIndex}
                formatted_data={formatted_data}
                setFormatedSliderData={setFormatedSliderData}
                formatedSliderData={formatedSliderData}
                showcheckbox={false}
                moduleDurations={moduleDurations}
              />
            </div>
          </div>
        </Styled.TickData>
      </Styled.Module>
    </>
  );
};

export default ExamForm;
