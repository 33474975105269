import { createSlice } from "@reduxjs/toolkit";

const completeCourseSlice = createSlice({
  name: "completeCourse",
  initialState: {
    CoursesCompleted: [],
    loading: false,
    error: null,
  },
  reducers: {
    completeCourseSuccess: (state, action) => {
      state.loading = false;
      state.CoursesCompleted = action.payload;
    },
    completeCourseFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.data;
    },
  },
});
export const { completeCourseSuccess, completeCourseFail } =
completeCourseSlice.actions;
export default completeCourseSlice.reducer;
