import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Card = styled.div`
  width: 280px;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 5px;
  background: ${color._ffffff};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 10px 0 10px;
  @media only screen and (max-width: 800px) {
    width: 600px;
    height: fit-content;
  }
`;

export const ImageCard = styled.img`
  width: 100%;
  display: block;
`;

export const Background = styled.div`
  border-radius: 2px;
  background: rgba(1, 56, 79, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
`;

export const ImageCardContainer = styled.div`
  margin: 0px auto;
  position: relative;
`;
export const HoveredItem = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: left;
`;
export const Line = styled.div`
  width: 93%;
  height: 1px;
  background: ${color._eaedef};
  margin: 10px auto;
`;

export const Heading = styled.div`
  color: ${color._16384e};
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-bottom: 6px;
  display: flex;
  min-height: 43px;
  @media only screen and (max-width: 800px) {
    font-size: 33px;
    padding-bottom: 20px;
    min-height: 99px;
  }
`;

export const SubHeading = styled.div`
  color: ${color._767676};
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;
export const DescriptionSection = styled.div`
  padding: 5px 10px;
  & .cardTitleAlignment {
    margin-top: -20px;
  }
`;
export const Phone = styled.div`
  color: ${color._16384e};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const Divider = styled.div`
  width: 0.5px;
  height: 18px;
  background: rgba(0, 0, 0, 0.1);
`;

export const DetailSection = styled.div`
  display: flex;
  margin-top: 12px;
  gap: 15px;
`;

export const Button = styled.button`
  width: 100%;
  height: 28px;
  flex-shrink: 0;
  border-radius: 2px;
  background: ${color._ffc636};
  margin: 12px auto;
  border: none;
`;
export const ButtonText = styled.div`
  color: ${color._0f2a3c};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
