import BrowseDeclaration from "../../containers/BrowseTrainingDeclaration/BrowseDeclaration";
import MainLayout from "../../layout/MainLayout";
import React from "react";

const BrowseDeclarationPage = () => {
  return (
    <MainLayout>
      <BrowseDeclaration />
    </MainLayout>
  );
};

export default BrowseDeclarationPage;
