import React from 'react'
import * as Styled from "./style"
import CivilLogo from "../../../assets/image/Civil-logo.png"
import RECWhiteLogo from "../../../assets/image/RECWhite.png"
import MemberLogo from "../../../assets/image/memberLogo.png"
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { GoTriangleDown } from "react-icons/go";

const SocilaMedia = () => {
  return (
    <Styled.MainContainer>
        <Styled.WhiteIcon><GoTriangleDown className='triangleIcon' /></Styled.WhiteIcon>
        <div className='container mainBox'>
        <div className='leftBox'>
            <img src={CivilLogo} alt='civilLogo' className='recIcon'/>
            <img src={RECWhiteLogo} alt="RECLOgo" className='recIcon'/>
        </div>
        <div className='middleBox'>
            <img src={MemberLogo} alt='MemberLogo'/>
            <h2 className='text'>GLAA <br/>LICENCED:<br/> AVTA0001</h2>
        </div>
        <div className='rightContainer' >
            <div className='boxContainer'>
            <h2 className='followText'>Follow Us</h2>
            <div className='rightBox'>
            <FaFacebookF className='socialIcon' />
            <FaLinkedinIn className='socialIcon' />
            <IoLogoInstagram className='socialIcon' />
            </div>
            </div>

        </div>
        </div>
    </Styled.MainContainer>
  )
}
export default SocilaMedia
