export const AccountDashboardData = {
  It: "It is a requirement of (EU) No. 2015/1998 and the UK SCD (A) that the relevant background check is completed before any training is provided. Chapter 11.1.5 requires that a Background check shall be completed before a person receives any security training which involves access to information that is not available to the public.",
  Background: "BACKGROUND CHECK*",
  DfT: "DfT ",
  National: "National Aviation Security Programme (NASP)",
  Please: "Please sign here",
  dateformate: "DD/MM/YYYY",
  confirmDec: "I can confirm that ALL of ",
  Date: "Date",
  email: "email",
  YourEmail: "Your Email",
  undertaking: `Employees, undertaking AVTAV LTD AVSEC Training, are employed in
  an AVSEC role within the organization and have successfully
  completed`,
  employerAccount: "name_of_employer_account",
  YourMobileNumber: "Your Mobile Number",
  rolenamehere: "role name here",
  LandlineNumber: "Landline Number",
  address: "address line 1",
  CompanyRegNumber: "Company Reg Number",
  YourName: "Your Name",
  towncity: "town/city postcode",
  addressline2: "address line 2",
  addressline1: "address line 1",
  CompanyAddress: "Company Address",
  nameofemployerAccount: "name_of_employer account",
  CompanyName: "Company Name",
  IN: "In accordance with Community and national rules, a ",
  shall: " shall at least:",
  establish:
    "Establish the person’s identity on the basis of documentary evidence;",
  cover:
    "Cover employment, education and any gaps during at least the preceding 5 years and;",
  criminal:
    "Cover criminal records in all states of residence during at least the preceding 5 years.",
  confirm: "I can confirm that (learner’s name). ",
  NAME_OF_TRAINEE: "NAME",
  NAME_OF_ACCOUNT: "NAME_OF_ACCOUNT",
  isEmployed: "is employed in an AVSEC role as ",
  ROLE_NAME: "ROLE_NAME ",
  Successfully: "and has successfully completed:",
  BACKGROUND: "A BACKGROUND CHECK ",
  access: "(access with security function / access with no security function)",
  Pleasetick: "Please tick",
  Has: "Has an ",
  CorporateHave: "Have an ",
  CorporateAssigned:
    "job or have been assigned a job within an aviation security role where access to the ",
  CorporateRequired:
    "is required or have been assigned a job with an aviation security role as specified above and is eligible to undertake security training that contains material that is not available to the public, and which is relevant to his/her role or position",
  AIRSIDE: "AIRSIDE ",
  And: "AND",
  assigned:
    "job or has been assigned a job within an aviation security role where access to the ",
  Security:
    "Security Restricted Area (SRA) and/or Critical Part of Security Restricted Area (CPSRA) ",
  required:
    "is required or has been assigned a job with an aviation security role as specified above and is eligible to undertake security training that contains material that is not available to the public, and which is relevant to his/her role or position",
  MALPRACTICE: "MALPRACTICE",
  deliberate:
    "Malpractice is the deliberate activity of neglect or other practice that compromises the integrity of the assessment and the validity of the certificates by either the Trainee or the Instructor. Examples include fabrication of work, improper assistance by the instructor and falsification of marks and impersonation of another trainee during assessment.",
  APPROPRIATEAUTHORITYACTION: "APPROPRIATE AUTHORITY ACTION",
  The: "The ",
  willseek:
    " will seek to take a stepped approach to compliance enforcement and, if necessary, persecution for non-compliance with the relevant legislation.",
  purchasing:
    "In purchasing this course, you are agreeing to abide by the AVTAV Malpractice Policy which requires organisations to ensure that their personnel do not receive any external assistance whilst completing this course or the associated assessment. Should it be discovered that individuals received assistance to complete their assessment, the matter will be referred to the ",
  result:
    "and could result is the withdrawal of the qualification or further investigation by the ",
  contravention: "for the contravention of the ",
  associatedsyllabuses:
    " (General Mandated Conditions) and associated syllabuses.",
  job: "Job Role",
  jobTitle: "Job Title",
  star: "*",
};
