import HttpApis from "../services/HttpApis";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Action,
  myCourseName,
  RenderDate,
} from "../containers/Corporate/BusinessUserLicense/CustomRender";
import { UpdatedCoursesList } from "../redux/slices/coursesSlice";
import {
  myCourseSuccess,
  individualCoursesSuccess,
} from "../redux/slices/myCourseSlice";
import { Routes } from "../routes/routes";

import {
  Courses,
  ActiveTag,
  CoursesDetail,
} from "../containers/Corporate/BusinessUserLicense/CustomRender";
import { useLicense } from "./LicenseHook";

export const useCourse = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const courses = useSelector((state) => state?.coursesReducer?.courses);
  const {
    fetchLicensesList,
    tableParams,
    search,
    handleTableChange,
    handleSearch,
    debouncedSearch,
    loading,
    licenseCount,
  } = useLicense();

  const licenseRelatedCourses = useSelector(
    (state) => state?.licenseReducer?.licenseList?.licenses
  );
  const [sortOrder, setSortOrder] = useState("DESC");
  const handleClick = (slug) => {
    navigate(`${Routes?.multiUserDetailPage}/${slug}`);
  };

  const getMyCourseData = async () => {
    try {
      const response = await HttpApis.getMyCourse();
      dispatch(myCourseSuccess(response?.data));
    } catch (error) {
      console.log("error");
    }
  };

  const getIndividualCoursesData = async () => {
    try {
      const response = await HttpApis.getIndividualCourse();
      dispatch(individualCoursesSuccess(response?.data));
    } catch (error) {
      console.log("error");
    }
  };

  const handleSort = (e) => {
    setSortOrder(e.target.value);
  };
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await HttpApis.coursesList();
        dispatch(UpdatedCoursesList(response?.data?.data));
      } catch (error) {
        console.error(error);
      }
    };

    fetchCourses();
  }, []);
  const [show, setShow] = useState(false);

  const handleShow = (row) => {
    setShow(row);
  };

  const TableCourse = [
    {
      label: "Date Added",
      mappedkey: "createdAt",
      defaultSortOrder: "descend",
      sorter: true,
      customRender: RenderDate,
    },
    {
      label: "Course Name",
      mappedkey: "course",
      customRender: myCourseName,
      action: handleShow,
    },
    {
      label: "Licenses",
      mappedkey: "qty",
    },
    {
      label: "Completed",
      mappedkey: "complited",
    },
    {
      label: "Active Licenses",
      mappedkey: "active",
      customRender: ActiveTag,
    },
    {
      label: "Actions",
      mappedkey: "actions",
      action: handleShow,
      customRender: Action,
    },
  ];

  return {
    TableCourse,
    show,
    fetchLicensesList,
    tableParams,
    search,
    handleTableChange,
    handleSearch,
    debouncedSearch,
    sortOrder,
    handleSort,
    loading,
    licenseCount,
    setShow,
    handleClick,
    courses,
    getMyCourseData,
    getIndividualCoursesData,
    licenseRelatedCourses,
  };
};
