import React from 'react'
import SocilaMedia from '../../components/Atoms/SocilaMedia/SocilaMedia'
import * as Styled from "./style"
import Button from '../../components/Atoms/Button'
 const Resources = () => {
  return (
    <>
    <Styled.ResourcesContainer>
      <div className='container heading'>RESOURCES</div>  
    </Styled.ResourcesContainer>
    <Styled.ButtonContainer >
        <div className='leftContainer'>
            <Button className="buttonBox" title={"Our Privacy Policy"}/>
        </div>
        <div className='rightContainer'>
            <Button className="buttonBox" title={"Terms & Conditions"}/>
        </div>
    
    </Styled.ButtonContainer>
     <SocilaMedia />
    </>
  )
}
export default Resources
