export const Routes = {
  trainingCourse: "/training-course",
  askingPage: "/asking-page",
  corporateSignup: "/corporate-signup",
  corporateSignupSubmitted: "/corporate-signup-submitted",
  singleUserSignup: "/single-user-signup",
  Signin: "/sign-in",
  declarationFormSent: "/declaration-form-sent",
  multiUserDetailPage: "/training-course",
  multiCart: "/multi-cart",
  checkoutPage: "/checkout-page",
  userProfile: "/user-profile",
  paymentMessage: "/payment-message",
  OrderCancelForm: "/Order-cancel-form",
  orderConfirmation: "/order-confirmation",
  browseDeclaration: "/browse-declaration",
  paymentSoleTrader: "/payment-sole-trader",
  corporateUserDeclaration: "/corporate-user-declaration",
  coursesDetail: "/courses-detail",
  invitedUserSignUp: "/invited-user-signUp",
  homePage: "/",
  contactPage: "/contact",
  aboutUsPage: "/about-us",
  recruitmentPage: "/recruitment",
  vettingPage: "/vetting",
  resourcesPage: "/resources",
  privacyNotes: "/privacy-notes",
  termsPage: "/terms-page",
  acceptablePage: "/acceptable-page",
  onlineTermsPage: "/online-terms-page",
  endUserPage: "/end-user-page",
  helloJohn: "/invited-user-pre-course",
  singleUserProfile: "/single-user-profile",
  preCourseInvited: "/pre-course-invited",
  singlePreCourse: "/single-pre-course-invited",
  examCourse: "/exam-instruction",
  aboutTrainingCourse: "/about-course/:id",
  inductionChecklist: "/induction-checklist",
  courseCompletion: "/course-completion",
  feedbackForm: "/feedback-form",
  exam: "/exam",
  managerDecPdf: "/managerDecPdf",
};
