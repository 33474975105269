import * as Styled from "./style";
import HeaderPre from "./HeaderPre";
import React from "react";
import a from "../../assets/Svg/a.svg";
import { course } from "./data";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../routes/routes";

const PreCourse = () => {
  const userData = useSelector((state) => state?.authReducer?.user);
  const navigate = useNavigate();
  const handleRoute = () => {
    navigate(Routes?.singleUserProfile);
  };
  return (
    <>
      <HeaderPre />
      <Styled.John className="container">
        <div>
          <Styled.JohnDiv className="container">
            <img src={a} />
            <div>
              <div className="hlo">
                {course.hlo}
                <span className="john">
                  {userData?.firstName + " " + userData?.lastName}
                </span>
              </div>
              <div className="field">
                {course.youField}
                <br />
                {course.contantYou}
              </div>
            </div>
          </Styled.JohnDiv>
          <Styled.Inter>{course.fully}</Styled.Inter>
          <Styled.Return onClick={() => handleRoute()}>
            <div>{course.returnMy}</div>
          </Styled.Return>
        </div>
      </Styled.John>
    </>
  );
};

export default PreCourse;
