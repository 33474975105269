import React, { useEffect } from "react";
import * as Styled from "./style";
import Card from "../../../components/Atoms/Cards/Card";
import { TextData } from "./data";
import { useSelector } from "react-redux";
import { useCourse } from "../../../hooks/CourseHook";
import Button from "../../../components/Atoms/Button/button";
import { BiSolidRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/routes";
import { message, Progress } from "antd";

export const IndividualCourses = () => {
  const { getIndividualCoursesData } = useCourse();
  const navigate = useNavigate();
  const individualCourseList = useSelector(
    (state) => state?.myCourseReducer?.IndividualCourses?.data?.cources
  );

  useEffect(() => {
    getIndividualCoursesData();
  }, []);

  const userDetail = useSelector((state) => state?.authReducer?.user);
  const firstName = userDetail?.firstName ? userDetail?.firstName : "";
  const lastName = userDetail?.lastName ? userDetail?.lastName : "";
  const username = firstName + " " + lastName;

  const handleStartCourse = (
    courseId,
    alreadyPrequestioner,
    title,
    slug,
    isDeclarationFormApproved,
    type,
    prequestionerStatus
  ) => {
    if (alreadyPrequestioner === 0) {
      if (isDeclarationFormApproved === 1 && type === "AVSEC") {
        navigate(Routes.singlePreCourse, {
          state: { courseId: courseId, slug: slug },
        });
      } else if (isDeclarationFormApproved === 0 && type !== "AVSEC") {
        navigate(Routes.singlePreCourse, {
          state: { courseId: courseId, slug: slug },
        });
      } else {
        message.error("Please Contact to admin");
      }
    } else {
      if (prequestionerStatus === "Accept") {
        navigate(Routes?.aboutTrainingCourse.replace(":id", slug), {
          state: {
            courseId: courseId,
            title: title,
            username: username,
            slug: slug,
          },
        });
      } else {
        message.error("Please Contact to admin");
      }
    }
  };

  return (
    <div>
      <Styled.Header>
        <div className="invitedText">{TextData.individualCourses}</div>
        <div className="text">{TextData.individualText}</div>
      </Styled.Header>

      <Styled.CardBox>
        {individualCourseList?.map((items) => {
          return (
            <div>
              {items?.OrderItems?.map((coursesList) => {
                return (
                  <div>
                    <Card
                      data={coursesList?.Course}
                      children={
                        coursesList?.progress <= 0 ? (
                          <Styled.StartButton>
                            <Button
                              className="start"
                              title={"START COURSE"}
                              icon={<BiSolidRightArrow />}
                              onClick={() =>
                                handleStartCourse(
                                  coursesList?.courseId,
                                  coursesList?.alreadyPrequestioner,
                                  coursesList?.Course?.title,
                                  coursesList?.Course?.slug,
                                  coursesList?.isDeclarationFormApproved,
                                  coursesList?.Course?.type,
                                  coursesList?.prequestionerStatus
                                )
                              }
                            />
                          </Styled.StartButton>
                        ) : null
                      }
                    />
                    <>
                      {coursesList?.progress > 0 ? (
                        <Styled.ProgressBar
                          onClick={() =>
                            handleStartCourse(
                              coursesList?.courseId,
                              coursesList?.alreadyPrequestioner,
                              coursesList?.Course?.title,
                              coursesList?.Course?.slug,
                              coursesList?.isDeclarationFormApproved,
                              coursesList?.Course?.type,
                              coursesList?.prequestionerStatus
                            )
                          }
                        >
                          <div className="progressbar">
                            <span className="yourProgress">Your Progress</span>
                            <span className="complete">
                              {Math.round(coursesList?.progress)}% COMPLETE
                            </span>
                          </div>
                          <Progress
                            percent={Math.round(coursesList?.progress)}
                            status="active"
                            showInfo={false}
                          />
                        </Styled.ProgressBar>
                      ) : null}
                    </>
                  </div>
                );
              })}
            </div>
          );
        })}
      </Styled.CardBox>
    </div>
  );
};
