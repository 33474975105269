import { AiFillCaretRight, AiFillStar } from "react-icons/ai";
import { FaGlobeEurope } from "react-icons/fa";
import { FaDesktop } from "react-icons/fa";
import { FaTrophy } from "react-icons/fa";
import * as Styled from "./style";

export const data = [
  {
    id: 1,
    name: "Services",
    icon: <AiFillCaretRight />,
  },
  {
    id: 2,
    name: "Training Courses",
    icon: <AiFillCaretRight />,
  },
];

const StarIcon = [
  {
    id: 1,
    staricon: <AiFillStar />,
  },
  {
    id: 2,
    staricon: <AiFillStar />,
  },
  {
    id: 3,
    staricon: <AiFillStar />,
  },
  {
    id: 4,
    staricon: <AiFillStar />,
  },
  {
    id: 5,
    staricon: <AiFillStar />,
  },
];

export const pageStats = {
  languageIcon: <FaGlobeEurope />,
  durationIcon: <FaDesktop />,
  certificationIcon: <FaTrophy />,
  ratingIcon: "Rating",
  starRating: (
    <Styled.Starcontainer>
      <Styled.StarIcon>
        {StarIcon?.map((item, id) => {
          return <div key={id}>{item.staricon}</div>;
        })}
      </Styled.StarIcon>
    </Styled.Starcontainer>
  ),
  locationLabel: "Location: ",
  assessmentLabel: "Assessment:",
  validityLabel: "Validity: ",
};

export const Module = [
  {
    id: "Module 1",
    value: "The Threat to Aviation",
  },
  {
    id: "Module 2",
    value: "Security in the Aviation Industry",
  },
  {
    id: "Module 3",
    value: "The Trainee’s Role",
  },
  {
    id: "Module 4",
    value: "Access Control and the Screening Point",
  },
  {
    id: "Module 5",
    value: "Escorting Employment and/or Visitor Passholders",
  },
];
