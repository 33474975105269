import styled from "styled-components";
import { color } from "../../../theme/Color";

export const SearchContainer = styled.div`
  gap: 5px;
  .searchIcon {
    color: ${color._16384E};
    margin-top: 3px;
    margin-left: 6px;
  }
  display: flex;
  align-items: center;
  width: 210px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
`;

export const Input = styled.input`
  outline: none;
  border: none;
  flex-shrink: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  color: ${color._000000};
  &::placeholder {
    color: ${color._000000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Dropdown = styled.div`
  padding: 10px 0px;
  display: flex;
  width: 186px;
  gap: 5px;
  height: 36px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 8px;

  .filter {
    font-size: 12px;
  }
`;
export const DropData = styled.div`
  .gsat {
    border: none;
    padding-right: 92px;
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Spreadsheet = styled.button`
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 32px 0px 11px;
  display: flex;
  align-items: center;
  width: 210px;
  height: 36px;
  gap: 10px;
  color: ${color._000000};

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const Filter = styled.div`
  display: flex;
  width: 230px;
  gap: 8px;
  height: 36px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 10px;

  .filter {
    font-size: 12px;
  }
`;
export const NewestFirst = styled.div`
  .newest {
    border: none;
    outline: none;
    padding-right: 82px;
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const DotIcon = styled.div`
  width: 38px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  align-items: center;
  display: flex;
  justify-content: center;
`;
export const TableHeader = styled.div`
  padding: 13px 20px 19px 17px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  padding-bottom: 14px;
  .tablecontainer {
    display: flex;
    gap: 12px;
  }
`;
export const Pipe = styled.div`
  display: flex;
  background-color: ${color._6091a8};
  color: ${color._6091a8};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 2px;
  height: 20px;
  margin-top: 4px;
`;
export const CompletedCourses = styled.div`
  display: flex;
  gap: 7px;
  padding-bottom: 18px;
  .courses {
    color: ${color._0f2a3c};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .number {
    color: ${color._6091a8};
    font-family: Open Sans;
    margin-top: 1px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const BelowText = styled.div`
  padding-bottom: 20px;

  color: ${color._68828e};

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const MainContainer = styled.div`
  margin-bottom: 26px;
  margin-top: 26px;
  border-radius: 5px;
  border: 0.7px solid ${color._ededde};
  background: ${color._ffffff};
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
`;
export const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 37px;

  .non-active {
    border-radius: 2px;
    background: ${color._f1fbff};
    border: none;
    color: ${color._177a9c};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    height: 35px;
    padding: 0px 10px;
  }
  .active {
    background: ${color._177a9c};
    color: ${color._ffffff};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    height: 35px;
    border: none;
    border-radius: 2px;
    padding: 0px 10px;
  }
`;
