import { useEffect } from "react";
import {
  BraintreePayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import useCheckoutHook from "../../hooks/CheckoutHook";
import { useNavigate } from "react-router";
import { Routes } from "../../routes/routes";
import { useSelector } from "react-redux";

const style = { label: "paypal", layout: "vertical", height: 37 };

const ButtonWrapper = ({
  currency,
  cartTotal,
}) => {
  const [{ options }, dispatch] = usePayPalScriptReducer();
  const checkoutReferer = useSelector(
    (state) => state?.commonReducer?.checkoutReferer
  );
  const { checkOutAction } = useCheckoutHook();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency]);

  return (
    <BraintreePayPalButtons
      style={style}
      disabled={false}
      fundingSource={"paypal"}
      createOrder={(data, actions) => {
        const checkoutData = JSON.parse(localStorage.getItem("checkoutForm"));
        return actions.braintree
          .createPayment({
            flow: "checkout",
            currency: "USD",
            intent: "capture",
            amount: cartTotal,
            shippingAddressOverride: {
              ...checkoutData,
            },
          })
          .then((orderId) => {
            return orderId;
          });
      }}
      onApprove={(data, actions) => {
        const checkoutData = JSON.parse(localStorage.getItem("checkoutForm"));
        return actions.braintree.tokenizePayment(data).then(async (payload) => {
          const response = await checkOutAction({
            ...checkoutData,
            payment_method_nonce: payload.nonce,
          });
          // Your code here after capture the order
          if (response.status) {
            if (checkoutReferer === "payment-message") {
              navigate(Routes?.paymentMessage);
              localStorage.removeItem("checkoutForm")
            } else if (checkoutReferer === "payment-sole-trader") {
              navigate(Routes?.paymentSoleTrader);
              localStorage.removeItem("checkoutForm")
            } else {
              navigate(Routes?.orderConfirmation);
              localStorage.removeItem("checkoutForm")
            }
          }
        });
      }}
      onCancel={(data) => {
        console.log("PayPal payment cancelled", JSON.stringify(data, 0, 2));
      }}
      onError={(err) => {
        console.error("PayPal error", err);
      }}
    />
  );
};

export default ButtonWrapper;
