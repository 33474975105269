export const dataItem = {
  Courses: "Courses",
  Number: "5",
  BelowText: "Below is a list of the certificates of courses",
  Active: "Active",
  Newestfirst: "Newest first",
  Oldestfirst: "Oldest first",
  DownloadSpreadsheet: "Download Spreadsheet",
  Back: "Back to list",
  CourseName: "General Security Awareness Training(GSAT)",
};
