import BusinessUserLicense from "./BusinessUserLicense/BusinessUserLicense";
import BusinessUserProfile from "./BusinessUserProfile/BusinessUserProfile";
import BusinessUserSecurity from "./BusinessUserSecurity/BusinessUserSecurity";
import Course from "./Courses/Courses";
import { Billing } from "./BusinessUserBilling/Billing";
import { BusinessUserDeclaration } from "./BusinessUserDeclaration/BusinessUserDeclaration";
import { InvoicesAndPayments } from "./InvoicesAndPaymentsPage/InvoicesAndPayments";
import CorporateStaff from "./CorporateStaff/CorporateStaff";

export const tabData = [
  {
    key: "1",
    label: "Profile",
    children: <BusinessUserProfile />,
    id: 5,
  },
  {
    key: "2",
    label: "Licenses",
    children: <BusinessUserLicense />,
  },
  {
    key: "3",
    label: "Declaration Forms",
    children: <BusinessUserDeclaration />,
  },
  {
    key: "4",
    label: "Account Security",
    children: <BusinessUserSecurity />,
  },
  {
    key: "5",
    label: "Billing",
    children: <Billing />,
  },
  {
    key: "6",
    label: "Invoices & Payments",
    children: <InvoicesAndPayments />,
  },
  {
    key: "7",
    label: "Courses",
    children: <Course />,
    id: 0,
  },
  {
    key: "8",
    label: "Users",
    children: <CorporateStaff />,
  },
];
