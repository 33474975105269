import React from "react";
import * as Styled from "./style";
import ARRec from "../../assets/image/ARRec.png";
import {
  BannerData,
  BannerData1,
  OverlayCardData,
  cardData,
  cardData1,
  data,
} from "./data";
import Timescales from "../../assets/image/Timescales.png";
import Security from "../../assets/image/Security.png";
import Retention from "../../assets/image/Retention.png";
import { CiAirportSign1 } from "react-icons/ci";
import SocilaMedia from "../../components/Atoms/SocilaMedia/SocilaMedia";
import Form from "../../components/Organisms/EnquiryForm/Form";
import Button from "../../components/Atoms/Button";
import { TbChessQueenFilled } from "react-icons/tb";

const Recruitment = () => {
  return (
    <>
      <Styled.RecruitmentContainer>
        <div className="container heading">RECRUITMENT</div>
      </Styled.RecruitmentContainer>
      <Styled.About className="container">
        <div className="left">
          <div className="heading">{data.heading}</div>
          <p className="para">{data.para1}</p>
          <p className="para">{data.para2}</p>
          <p className="para">{data.para3}</p>
          <p className="para">{data.para4}</p>
        </div>
        <div className="right">
          <img src={ARRec} alt="AV-Vetting" className="cardImage" />
        </div>
      </Styled.About>

      <hr className="container" />
      <Styled.Timescale className="container">
        <div className="left">
          <div className="heading">{data.TimescalesHeading}</div>
          <p className="timeScalePara">{data.Timescales}</p>
        </div>
        <div className="right">
          <img src={Timescales} alt="AV-Vetting" className="timeScaleImage" />
        </div>
      </Styled.Timescale>
      <hr className="container" />
      <Styled.Timescale className="container">
        <div className="right">
          <img src={Security} alt="AV-Vetting" className="timeScaleImage" />
        </div>
        <div className="left">
          <div className="heading">{data.Security}</div>
          <p className="timeScalePara">{data.SecurityPara}</p>
        </div>
      </Styled.Timescale>
      <hr className="container" />
      <Styled.Timescale className="container">
        <div className="left">
          <div className="heading">{data.Retention}</div>
          <p className="timeScalePara">{data.RetentionPara}</p>
        </div>
        <div className="right">
          <img src={Retention} alt="AV-Vetting" className="timeScaleImage" />
        </div>
      </Styled.Timescale>
      <hr className="container" />
      <Styled.Timescale className="container">
        <div className="left">
          <div className="heading">{data.service}</div>
          <p className="timeScalePara">{data.servicePara1}</p>
          <p className="timeScalePara">{data.servicePara2}</p>
          <p className="timeScalePara">{data.servicePara3}</p>
        </div>
        <div className="right">
          <div className="imageBox">
            <CiAirportSign1 className="airplane" />
          </div>
        </div>
      </Styled.Timescale>
      <hr className="container" />
      <Styled.OverlyCardBox className="container">
        {OverlayCardData?.map((item) => {
          return (
            <div class="container">
              <div className="icon">
                {item?.icon}
              </div>
              <div className="heading">{item?.Heading}</div>
              <div class="overlay">
                <div class="text">{item?.para}</div>
              </div>
            </div>
          );
        })}
      </Styled.OverlyCardBox>

      <Styled.BGImageContainer>
        <div className="textContainer container">
          {BannerData?.map((item) => {
            return (
              <div className="innerBox">
                <div className="icon">{item?.icon}</div>
                <p className="para">{item?.para}</p>
              </div>
            );
          })}
        </div>
        <hr className="container line" />
        <div className="textContainer container">
          {BannerData1?.map((item) => {
            return (
              <div className="innerBox">
                <div className="icon">{item?.icon}</div>
                <p className="para">{item?.para}</p>
              </div>
            );
          })}
        </div>
      </Styled.BGImageContainer>

      <Styled.CardBox>
        <h2 className="h2">Sectors and Roles we cover</h2>

        <Styled.CardContainer>
          {cardData?.map((item) => {
            return (
              <div class="flip-box">
                <div class="flip-box-inner">
                  <div class="flip-box-front">
                    <div className="icon">{item?.icon}</div>

                    <div className="heading">{item?.Hedaing1}</div>
                  </div>
                  <div class="flip-box-back">
                    <div className="heading">{item?.heading2}</div>
                    <p className="paragraph">{item?.para}</p>
                    <div className="buttonBox">
                      <Button title={"Click Here"} className={"cardButton"} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Styled.CardContainer>
      </Styled.CardBox>
      <Styled.CardContainer1>
        {cardData1?.map((item) => {
          return (
            <div class="flip-box">
              <div class="flip-box-inner">
                <div class="flip-box-front">
                  <div className="icon">{item?.icon}</div>

                  <div className="heading">{item?.Hedaing1}</div>
                </div>
                <div class="flip-box-back">
                  <div className="heading">{item?.heading2}</div>
                  <p className="paragraph">{item?.para}</p>
                  <div className="buttonBox">
                    <Button title={"Click Here"} className={"cardButton"} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Styled.CardContainer1>
      <Form />
      <SocilaMedia />
    </>
  );
};
export default Recruitment;
