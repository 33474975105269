import * as Styled from "./style";
import React from "react";
import LogoSide from "../../../assets/Svg/LogoSide.svg";
import LogoTraining from "../../../assets/Svg/LogoTraining.svg";
import visitTraining from "../../../assets/Svg/visitTraining.svg";
import { AiFillLinkedin, AiOutlineInstagram } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";

const Sidebar = () => {
  return (
    <>
      <Styled.Container>
        <Styled.ImgContainer>
          <Styled.Log>
            <Styled.ImgLogo src={LogoSide}></Styled.ImgLogo>
          </Styled.Log>
          <Styled.Para src={visitTraining}></Styled.Para>
        </Styled.ImgContainer>
        <Styled.IconContainer>
          <Styled.Img2>
            <Styled.LogoImg src={LogoTraining} />
          </Styled.Img2>
          <Styled.Icons>
            <FaFacebook className="icon" />
            <AiFillLinkedin className="icon" />
            <AiOutlineInstagram className="icon" />
          </Styled.Icons>
        </Styled.IconContainer>
      </Styled.Container>
    </>
  );
};

export default Sidebar;
