export const employeeDetailConstant = {
  confirmation: `  If you are a sole trader and your background declaration has
    been self- authorised, we will require confirmation of the
    dates of the period of self-employment from independent
    accountants, solicitors or from the relevant government
    department or agency (on their company letterheaded paper),
    or the DfT. Please note that in order for your references to
    conform to Chapter 11 guidelines, all references must be
    dated, include a signature, and all confirmation of dates
    require a ‘From and To’ date which must be in DD/MM/YYYY
    format.`,
  declare: ` Once payment is completed, the declaration will be sent for signature to your employer. Upon approval from AVTAV training team you will granted access to the course.`,
};
export const declarationSingle = {
  backInfo: "Background Check Confirmation and Employment Information",
};
