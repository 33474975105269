import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Background = styled.div`
  position: relative;
  width: 100%;
`;
export const Container = styled.div`
  position: absolute;
  top: 30%;
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  padding: 0 40px;
`;
export const BoxContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
export const ImgContainer = styled.div`
  width: 135px;
`;
export const IconMap = styled.div`
  .img {
    display: flex;
    gap: 15px;
  }
`;
export const Icons = styled.div`
  .JFBtn {
    background: ${color._ffffff};
    border-radius: 50%;
    border: none;
    width: 31px;
    height: 31px;
    color: #081e2c;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
