import HttpApis from "../services/HttpApis";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import {
  loginFailure,
  loginStart,
  loginSuccess,
  registerFailure,
  registerStart,
  registerSuccess,
  updateEditUserData,
} from "../redux/slices/authSlice";

const useAuthHook = () => {
  const userId = useSelector((state) => state.authReducer.user?.id);
  const sessionId = useSelector((state) => state.commonReducer.sessionId);
  const dispatch = useDispatch();
  const [loginDetails, setLoginDetails] = useState({
    userName: "",
    password: "",
    sessionId: sessionId,
  });

  const [signUpData, setSignUpData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    companyName: "",
    companyRegNo: "",
    landingNo: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postcode: "",
    password: "",
    sessionId: sessionId,
  });

  const handleChange = (e) => {
    setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
  };

  const registerUserAction = (registerData) => async (dispatch) => {
    try {
      dispatch(registerStart());
      const response = await HttpApis.corporateSignUp(registerData);
      dispatch(registerSuccess(response.data));
      localStorage.setItem("token", response?.data?.data?.token);
      return response?.data;
    } catch (error) {
      dispatch(registerFailure(error.message));
      return null;
    }
  };
  //regester-invite-user
  const registerInviteUserAction = (invitedData) => async (dispatch) => {
    try {
      dispatch(registerStart());
      const response = await HttpApis.invitedUserSignUp(invitedData);
      dispatch(registerSuccess(response.data));
      localStorage.setItem("token", response?.data?.data?.token);
      return response?.data;
    } catch (error) {
      dispatch(registerFailure(error.message));
      return null;
    }
  };

  const handleRegister = async (registerData) => {
    const userData = await dispatch(registerUserAction(registerData));
    return userData;
  };
  //regester-invite-user-handler
  const handleInviteRegister = async (registerData) => {
    const userData = await dispatch(registerInviteUserAction(registerData));
    return userData;
  };

  const handleLoginChange = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  const loginUserAction = (credentials) => async (dispatch) => {
    try {
      dispatch(loginStart());
      const response = await HttpApis.login(credentials);
      dispatch(loginSuccess(response.data));
      localStorage.setItem("token", response?.data?.data?.token);
      return response?.data;
    } catch (error) {
      // message.error(error.message);
      return null;
    }
  };

  const handleLogin = async (credentials) => {
    const userData = await dispatch(loginUserAction(credentials));
    return userData;
  };

  const handleEditUser = async (userData) => {
    try {
      const response = await HttpApis.userProfileUpdate({
        ...userData,
        userId: userId,
      });

      if (response && response.data.status) {
        message.success(response.data.message);
        dispatch(
          updateEditUserData({
            ...userData,
            id: userId,
          })
        );
        return response.data;
      } else {
        message.error(response.data.message);
        return null;
      }
    } catch (error) {
      message.error("Something went wrong");
      return null;
    }
  };

  return {
    handleChange,
    handleRegister,
    signUpData,
    setSignUpData,
    handleLogin,
    loginDetails,
    handleLoginChange,
    handleEditUser,
    handleInviteRegister,
  };
};

export default useAuthHook;
