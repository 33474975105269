import React from 'react'
import AboutBanner from '../../components/Atoms/AboutBanner/AboutBanner'
import * as Styled from "./style"
import SocilaMedia from '../../components/Atoms/SocilaMedia/SocilaMedia'
import { FaRegDotCircle } from "react-icons/fa";
import { FaRegCheckSquare } from "react-icons/fa";
import { BiSolidBullseye } from "react-icons/bi";

const AboutPage = () => {
  return (
    <div>
         <Styled.Banner>
        <div className="bannerContainer">
          <Styled.Vetting>ABOUT US</Styled.Vetting>
          <p className="avtavText">AVSEC Training, Vetting and Recruitment Specialist</p>
        </div>
      </Styled.Banner>
    <Styled.AboutContainer>
        <AboutBanner/>
    </Styled.AboutContainer>
    <Styled.CardContainer className="container">
        <div class="flip-box">
          <div class="flip-box-inner">
            <div class="flip-box-front">
              <BiSolidBullseye  className="icon" />
              <div className="heading">Our Mission Statement</div>
            </div>
            <div class="flip-box-back">
              <p className="paragraph">
              To be the service provider of choice through confidence in our dedication and ability to deliver exceptional customer service standards. To make a positive impact on people’s lives and organisational performance and to always exceed expectation.
              </p>
            </div>
          </div>
        </div>
        <div class="flip-box">
          <div class="flip-box-inner">
            <div class="flip-box-front">
              <FaRegCheckSquare   className="icon" />
              <div className="heading">Core Values</div>
            </div>
            <div class="flip-box-back">
              <p className="paragraph">
              INTEGRITY, ACCOUNTABILITY, RELIABILITY, TRANSPARENCY, CONSISTENCY, PASSION & RESPECT
              </p>
            </div>
          </div>
        </div>
        <div class="flip-box">
          <div class="flip-box-inner">
            <div class="flip-box-front">
              <FaRegDotCircle  FaExchangeAlt className="icon" />
              <div className="heading">Our Moto	</div>
            </div>
            <div class="flip-box-back">
              <p className="paragraph">
              Compliance, Expertise and Experience Your Single Aviation Solution
              </p>
            </div>
          </div>
        </div>
      </Styled.CardContainer>
    <SocilaMedia/>
    </div>
  )
}
export default AboutPage
