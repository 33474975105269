import styled from "styled-components";
import aboutBg from "../../assets/image/aboutBg.png";

export const AboutContainer = styled.div`
  background: linear-gradient(#5c8aa0, #1d3e51);
  padding: 20px 0px;
  color: #ffffff;
  .avtavheading {
    color: #ffffff;
    font-weight: bold;
    line-height: 3.1em;
  }
`;
export const Banner = styled.div`
  background-image: url(${aboutBg});
  padding: 82px 0px;
  display: flex;
  justify-content: center;
  .bannerContainer {
    width: 80%;
    text-align: center;
  }
  .avtavText {
    color: #ffffff;
    font-size: 39px;
    white-space: normal;
  }
`;
export const Vetting = styled.div`
  font-size: 50px;
  font-weight: 800;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 10px;
`;
export const CardContainer = styled.div`
  padding: 30px 0px;
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  justify-content: center;
  .flip-box {
    background-color: transparent;
    width: 360px;
    height: 250px;
    border: 1px solid #f1f1f1;
    perspective: 1000px;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  .flip-box:hover .flip-box-inner {
    transform: rotateX(180deg);
  }

  .flip-box-front,
  .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-box-front {
    background-color: #17374c;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
  }

  .flip-box-back {
    background-color: #6091a8;
    color: white;
    transform: rotateX(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
  }

  .heading {
    font-size: 21px;
    font-weight: 600;
  }
  .paragraph {
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
  }
  .cardButton {
    border: 2px solid #ffffff;
    background: none;
    color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    width: 40%;
  }
  .icon {
    font-size: 50px;
    width: 100%;
    margin-bottom: 20px;
  }
  .buttonBox {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
