import * as Styled from "./style";
import React from "react";
import { MdClose, MdOutlineCheck } from "react-icons/md";
import { ReactComponent as LogoHead } from "../../../assets/Svg/logoHead.svg";
import { AccountDashboard } from "../../../components/Molecules/AccountDashboardPage/AccountDashboard";
import { DeclarationModalText } from "./LicenseData";

export const DeclarationModalPreview = ({
  formData,
  item,
  isTraineeData,
  openModal,
  handleClose,
  setOpenModal,
  isSoleTrader,
  singleDeclaration,
  index,
  fetchLicensesList,
  declarationStatus,
}) => {
  return (
    <>
      <Styled.DeclarationModal
        style={{
          top: 20,
        }}
        open={openModal}
        footer={null}
        width={903}
        closeIcon={false}
        onOk={handleClose}
        onCancel={handleClose}>
        <Styled.DeclarationContainer>
          <div className="corporateIcon">
            <div className="headCorporate">
              <LogoHead />
              {isSoleTrader
                ? DeclarationModalText.single
                : DeclarationModalText.corporate}
            </div>
            <Styled.HeaderIcon onClick={() => setOpenModal(false)}>
              <MdClose className="closeIcon" />
            </Styled.HeaderIcon>
          </div>
          <Styled.Textcontainer>
            <Styled.IconContainer>
              <Styled.IconBg>
                <MdOutlineCheck className="icon" />
              </Styled.IconBg>
              <div className="checkboxstyle">{DeclarationModalText.Text}</div>
            </Styled.IconContainer>
            <Styled.TextLine hr />
            {isTraineeData ? (
              singleDeclaration ? (
                <AccountDashboard
                  isInvitedDeclaration={true}
                  item={item}
                  isFormData={false}
                  isName={true}
                  isStaffDetails={true}
                  isTraineeData={isTraineeData}
                />
              ) : (
                <AccountDashboard
                  isInvitedDeclaration={true}
                  item={item}
                  isFormData={false}
                  isName={false}
                  isTraineeData={isTraineeData}
                />
              )
            ) : (
              <AccountDashboard
                isInvitedDeclaration={true}
                formData={formData}
                isFormData={true}
                CorporateUser={true}
                item={item}
                isLicense={false}
                index={index}
                fetchLicensesList={fetchLicensesList}
                declarationStatus={declarationStatus}
                isTraineeData={isTraineeData}
              />
            )}
          </Styled.Textcontainer>
        </Styled.DeclarationContainer>
      </Styled.DeclarationModal>
    </>
  );
};
