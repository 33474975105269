import * as Styled from "./styled";
import Footer from "../../components/Organisms/Footer/Footer";
import LightColorLogo from "../../assets/Svg/lightcolorLogo.svg";
import React, { useEffect } from "react";
import { Paragraph } from "./data";
import { useSelector } from "react-redux";
import OrderDetails from "../../components/Atoms/OrderDetail/OrderDetails";
import { useNavigate } from "react-router-dom";

export const OrderConfirmation = () => {
  const orderDetails = useSelector(
    (state) => state.checkoutReducer.checkout.share_data
  );
  const navigate = useNavigate();
  window.addEventListener("popstate", function (event) {
    navigate("/order-confirmation");
  });

  return (
    <>
      <Styled.Maincontainer>
        <Styled.Container>
          <div>
            <Styled.Payment>{Paragraph.Payment} </Styled.Payment>
            <Styled.Line />
          </div>
        </Styled.Container>

        <div className="container">
          <Styled.ParagraphContainer>
            <Styled.Thankyou>{Paragraph.Thankyou}</Styled.Thankyou>
            <Styled.Purchase>{Paragraph.Purchase}</Styled.Purchase>
            <Styled.AVTAVText>
              {Paragraph.AVTAVText}
              <span className="minutes">{Paragraph.Minutes}</span>
              {Paragraph.Onto}
              <span className="minutes">{orderDetails?.billing?.email}</span>
            </Styled.AVTAVText>
            <Styled.EmailText>
              {Paragraph.Ifyou}
              <a href="" className="email">
                {Paragraph.Email}
              </a>
            </Styled.EmailText>
            <Styled.CorporateUser>
              {Paragraph.If}
              <span className="corporate">{Paragraph.Corporate}</span>
              {Paragraph.Multiple}
            </Styled.CorporateUser>
          </Styled.ParagraphContainer>
          <Styled.OrderLine hr />
          <OrderDetails />

          <Styled.FooterLine hr />

          <Styled.Billing>
            <div className="billing">{Paragraph.Billing}</div>
            <div className="address">{orderDetails?.billing?.address}</div>
            <div className="address">{orderDetails?.billing?.City?.name}</div>
            <div className="address">
              {orderDetails?.billing?.State?.name},
              {orderDetails?.billing?.Country?.name}
            </div>
          </Styled.Billing>

          <Styled.FooterLine hr />

          <Styled.ThanksFor>{Paragraph.ThanksFor}</Styled.ThanksFor>
          <Styled.LightColorLogo>
            <img src={LightColorLogo} alt="lightcolorLogo" />
          </Styled.LightColorLogo>
        </div>
      </Styled.Maincontainer>
      <Footer />
    </>
  );
};
