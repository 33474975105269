import styled from "styled-components";
import { Table } from "antd";
import { color } from "../../../theme/Color";

export const AntdTable = styled(Table)`
  & td {
    height: 58px;
  }
  & tr {
    height: 51px;
    color: ${color._15374D};
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  & th {
    background: none !important;
  }
  .ant-table-column-sorters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 8px 8px 25px !important;
  }
  .ant-table-thead > tr > th {
    color: ${color._949494};
    background: ${color._ffffff};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 8px 8px 25px !important;
  }
  .ant-table-thead > tr {
    background: ${color._ffffff};
    color: ${color._949494};
    height: 57px;
  }
  .ant-table.ant-table-small {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    min-height: 327px;
    border-top: 1px solid #ededee;
    border-radius: 0px;
  }
  .ant-table-column-title {
    flex: initial;
  }

  .ant-table-pagination.ant-pagination {
    border-radius: 2px 2px 0px 0px;
    background: ${color._f1fbff};
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    justify-content: center;
  }

  & .ant-pagination-item-link-icon {
    vertical-align: baseline;
  }

  th {
    &.ant-table-cell::before {
      content: none !important;
    }
  }
`;
