export const cong = {
  con: `Congratulations`,
  success: `Congratulations! You have successfully completed `,
  xx: ` XXXXX`,
  please: ` course. Please be advised that the certificate of completion and training record will be sent directly to your employer.`,
  request: ` You can request this directly from `,
  hesitate: ` Please do not hesitate to contact us if you have any queries.`,
  kindest: ` Kindest Regards,`,
  avtav: ` AVTAV Training Team`,
  profile: ` You may download your certificate of completion and training record from your profile.`,
};
