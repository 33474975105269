import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useState } from "react";
import { AddCourses } from "./AddCourses";
import { IndividualCourses } from "./IndividualCourses";
import { Invited } from "./Invited";
import { CoursesButton } from "./data";

export const MyCourses = () => {
  const [courses, setCourses] = useState(0);
  const handleclick = (id) => {
    setCourses(id);
  };

  return (
    <div>
      <Styled.ButtonContainer>
        {CoursesButton?.map((item, index) => {
          return (
            <Button
              className={index === courses ? "active" : "non-active"}
              title={item.title}
              onClick={() => handleclick(item.id)}
            />
          );
        })}
      </Styled.ButtonContainer>

      {courses === 0 && <Invited />}
      {courses === 1 && <IndividualCourses />}
      {courses === 2 && <AddCourses />}
    </div>
  );
};
