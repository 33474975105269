import React from "react";
import * as Styled from "./style";
import { enquiryTypes, formFieldLabels } from "./data";

const Form = () => {
  return (
    <Styled.EnquireFormWrapper>
      <Styled.FormHeading>{formFieldLabels?.heading}</Styled.FormHeading>
      <Styled.FormFields>
        <Styled.TopFormFields>
          <Styled.FormField>
            <Styled.Label htmlFor="enquiryType">
              {formFieldLabels.enquiryType}
            </Styled.Label>
            <Styled.Select id="enquiryType">
              {enquiryTypes.map((item, index) => (
                <option key={item.type + index} value={item.type}>
                  {item.type}
                </option>
              ))}
            </Styled.Select>
          </Styled.FormField>
        </Styled.TopFormFields>
        <Styled.BottomFormFields>
          <Styled.BottomLeft>
            <Styled.FormField>
              <Styled.Label htmlFor="firstName">
                {formFieldLabels.firstname}
              </Styled.Label>
              <Styled.Input type="text" id="firstName" />
            </Styled.FormField>
            <Styled.FormField>
              <Styled.Label htmlFor="companyName">
                {formFieldLabels.companyName}
              </Styled.Label>
              <Styled.Input type="text" id="companyName" />
            </Styled.FormField>
            <Styled.FormField>
              <Styled.Label htmlFor="phone">
                {formFieldLabels.phone}
              </Styled.Label>
              <Styled.Input type="text" id="phone" />
            </Styled.FormField>
            <Styled.FormField>
              <Styled.Label htmlFor="comments">
                {formFieldLabels.comments}
              </Styled.Label>
              <Styled.Input type="text" id="comments" />
            </Styled.FormField>
          </Styled.BottomLeft>
          <Styled.BottomRight>
            <Styled.FormField>
              <Styled.Label htmlFor="surname">
                {formFieldLabels.surname}
              </Styled.Label>
              <Styled.Input type="text" id="surname" />
            </Styled.FormField>

            <Styled.FormField>
              <Styled.Label htmlFor="mobile">
                {formFieldLabels.mobile}
              </Styled.Label>
              <Styled.Input type="text" id="mobile" />
            </Styled.FormField>

            <Styled.FormField>
              <Styled.Label htmlFor="email">
                {formFieldLabels.email}
              </Styled.Label>
              <Styled.Input type="email" id="email" />
            </Styled.FormField>
          </Styled.BottomRight>
        </Styled.BottomFormFields>
        <Styled.FormField>
          <Styled.Button type="submit">{formFieldLabels.submit}</Styled.Button>
        </Styled.FormField>
      </Styled.FormFields>
    </Styled.EnquireFormWrapper>
  );
};

export default Form;
