import * as Styled from "./style";
import AntdTable from "../../../components/Atoms/Table";
import React, { useEffect, useState } from "react";
import useCommonHook from "../../../hooks/CommonHook";
import { AiOutlineSearch } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegFileExcel } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useDecarationHook } from "../../../hooks/DecarationHook";
import { dataItem } from "../BusinessUserCompletedCourses/data";
import { Declaration } from "../businessConstant";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import DownloadDeclarationForm from "../BusinessUserLicense/DownloadDeclarationForm";
import { DeclarationModalPreview } from "../BusinessUserLicense/DeclarationModal";

export const BusinessUserDeclaration = () => {
  const [downloadSheet, setDownloadSheet] = useState(false);

  const { useDebounce } = useCommonHook();
  const {
    TableDeclaration,
    corporateDeclarationForm,
    downloadData,
    declarationPreview,
    setDeclarationPreview,
    loading,
  } = useDecarationHook();
  const DeclarationCount = useSelector(
    (state) => state?.declarationReducer?.corporateDeclarationForm?.count
  );
  const corporateDeclarationFormData = useSelector(
    (state) =>
      state?.declarationReducer?.corporateDeclarationForm?.declarationForms
  );

  const [search, setSearch] = useState({
    page: 1,
    limit: 5,
    name: "",
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      total: DeclarationCount,
      current: 1,
      pageSize: 5,
    },
  });
  useEffect(() => {
    setTableParams({
      pagination: {
        total: DeclarationCount,
        current: 1,
        pageSize: 5,
      },
    });
  }, [DeclarationCount]);
  const debouncedSearch = useDebounce(search, 500);
  useEffect(() => {
    corporateDeclarationForm({
      ...debouncedSearch,
      page: tableParams.pagination.current,
    });
  }, [debouncedSearch, tableParams]);

  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };
  const handleSearch = (e) => {
    setSearch({ ...search, name: e.target.value });
  };

  useEffect(() => {
    if (downloadSheet) {
      const handleDeclaration = async () => {
        const response = await corporateDeclarationForm();

        if (response && Array.isArray(response?.declarationForms)) {
          const dataToDownload = response?.declarationForms?.map((item) => ({
            "Date Added": item.createdAt
              ? new Date(item.createdAt).toLocaleDateString("en-GB")
              : "",
            "Name of holder": item?.traineeName,
            "Course Name": item?.course?.title,
          }));
          const ws = XLSX.utils.json_to_sheet(dataToDownload);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });
          saveAs(data, "Declaration Form Data.xlsx");
        } else {
          console.error("No data to download");
        }
      };

      handleDeclaration();
      setDownloadSheet(false);
    }
  }, [downloadSheet]);
  const handleClick = () => {
    setDownloadSheet(true);
  };

  return (
    <Styled.DeclarationFormWrapper>
      <Styled.CompletedCourses>
        <span className="courses">{Declaration.forms}</span>
        <Styled.Pipe />
        <span className="number">{DeclarationCount}</span>
      </Styled.CompletedCourses>
      <Styled.BelowText>{dataItem.declaration}</Styled.BelowText>
      <Styled.MainContainer>
        <Styled.TableHeader className="container">
          <div className="tablecontainer">
            <Styled.SearchContainer>
              <AiOutlineSearch className="searchIcon" size={12} />
              <Styled.Input
                type="text"
                placeholder="Search by trainee name"
                value={search?.name}
                onChange={handleSearch}
              />
            </Styled.SearchContainer>
            <Styled.SpreadSheetButton onClick={handleClick}>
              <FaRegFileExcel />
              {dataItem.DownloadSpreadsheet}
            </Styled.SpreadSheetButton>
          </div>
          <Styled.DotIcon>
            <BsThreeDotsVertical className="dots" />
          </Styled.DotIcon>
        </Styled.TableHeader>
        <div>
          <AntdTable
            data={corporateDeclarationFormData}
            columns={TableDeclaration}
            numberOfTableData={tableParams.pagination.pageSize}
            onChange={handleTableChange}
            {...tableParams}
            loading={loading}
          />
        </div>
      </Styled.MainContainer>
      <div
        className="declaration-form"
        style={{
          position: "absolute",
          zIndex: "-1000",
          overflow: "hidden",
          height: "1px",
          width: "75%",
          opacity: "0",
        }}
      >
        <div>
          <DownloadDeclarationForm
            item={downloadData}
            isSoleTrader={false}
            singleDeclaration={false}
          />
        </div>
      </div>
      {declarationPreview ? (
        <DeclarationModalPreview
          openModal={true}
          setOpenModal={setDeclarationPreview}
          item={declarationPreview}
          isTraineeData={true}
          isSoleTrader={false}
          singleDeclaration={false}
        />
      ) : null}
    </Styled.DeclarationFormWrapper>
  );
};
