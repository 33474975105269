import BackgroundImageUn from "../../assets/image/BackgroundImageUn.jpg";
import styled from "styled-components";
import { color } from "../../theme/Color";

export const Container = styled.div`
  display: flex;
  overflow-x: hidden;
  @media only screen and (max-width: 800px) {
    display: flex;
    justify-content: center;
    // height: 100vh;
    width: 100%;
    background: aliceblue;
  }
`;

export const SignContainer = styled.div`
  flex: 0.5;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export const FormContainer = styled.div`
  flex: 0.5;
  @media only screen and (max-width: 800px) {
    // width: 100%;
    // flex: 1;
    // padding-top: 52px;
    padding-top: 50%;
    padding-bottom: 80%;
    height: 100%;
  }
`;

export const ImageContainer = styled.div`
  background: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  height: 963px;
  @media (min-height: 850px) {
    height: 100vh;
  }
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
export const Box = styled.div``;
export const MainContainer = styled.div`
  margin-top: 60px;
`;
export const Cross = styled.div`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
    position: absolute;
    left: 8%;
    .cross {
      width: 60px;
      height: 100px;
      font-size: 60px;
    }
  }
`;
export const Head = styled.div`
  padding-bottom: 43px;
  margin-top: 63px;
`;
export const Heading = styled.div`
  align-items: center;
  color: ${color._16384e};
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  padding-bottom: 1%;
  gap: 2%;
  @media only screen and (max-width: 800px) {
    font-size: 74px;
  }
`;
export const Acc = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: ${color._15374d};
  @media only screen and (max-width: 800px) {
    font-size: 32px;
  }
`;
export const SignIn = styled.div`
  color: ${color._15374d};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  @media only screen and (max-width: 800px) {
    font-size: 32px;
  }
`;
export const Title = styled.div`
  display: flex;
  justify-content: center;
  gap: 1%;
`;
export const Icon = styled.span`
  background-color: ${color._15374d};
  color: white;
  border-radius: 24%;
  width: 24.738px;
  height: 24.738px;
  margin-top: 5px;
  .arrowIcon {
    width: 24px;
    position: relative;
    bottom: 11px;
  }
  @media only screen and (max-width: 800px) {
    .arrowIcon {
      width: 49px;
      position: relative;
      bottom: 15px;
    }
  }
  @media only screen and (max-width: 800px) {
    width: 65.738px;
    height: 70.738px;
  }
`;
export const Line = styled.div`
  width: 60%;
  height: 1px;
  background: ${color._EFF3F9};
  margin: 10px auto;
  @media only screen and (max-width: 800px) {
    width: 100%;
    height: 2px;
  }
`;

export const Details = styled.div`
  padding-top: 37px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
  .bottom {
    margin-top: 25px;
  }
`;
export const MainPassword = styled.div`
  width: 313px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
  padding: 10px;
  outline: none;
  display: flex;
  justify-content: space-between;

  .passwordEye {
    background: ${color._fbfbfb};
    outline: none;
    border: none;
    width: 250px;
  }
  .eye {
    cursor: pointer;
  }
  @media only screen and (max-width: 800px) {
    .eye {
      width: 40px;
      height: 0px;
      font-size: 41px;
    }
  }
  @media only screen and (max-width: 800px) {
    width: 462px;
    height: 95px;
    font-size: 30px;
  }
`;
export const Input = styled.input`
  width: 313px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid ${color._ebebeb};
  background: ${color._fbfbfb};
  padding: 10px;
  outline: none;
  .passwordEye {
    position: relative;
  }
  @media only screen and (max-width: 800px) {
    width: 462px;
    height: 95px;
    font-size: 30px;
  }
`;

export const Label = styled.label`
  color: ${color._000000};
  padding-bottom: 4px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 95%;
  .star {
    color: ${color._f20000};
  }
  gap: 3px;
  display: flex;
  @media only screen and (max-width: 800px) {
    font-size: 30px;
  }
`;
export const DetailsButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const NextBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .next_step {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    flex-shrink: 0;
    line-height: normal;
    background: ${color._009BE3};
    width: 188px;
    height: 37.421px;
    flex-shrink: 0;
    border-radius: 2px;
    border: none;
    color: ${color._ffffff};
    &:hover {
      background-color: #177a9c;
      color: #ffffff;
    }
  }
  @media only screen and (max-width: 800px) {
    .next_step {
      font-size: 36px;
      width: 257px;
      height: 77.421px;
      margin-bottom: 77%;
    }
  }
`;
