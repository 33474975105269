import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loginReferer: "", // will be used when user is not logged in and clicking add to card
  cartItem: null, // will be used when user is not logged in and clicking add to card
  loading: false,
  error: null,
  sessionId: null,
  country: [],
  state: [],
  cities: [],
  checkoutReferer: "",
  coursesTypeList: [],
};
const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    updateStateAction: (state, action) => {
      assignValueInObject(action.payload, state);
    },
    resetStateAction: (state) => {
      assignValueInObject(initialState, state);
    },
  },
});
const assignValueInObject = (obj, state) => {
  for (let key in obj) {
    state[key] = obj[key];
  }
};
export const { updateStateAction, resetStateAction } = commonSlice.actions;
export default commonSlice.reducer;
