import { createSlice } from "@reduxjs/toolkit";

const inductionSlice = createSlice({
  name: "induction",
  initialState: {
    checklist: [],
    postChecklist: [],
    loading: false,
    error: null,
  },
  reducers: {
    checklistSuccess: (state, action) => {
      state.loading = false;
      state.checklist = action.payload;
    },
    checklistFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.data;
    },
    checklistPostSuccess: (state, action) => {
      state.loading = false;
      state.postChecklist = action.payload;
    },
    checklistPostFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.data;
    },
  },
});
export const {
  checklistSuccess,
  checklistFail,
  checklistPostSuccess,
  checklistPostFail,
} = inductionSlice.actions;
export default inductionSlice.reducer;
