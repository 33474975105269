import styled from "styled-components";
import { color } from "../../../theme/Color";

export const StepperLabelContainer = styled.div`
  width: 76%;
  height: 53px;
  flex-shrink: 0;
  background-color: ${color._5193aa};
  display: flex;
  border-radius: 2px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin: auto;
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;
export const StepperLabelText = styled.h6`
  color: ${color._f1fbff};
  display: flex;
  align-items: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 95%;
  position: relative;
  top: 6px;
  padding-left: ${({ isLogo }) => (isLogo ? "21px" : "10px")};
`;
export const IconLogo = styled.div`
  height: 27px;
  position: relative;
  height: 35px;
  left: 5%;
  top: 4px;
  .icon {
    width: 25.343px;
    height: 26px;
  }
`;
export const ResultLogo = styled.div`
  display: flex;
  width: 29%;
  position: relative;
  right: 1%;
  align-items: center;
  .resultLogo {
    width: 16px;
    height: 16px;
  }
`;
export const Declaration = styled.div`
  display: flex;
`;
export const Sign = styled.span`
  color:  ${color._fff};
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 11px;

`;
