import { createSlice } from "@reduxjs/toolkit";

const preCourseQuestionSlice = createSlice({
  name: "preCourse",
  initialState: {
    preCourseQuestion: [],
    preCourseQuestionnaire: [],
    loading: false,
    error: null,
  },
  reducers: {
    preCourseSuccess: (state, action) => {
      state.loading = false;
      state.preCourseQuestion = action.payload;
    },
    preCourseFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.data;
    },
    preCoursePostSuccess: (state, action) => {
      state.loading = false;
      state.preCourseQuestionnaire = action.payload;
    },
    preCoursePostFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.data;
    },
  },
});
export const {
  preCourseSuccess,
  preCourseFail,
  preCoursePostSuccess,
  preCoursePostFail,
} = preCourseQuestionSlice.actions;
export default preCourseQuestionSlice.reducer;
