import Footerbg from "../../../assets/image/Footerbg.jpg";
import { styled } from "styled-components";
import { color } from "../../../theme/Color";

export const FooterBackground = styled.div`
  background: url(${Footerbg});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 55px;
  display: flex;
  @media only screen and (max-width: 800px) {
    display: block;
    gap: 10px;
  }
`;

export const LogoContainer = styled.div`
  flex: 0.3;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;

export const InformationContainer = styled.div`
  flex: 0.5;
`;

export const ContactContainer = styled.div`
  flex: 0.2;

  @media only screen and (max-width: 800px) {
    margin-top: 45px;
  }
`;
export const SocialMobile = styled.div`
  @media only screen and (max-width: 800px) {
    display: flex;
    gap: 16px;
    margin-top: 10px;
    .socialIcons {
      display: block;

      color: ${color._fff};
      font-size: 57px;
      width: 62px;
      height: 69px;
    }
  }
`;
export const Heading = styled.div`
  color: ${color._fff};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 95%;
  @media only screen and (max-width: 800px) {
    font-size: 40px;
  }
`;

export const Label = styled.div`
  color: rgba(255, 255, 255, 0.63);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 95%;
  cursor: pointer;
  &.value {
    margin-top: 16px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 40px;
  }
`;

export const Value = styled.div`
  color: ${color._fff};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 95%;
  @media only screen and (max-width: 800px) {
    font-size: 35px;
  }
`;

export const Content = styled.div`
  margin-top: 17px;

  @media only screen and (max-width: 800px) {
    display: flex;
  }
  .icon {
    color: ${color._fff};
    height: 15px;
    width: 15px;
  }
  &.content:nth-child(-n + 3) {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;
export const PhoneNumber = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 5px;
  @media only screen and (max-width: 800px) {
    .icon {
      font-size: 44px;
      width: 30px;
      height: 55px;
    }
  }
`;

export const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 25px;
  .emailIcon {
    color: ${color._fff};
    height: 15px;
    width: 15px;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 800px) {
    margin-top: 36px;
    display: block;
  }
`;

export const CompanyContainer = styled.div`
  flex: 0.3;
`;

export const ServiceContainer = styled.div`
  flex: 0.25;
  @media only screen and (max-width: 800px) {
    margin-top: 45px;
  }
`;

export const LegalContainer = styled.div`
  flex: 0.45;
  @media only screen and (max-width: 800px) {
    margin-top: 45px;
  }
`;

export const Company = styled.div``;

export const Socials = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 45px;
  .socialIcon {
    color: ${color._fff};
    width: 14px;
    height: 14px;
  }
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export const Background = styled.div`
  background: ${color._0f2e43};
  height: 51px;
`;

export const CompanyDescription = styled.div`
  color: rgba(255, 255, 255, 0.84);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 95%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;
