import * as Styled from "./style";
import React from "react";
import avtavRecord from "../../assets/Svg/avtavRecord.svg";
import { record, training } from "./data";
import sign from "../../assets/image/sign.png";
import dayjs from "dayjs";

const TrainingRecord = ({ certificatePreview }) => {
  const expirationDate = dayjs(certificatePreview?.createdAt)
    .add(5, "year")
    .subtract(1, "day")
    .format("DD-MM-YYYY");
  const modules = certificatePreview?.moduleName?.split(",");
  console.log(certificatePreview, "certificatePreview..");
  return (
    <>
      <Styled.MainContainer className="container">
        <Styled.Record>
          <div>
            <img src={avtavRecord} className="avtav" alt="avtav_record" />
          </div>
          <div className="img">
            {record?.map((item, index) => {
              return <img key={index} src={item?.img} />;
            })}
          </div>
        </Styled.Record>
        <Styled.Training>
          <div className="exam">
            {training.train}
            <span className="form">{training.rec}</span>
          </div>
          <Styled.NameJohn>
            <div className="john">
              <div className="deo">
                {training.deo}
                <span className="nameemp">
                  {certificatePreview?.trainee_name}
                </span>
              </div>
            </div>
            <div className="emp">
              <div className="deo">
                {training.employee}
                <span className="nameemp">
                  {certificatePreview?.companyName}
                </span>
              </div>
            </div>
          </Styled.NameJohn>
        </Styled.Training>

        <Styled.ModuleCompleted>
          <div className="dataCourse">{training.course}</div>
          <Styled.Syllabus>
            <div className="syllabus">
              <div className="general">
                <div> {training.title}</div>
                <div> {certificatePreview?.courseName}</div>
              </div>
            </div>
            <div className="date">
              <div className="datetime">
                {training.date}
                <Styled.InputDate>
                  {dayjs(certificatePreview?.createdAt).format("DD-MM-YYYY")}
                </Styled.InputDate>
              </div>
              <div className="datetime">
                {training.expiry}
                <Styled.InputDate>{expirationDate}</Styled.InputDate>
              </div>
            </div>
            <div className="complete">{training.module}</div>
            {modules?.map((item) => {
              return (
                <>
                  <div className="threat">{item}</div>
                  <Styled.SingleLine />
                </>
              );
            })}
            <div className="certificate">
              <div className="issue">{training.issue}</div>
              <div className="issue">{`${
                certificatePreview?.examPercentage
                  ? certificatePreview?.examPercentage
                  : 0
              }%`}</div>
            </div>
          </Styled.Syllabus>
        </Styled.ModuleCompleted>
        <Styled.Sign>
          <div className="sign">
            <div className="instruct">{training.instruct}</div>
            <img src={sign} alt="sign" />
          </div>
          <div className="cin">
            <div>CIN: #2243</div>
            <div>
              Date: {dayjs(certificatePreview?.createdAt).format("DD-MM-YYYY")}{" "}
            </div>
          </div>
        </Styled.Sign>
        <Styled.SingleLine />
        <div className="ltd">{training.ltd}</div>
      </Styled.MainContainer>
    </>
  );
};

export default TrainingRecord;
