import { createSlice } from "@reduxjs/toolkit";

const examSlice = createSlice({
  name: "exam",
  initialState: {
    examQuestion: [],
    postExamQuestion: [],
    loading: false,
    error: null,
  },
  reducers: {
    examSuccess: (state, action) => {
      state.loading = false;
      state.examQuestion = action.payload;
    },
    examFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.data;
    },
    examPostSuccess: (state, action) => {
      state.loading = false;
      state.postExamQuestion = action.payload;
    },
    examPostFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.data;
    },
  },
});
export const { examSuccess, examFail, examPostSuccess, examPostFail } =
  examSlice.actions;

export default examSlice.reducer;
